import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';
import { ConjuntoService, ConjuntoQuery } from 'src/app/_services/conjunto.service';
import { PaginatedDataSource } from 'src/app/_services/generic-datasource';
import { environment } from 'src/environments/environment';
import { Conjunto } from 'src/app/_models/conjuntos.model';

@Component({
  selector: 'app-conjuntos',
  templateUrl: './conjuntos.component.html',
  styleUrls: ['./conjuntos.component.scss']
})
export class ConjuntosComponent {

  columnsToDisplay = ['nome'];
  datasource = new PaginatedDataSource<Conjunto, ConjuntoQuery>(
    (request, query) => this.conjuntoService.listarConjuntosPaginado(request, query),
    {property: 'idConjunto', order: 'asc'},
    {search: ''},
    environment.LIMITE_REGISTROS_LISTA
  )

  constructor(
    private fundoInvestimentoService: FundoInvestimentoService,
    private conjuntoService: ConjuntoService
  ) {
  }

}
