<h2 mat-dialog-title>TERMOS E CONDIÇÕES GERAIS PARA ACESSO AO SISTEMA (LIASYS)</h2>
<mat-dialog-content>
  <p>
    <strong>1.</strong> Estes Termos e Condições Gerais aplicam-se ao acesso da ferramenta denominada LIASYS disponível no site da Di Blasi
    Consultoria Financeira, a seguir denominada simplesmente, DI BLASI, empresa devidamente inscrita no CNPJ nº
    03.866.812|0001-02, incluindo os serviços de simulações de fundos de investimento, retorno de carteiras, análise de
    medidas de risco, atribuição de desempenho de investimentos, relatórios técnicos e financeiros, entre outras
    funcionalidades, tudo para facilitar a administração do capital investido pelos seus clientes.
  </p>
  <p>
    <strong>2.</strong> Qualquer pessoa que receber um usuário e senha e pretenda utilizar o sistema, deverá aceitar o presente Termo e
    Condições Gerais, bem como todas as demais políticas apresentadas pela DI BLASI.
  </p>
  <p>
    <strong>3.</strong> A ACEITAÇÃO DESTES TERMOS E CONDIÇÕES GERAIS É ABSOLUTAMENTE INDISPENSÁVEL PARA UTILIZAÇÃO DO SISTEMA E SUAS
    FERRAMENTAS.
  </p>
  <p>
    <strong>4.</strong> O usuário deverá ler, certificar-se de haver entendido e aceitar todas as condições estabelecidas nos Termos e
    Condições Gerais de uso, assim como nos demais documentos que por ventura tenha a sua aceitação solicitada.
  </p>
  <p>
    <strong>5.</strong> O Usuário acessará o sistema registrando seu e-mail e senha, compromete-se a não informar a terceiros esses dados,
    responsabilizando-se integralmente pela sua utilização e divulgação.
  </p>
  <strong>6.</strong> O Usuário compromete-se a notificar a DI BLASI imediatamente, através de meio seguro, a respeito de qualquer uso não
  autorizado de seus dados, bem como o acesso não autorizado por terceiros.
  <p>
    <strong>7.</strong> A DI BLASI poderá alterar, a qualquer tempo, os Termos e Condições Gerais, visando seu aprimoramento e melhoria do
    sistema, sem prejuízo de utilização da ferramenta por parte do cliente.
  </p><p>
    <strong>8.</strong> Os novos Termos e Condições Gerais entrarão em vigor 5 (cinco) dias após publicados no site, hipótese que uma nova
    aceitação aos Termos e Condições Gerais poderá ser solicitada, caso necessário.
  </p>
  <p>
    <strong>9.</strong> As marcas, nomes, marcas de serviços, desenhos e logotipos, lay out, parametrizações, etc. que aparecem e estão
    dispostos no site são marcas registradas e não registradas da DI BLASI, não podendo ser copiadas ou repassadas a
    terceiros sem autorização da DI BLASI.
  </p>
  <p>
    <strong>10.</strong> O Usuário não tem autorização de se referir publicamente ou atribuir qualquer informação à DI BLASI em qualquer
    mídia pública, salvo se autorizado, por escrito e por prazo determinado.
  </p>
  <p>
    <strong>11.</strong> O uso comercial do sistema LIASYS, bem como os conteúdos das telas dos programas, bancos de dados, redes, arquivos,
    etc. que permitem que o Usuário acesse e use sua conta são propriedade da DI BLASI e estão protegidos pelas leis e
    tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a
    reprodução total ou parcial dos referidos conteúdos são proibidos, salvo a autorização expressa da DI BLASI.
  </p>
  <p>
    <strong>12.</strong> Toda e qualquer informação transmitida e/ou obtida pelas PARTES será considerada confidencial, exceto se:
  </p><p>
    <small>
    a) as PARTES declararem, previamente e por escrito, que determinada informação não é confidencial;<br>
    b) se a informação já era de domínio público no momento de sua obtenção;<br>
    c) se a informação se tornar de domínio público sem que uma das partes tenha agido, ativa ou passivamente, para sua divulgação, bem como qualquer pessoa sob a sua responsabilidade, tais como administradores, empregados, sócios (ou acionistas), terceiros, prepostos, etc.;<br>
    </small>
  </p>
  <p>
    <strong>13.</strong> A obrigação de confidencialidade também compreende os deveres de:
  </p>
  <p>
    <small>
      a) impedir qualquer uso de informações confidenciais não autorizado;<br>
      b) impedir a divulgação de informações confidenciais a qualquer preposto que não necessite ter acesso;<br>
      c) adotar as medidas de segurança necessárias para prevenir eventual divulgação acidental das informações confidenciais.<br>
    </small>
  </p>
  <p>
    <strong>14.</strong> O Usuário entende e concorda que as informações contidas no sistema LIASYS têm caráter pedagógico, de auxílio à
    compreensão das medidas de risco e administração de carteira de investimentos, próprias ou terceirizadas, a fim de que o
    Usuário avalie o desempenho de tais carteiras e, se possível, otimize o desempenho de seus investimentos.
  </p>
  <p>
    <strong>15.</strong> O Usuário se declara ciente e concorda que nenhum índice, coeficiente ou produto do processamento gerado pela DI
    BLASI, inclusive os relatórios que lhe forem fornecidos, poderá ser considerado recomendação de compra ou alienação de
    ativos ou realização de investimento, nem como garantia do comportamento futuro dos ativos ou instituições analisadas,
    devendo ser qualificados tão somente como instrumentos de informação, inclusive quando esses indicadores permitirem ou
    estabelecerem ordenação sequencial (ranking) de fundos de investimento, gestores ou ativos, já que esta forma apenas
    reflete uma organização conveniente de informações e não pode ser entendida como recomendação de compra ou de venda.
  </p>
  <p>
    <strong>16.</strong>. O Usuário compreende que o valor dos ativos que compõem a carteira do fundo pode aumentar ou diminuir de acordo com
    flutuações de preços e cotações de mercado, mudanças no cenário político e econômico, alterações nas taxas de juros e,
    ainda, com os resultados das empresas emitentes de valores mobiliários (ações, debêntures, notas promissórias, entre
    outros), dinâmica típica e intrínseca desses mercados.
  </p>
  <p>
    <strong>17.</strong> Investimentos em fundos, ações, ativos financeiros em geral, possuem riscos inerentes à sua aplicação, razão pela
    qual a DI BLASI está totalmente isenta de qualquer responsabilidade em caso de perda de valores, redução de ativos,
    etc., principalmente se o Usuário utilizar o sistema indevidamente, em desacordo à conformidade contratada.
  </p>
  <p>
    <strong>18.</strong> A DI BLASI não garante a obtenção de resultados positivos ou vantagens pelo Usuário em decorrência da utilização da
    ferramenta LIASYS.
  </p>
  <p>
    <strong>19.</strong> As decisões acerca dos investimentos são de única e exclusiva responsabilidade do Usuário, tenham estas decisões
    sido ou não tomadas com base nas informações obtidas por meio da DI BLASI.
  </p>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutGap="15px">
  <button fxFlex mat-stroked-button color="primary" [mat-dialog-close]="true"><i class="fas fa-check"></i>Li e aceito os Termos e Condições gerais</button>
  <button fxFlex mat-stroked-button color="warn" mat-dialog-close><i class="fas fa-times"></i>Recusar</button>
</mat-dialog-actions>