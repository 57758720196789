import { Component, OnInit } from "@angular/core";
import {
  FormBuilder, FormGroup
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogDeleteComponent } from "src/app/_helpers/dialog-delete/dialog-delete.component";
import { UIService } from 'src/app/_helpers/ui.service';
import { Credenciamento } from 'src/app/_models/credenciamento.model';
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { CredenciamentoQuery, CredenciamentoService } from 'src/app/_services/credenciamento.service';
import { PaginatedDataSource } from 'src/app/_services/generic-datasource';
import { environment } from 'src/environments/environment';
@Component({
  selector: "app-credenciamento",
  templateUrl: "./credenciamento.component.html",
  styleUrls: ["./credenciamento.component.scss"],
})
export class CredenciamentoComponent implements OnInit {
  TipoUsuario = TipoUsuario;

  datasource = new PaginatedDataSource<Credenciamento, CredenciamentoQuery>(
    (request, query) => this.credenciamentoService.listarPaginado(request, query, this.idCliente),
    { property: 'nomeAdmin', order: 'asc' },
    { search: '' },
    environment.LIMITE_REGISTROS_LISTA
  )

  columnsToDisplay = ['cnpjAdmin', 'nomeAdmin', 'nomeGestor', 'pontuacao', 'indice_qualidade_gestao', 'data', 'prazo', 'tipo_credenciamento', 'acoes',];

  form: FormGroup;

  dados_calculados: boolean;

  idCliente: string;

  constructor(
    private credenciamentoService: CredenciamentoService,
    private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.form = fb.group({

    });
  }

  ngOnInit() {
    this.idCliente = this.route.snapshot.paramMap.get("idCliente");
  }


  openNew(path) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(path)
    );

    window.open(url, '_blank');
  }


  excluirCredenciamento(credenciamento: Credenciamento) {

    const dialog = this.dialog.open(DialogDeleteComponent, { data: { name: credenciamento.id } });
    dialog.afterClosed().subscribe(response => {
      if (response) {
        this.uiService.abrirDialogLoading(
          this.credenciamentoService.removerCredenciamento(credenciamento.id),
          'Excluindo credencimanento...',
          'Credenciamento excluído com sucesso',
          'Erro ao excluir o credencimamento.'
        ).subscribe(v => {
          this.datasource.queryBy({ search: '' });
        });
      }
    });
  }


  calcularVencimento(credenciamento: Credenciamento) {
    const hoje = Date.now();

    let dias = new Date(credenciamento.data).getDate() + 365;
    if (new Date(credenciamento.data) >= new Date('2022-06-01 00:00:00')) {
      dias = new Date(credenciamento.data).getDate() + 730;
    }

    const vouv = +(new Date(credenciamento.data).setDate(dias)) - +hoje

    return vouv >= 0;
  }


  calcularPrazo(credenciamento: Credenciamento) {
    // const diasd = new Date(credenciamento.data).getDate() + 365;

    let dt = new Date(credenciamento.data).setDate(new Date(credenciamento.data).getDate() + 365);
    if (new Date(credenciamento.data) >= new Date('2022-06-01 00:00:00')) {
      dt = new Date(credenciamento.data).setDate(new Date(credenciamento.data).getDate() + 730);
    }

    return dt
  }

}
