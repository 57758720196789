import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { delay } from "rxjs/operators";
import { Carteira } from "src/app/_models/carteira.model";
import { Cliente } from "src/app/_models/cliente.model";
import { AuthService } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ClienteService } from "src/app/_services/cliente.service";
import { MY_FORMATS } from "../relatorio-retornos/relatorio-retornos.component";

@Component({
  selector: "app-riscos",
  templateUrl: "./riscos.component.html",
  styleUrls: ["./riscos.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RiscosComponent implements OnInit, OnDestroy {
  atual = moment();
  date = new FormControl(moment().year(2025));

  idCliente: number;
  cliente: Cliente;

  data: moment.Moment;
  investimentos: Carteira[] = [];
  minDate: Date;
  maxDate: Date;
  printObs: Subscription;

  constructor(
    private route: ActivatedRoute,
    private clienteService: ClienteService,
    private authService: AuthService,
    private carteiraService: CarteiraService
  ) {
    this.data = moment().startOf("month");
  }

  ngOnInit() {
    this.minDate = new Date(2024, 0, 1, 0, 0, 0);
    this.maxDate = new Date(2025, 11, 31, 23, 59, 59);
    this.idCliente = this.route.snapshot.params["idCliente"];

    this.clienteService
      .buscarClientePorId(this.idCliente)
      .subscribe((cliente) => (this.cliente = cliente));

    this.authService.willPrint$.next(false);
    this.printObs = this.authService.willPrint$
      .pipe(delay(500))
      .subscribe((willPrint) => {
        if (willPrint) {
          window.print();
        }
      });
    window.onafterprint = (e) => this.authService.willPrint$.next(false);

    this.carteiraService
      .getCarteiraCliente(this.idCliente)
      .subscribe((carteira) => (this.investimentos = carteira));
  }

  ngOnDestroy(): void {
    this.printObs.unsubscribe();
    this.authService.willPrint$.next(false);
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.endOf("month"));
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue.endOf("month"));
    this.data = ctrlValue.endOf("month");

    this.carteiraService
      .getCarteiraCliente(this.idCliente, null, ctrlValue.toDate())
      .subscribe((carteira) => (this.investimentos = carteira));
    datepicker.close();
  }

  print() {
    this.authService.willPrint$.next(true);
  }
}
