import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Carteira, SituacaoEnquadramento } from 'src/app/_models/carteira.model';
import { Cliente } from 'src/app/_models/cliente.model';
import { AuthService } from 'src/app/_services/auth.service';
import { CarteiraService } from 'src/app/_services/carteira.service';
import { ClienteService } from 'src/app/_services/cliente.service';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';
import { OtimizadorService } from 'src/app/_services/otimizador.service';
import { ResolucaoService } from 'src/app/_services/resolucao.service';
import { DialogAdicionarFundoComponent } from './dialog-adicionar-fundo/dialog-adicionar-fundo.component';
import { DialogMovimentoSimuladorEnquadramentoComponent } from './dialog-movimento-simulador-enquadramento/dialog-movimento-simulador-enquadramento.component';
import * as shape from 'd3-shape';
import { flatMap, map, tap } from 'rxjs/operators';
import { DialogMovimentosSimuladorComponent } from './dialog-movimentos-simulador/dialog-movimentos-simulador.component';

export enum OperacaoSimulador {
  RESGATE = -1,
  APLICACAO = 1
}

@Component({
  selector: 'app-simulador-enquadramento',
  templateUrl: './simulador-enquadramento.component.html',
  styleUrls: ['./simulador-enquadramento.component.scss']
})
export class SimuladorEnquadramentoComponent implements OnInit {

  lineCurve = shape.curveCardinalOpen;
  idCliente: number;
  fromRoute: boolean = false;

  loading: boolean = false;

  investimentos: Carteira[] = [];
  investimentos$ = new Subject<Carteira[]>();
  totalCarteira: number;

  cliente: Cliente;
  enquadrado: boolean = true;
  sharpeCarteira: number = 0;
  varCarteira: number = 0;

  constructor(
    public dialog: MatDialog,
    private clienteService: ClienteService,
    private carteiraService: CarteiraService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fundoInvestimentoService: FundoInvestimentoService,
    private resolucaoService: ResolucaoService,
    private otimizadorService: OtimizadorService
  ) { }

  ngOnInit(){
    this.authService.usuario$.subscribe((usuario) => {
      const idCliente = +this.route.snapshot.paramMap.get("idCliente");
      if(!idCliente) {
        this.idCliente = usuario.idCliente
      } else {
        this.fromRoute = true
        this.idCliente = idCliente
      }
      this.loading = true;
      
      this.clienteService.buscarClientePorId(this.idCliente).subscribe(cliente => {
        this.cliente = cliente
        this.carteiraService.getCarteiraCliente(cliente.idCliente).subscribe(this.handleCarteira)
      });
    });

    this.investimentos$.subscribe(async (investimentos: Carteira[]) => {
      try {
        this.loading = true;
        this.enquadrado = true;
        this.totalCarteira = investimentos.reduce((a,b) => a + b.saldo, 0);

        this.investimentos = investimentos.map((investimento) => ({
          ...investimento,
          participacao: investimento.saldo / this.totalCarteira,
          percentualPatrimonio: investimento.saldo / investimento.patrimonioLiquido,
        }));

        for await (let investimento of this.investimentos) {
          if(!investimento.indices) {
            try {
              const indices:any = await this.otimizadorService.rentabilidadesFundo(investimento.cnpjFundo).toPromise();
              investimento.indices = indices;
              const rentabilidadesFundo = [...indices.rentabilidades];
              rentabilidadesFundo.reverse();
              investimento.indicesChart = [ { name: 'Rentabilidade mensal', series: rentabilidadesFundo.map((r, i) => ({ name: i+1, value: r})) }];
            } catch(error) {

            }
          }

          if(!investimento.resolucao) {
            try {
              const resolucoes = await this.resolucaoService.enquadramentosFundo(investimento.cnpjFundo).toPromise()
              const enquadramento = resolucoes.find(c => c.tipo === this.cliente.tipoEnquadramento)
              investimento.resolucao = enquadramento; 
            } catch(error) {

            }
          }
          
          investimento.enquadramento = this.verificaEnquadramento(investimento, this.cliente);
          this.enquadrado = this.enquadrado && investimento.enquadramento.enquadrado;
        }
      } catch(err) {
        console.log(err);
      } finally{
        this.loading = false
      }
    });
  }

  handleCarteira = (investimentos: Carteira[]) => {
    this.investimentos$.next(investimentos)
  }
  
  abrirDialogAdicionarFundo() {
    const dialog = this.dialog.open(DialogAdicionarFundoComponent, {
      width: '60%',
      hasBackdrop: true
    });

    dialog.afterClosed().subscribe(result => {
      if(result) {
        this.loading = true
        const { cnpjFundo, valorInvestido } = result;
        this.fundoInvestimentoService.getFundoByCNPJ(cnpjFundo).subscribe(fundo => {
          const objCarteiraFundo: any = {
            cnpjFundo,
            nomeFundo: fundo.fidados.nome,
            saldo: valorInvestido,
          }
          this.handleCarteira([...this.investimentos, objCarteiraFundo]);
        })
      }
    })
  }

  abrirDialogMovimento(index: number, fundo: Carteira, operacao: OperacaoSimulador) {
    const dialog = this.dialog.open(DialogMovimentoSimuladorEnquadramentoComponent, {
      width: '60%',
      hasBackdrop: true,
      data:{
        fundo,
        operacao
      }
    });

    dialog.afterClosed().subscribe(result => {
      if(result) {
        const { operacao, valor } = result;
          let valorMov = +valor;
          if (operacao === -1){
            valorMov *= -1
          }
          fundo.saldo += valorMov;
          if(!Array.isArray(fundo.movimentosSimulador)) {
            fundo.movimentosSimulador = []
          }
          fundo.movimentosSimulador.push({
            operacao,
            valor,
          });
          const investimentos = [...this.investimentos]
          investimentos.splice(index, 1, fundo);
          this.handleCarteira(investimentos);
      }
    })
  }

  abrirDialogHistoricoMovimentos(fundo: Carteira) {
    const dialog = this.dialog.open(DialogMovimentosSimuladorComponent, {
      width: '60%',
      hasBackdrop: true,
      data:{
        fundo,
      }
    });
  }

  verificaEnquadramento = (investimento: Carteira, cliente: Cliente) => {
    if(!investimento.resolucao) {
      return <SituacaoEnquadramento>{ enquadrado: false, motivo: 'Falta de informação de enquadramento no fundo'}
    }
    if(cliente.tipoEnquadramento === 'RPPS') {
      /*if(this.cliente.limitePolitica && (this.cliente.limitePolitica / 100) < investimento.participacao) {
        return <SituacaoEnquadramento>{ enquadrado: false, motivo: 'Política'}
      }*/

      if((investimento.resolucao.limite / 100) < investimento.participacao) {
        return <SituacaoEnquadramento>{ enquadrado: false, motivo: 'Limite'} 
      }
      
      if((investimento.resolucao.limite / 100) <= investimento.percentualPatrimonio) {
        return <SituacaoEnquadramento>{ enquadrado: false, motivo: 'Participação'}
      }

      //ANTIGO, SOMA TODOS OS VALORES DE CADA CNPJ DENTRO DA CARTEIRA
      const investimentosCNPJ = this.investimentos.filter(inv => inv.cnpjFundo === investimento.cnpjFundo);
      const saldoCNPJ = investimentosCNPJ.reduce((acc, item) => acc + item.saldo, 0);

      if (investimento.enquadramentoLegislacao.includes('Art. 7º, I, \"b\"')) {
        // ignora
        console.log('ignora o fundo ' + investimento.cnpjFundo);
      } else {
        if(saldoCNPJ / this.totalCarteira > 0.2){
          return <SituacaoEnquadramento>{ enquadrado: false, motivo: 'Limite de 20% por CNPJ'}
        }
      }

      // ESSE AQUI?? 
      // if(investimento.saldo / this.totalCarteira > 0.2){
      //   return <SituacaoEnquadramento>{ enquadrado: false, motivo: 'Limite de 20% por CNPJ'}
      // }


      return <SituacaoEnquadramento>{ enquadrado: true, motivo: ''}
      
    } else {

      if(this.cliente.limitePolitica && (this.cliente.limitePolitica / 100) < investimento.participacao) {
        return <SituacaoEnquadramento>{ enquadrado: false, motivo: 'Política'}
      }

      if(!this.cliente.limitePolitica && (investimento.resolucao.limite / 100) < investimento.participacao) {
        return <SituacaoEnquadramento>{ enquadrado: false, motivo: 'Limite'}
      }

      if((investimento.resolucao.limiteSuperior / 100) < investimento.participacao) {
        return <SituacaoEnquadramento>{ enquadrado: false, motivo: 'Limite superior carteira'}
      }

      if((investimento.resolucao.limiteInferior / 100) > investimento.participacao) {
        return <SituacaoEnquadramento>{ enquadrado: false, motivo: 'Limite inferior carteira'}
      }

      return <SituacaoEnquadramento>{ enquadrado: true, motivo: ''}
    }
  }

  removerFundo(index: number) {
    this.loading = true
    const investimentos = [...this.investimentos]
    investimentos.splice(index, 1);
    this.handleCarteira(investimentos);
  }

  formatarY(valor) {
    return `${(valor * 100).toFixed(5)}%`;
  }

  fnSumVar = (acc: number, item:Carteira) => {
    if(item.indices){
      return acc + (item?.indices?.var * item.saldo)
    }
    return acc
  }

  fnSumSharpe = (acc: number, item:Carteira) => {
    if(item.indices){
      return acc + (item?.indices?.sharpe * item.saldo)
    }
    return acc
  }

  fnRentabilidadeMedia = (acc: number, item:Carteira) => {
    if(item.indices){
      return acc + (item?.indices?.rentabilidade * item.saldo)
    }
    return acc
  }

}
