import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import * as moment from "moment";
import { defaultFormat as _rollupMoment, Moment } from "moment";
import { FormControl } from "@angular/forms";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/_services/auth.service";
import { delay } from "rxjs/operators";
import { Retorno } from "src/app/_models/carteira.model";
import { ClienteService } from "src/app/_services/cliente.service";
import { BehaviorSubject, Subject } from 'rxjs';
import { Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { CurrencyPipe, PercentPipe } from '@angular/common';

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY",
  },
  display: {
    dateInput: "YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-grafico-retorno-acumulado",
  templateUrl: "./grafico-retorno-acumulado.component.html",
  styleUrls: ["./grafico-retorno-acumulado.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class GraficoRetornoAcumuladoComponent implements OnInit {

  @Input()
  relatorioDinamico: Boolean = false;

  dataEntrada$ = new BehaviorSubject<string>(null);
  @Input() set dataEntrada(value: string) {
    this.dataEntrada$.next(value)
  }

  @Output()
  loadingStatus = new EventEmitter<boolean>();

  atual = moment();
  date = new FormControl(moment());

  registros: any[] = [];
  evolucaoPatrimonial: any[] = [];
  evolucaoPatrimonialRetono: any[] = [];
  registrosExibicao: Retorno[] = [];
  complementoTitulo: string = "";

  colorSchema = {
    domain: ["#32a852", "#ed8a00"]
  }

  minY: number;
  maxY: number;

  idCliente: number;
  totais = [];
  zoom = new FormControl(1);
  mesesExibicao = new FormControl([]);
  mesesDisponiveis = [];

  labels: Label[] = [];
  chartData: ChartDataSets[];

  chartDataEvolucao: ChartDataSets[];

  chartDataRetorno: ChartDataSets[];

  public barChartPlugins = [pluginDataLabels];
  public ChartOptionsPercent: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'bottom',
        formatter: (value) => new PercentPipe('pt-BR').transform(value / 100, '1.2-4')
      }
    }
  };

  public ChartOptionsMoney: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'bottom',  
        font:{
          size: 11
        },
        formatter: (value) => new CurrencyPipe('pt-BR').transform(value, 'BRL')
      }
    }
  };

  constructor(
    private carteiraService: CarteiraService,
    private clienteService: ClienteService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.idCliente = +route.snapshot.paramMap.get("idCliente");
    
  }

  ngOnInit() {
    if(!this.relatorioDinamico) {
      window.onafterprint = (e) => this.authService.willPrint$.next(false);
      this.authService.willPrint$.pipe(delay(500)).subscribe((willPrint) => {
        if (willPrint) {
          window.print();
        }
      });
    }

    if (this.idCliente) {
      this.clienteService.buscarClientePorId(this.idCliente).subscribe((c) => {
        this.complementoTitulo = ` - Cliente: ${c.nome}`;
      });
    }

    if(this.relatorioDinamico) {
      this.dataEntrada$.subscribe(value => {
        this.loadingStatus.emit(false);
        this.date.setValue(moment(value, 'DD/MM/YYYY'));
        this.retrieveRetornos(moment(this.date.value));
      })
    }

    this.mesesExibicao.valueChanges.subscribe((v) => {
      this.registrosExibicao = this.registros.filter((r:Retorno) =>
        v.includes(r.periodo)
      );

      this.totais = [
        {
          totalSaldoAnterior: this.registrosExibicao.reduce(
            (acc, r) => acc + r.saldoAnterior,
            0
          ),
          totalSaldoAtual: this.registrosExibicao.reduce(
            (acc, r) => acc + r.saldoAtual,
            0
          ),
          totalResgates: this.registrosExibicao.reduce(
            (acc, r) => acc + r.resgates,
            0
          ),
          totalAplicacoes: this.registrosExibicao.reduce(
            (acc, r) => acc + r.aplicacoes,
            0
          ),
          totalRetornos: this.registrosExibicao.reduce(
            (acc, r) => acc + r.retorno,
            0
          ),
          totalRetornoPercentual:
            this.registrosExibicao.reduce(
              (acc, r) => acc * (r.retornoPercentual + 1),
              1
            ) - 1,
          totalMeta:
            this.registrosExibicao.reduce((acc, r) => acc * (r.meta + 1), 1) -
            1,
        },
      ];

      this.totais = this.totais.map(total => ({
        ...total, atingimento:  total.totalRetornoPercentual / total.totalMeta
      }))

    });
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
    datepicker.close();
    this.retrieveRetornos(ctrlValue);
  }

  retrieveRetornos(date: Moment) {
    this.carteiraService
      .relatorioRetornosAcumulado(this.idCliente, date)
      .subscribe((v) => {

        this.mesesDisponiveis = v.map((registro) => {
          return  moment(registro.periodo).format("MMMM");
        });

        const series = [
          { name: "Efetivo", series: [] },
          { name: "Meta", series: [] },
        ]

        const patrimonio = { name: 'Patrimonio', series: [] };
        const evolucaoRetorno = [
          { name: 'Patrimônio', series: [] },
          { name: 'Retorno', series: [] }
        ]

        const metaSeries = []
        const efetivoSeries = [];

        const retornoSeries = [];
        const saldoSeries = []

        this.labels = []
        v.forEach((registro: Retorno) => {
          if(moment(registro.periodo).isAfter(this.date.value.clone().endOf('month'), 'month')){
            return
          }
          const mes = moment(registro.periodo).format("MMMM");

          this.labels.push(mes);
          metaSeries.push(registro.meta * 100)
          efetivoSeries.push(registro.retornoPercentual * 100)

          retornoSeries.push(registro.retorno)
          saldoSeries.push(registro.saldoAtual)

          series[0].series.push({ name: mes, value: registro.retornoPercentual * 100 })
          series[1].series.push({ name: mes, value: registro.meta * 100 })
          patrimonio.series.push({ name: mes, value: registro.saldoAtual });

          evolucaoRetorno[0].series.push({ name: mes, value: registro.saldoAtual})
          evolucaoRetorno[1].series.push({ name: mes, value: registro.retorno })
        })

        this.chartData = [
          { data: efetivoSeries, label: 'Efetivo' },
          { data: metaSeries, label: 'Meta' }
        ]

        this.chartDataEvolucao = [
          { data: retornoSeries, label: 'Retorno' },
          { data: saldoSeries, label: 'Saldo' }
        ]
        
        this.chartDataRetorno = [
          { data: retornoSeries, label: 'Retorno' },
        ]

        this.evolucaoPatrimonial = [patrimonio]

        this.evolucaoPatrimonialRetono = evolucaoRetorno

        this.registros = series;

        const valores:number[] = series.reduce((acc, serie) => [...acc, ...serie.series], []);
 
        this.maxY = Math.max(...valores) + 0.3;
        this.minY = Math.min(...valores) - 0.3
        
        this.mesesDisponiveis = this.registros.map((registro) => {
          return registro.periodo;
        });

        if(this.relatorioDinamico) {
          const mes = this.date.value.clone().endOf('month');
          
          this.mesesDisponiveis = this.mesesDisponiveis.filter((c, i) => {
            return moment(c, 'YYYY-MM-DD').isSameOrBefore(mes, 'day');
          })
        }
        
        this.mesesExibicao.patchValue([...this.mesesDisponiveis]);
        this.loadingStatus.emit(true)
      });
  }

  print() {
    this.authService.willPrint$.next(true);
  }

  toggleMeses() {
    if (this.mesesExibicao.value.length === 0) {
      this.mesesExibicao.patchValue([...this.mesesDisponiveis]);
    } else {
      this.mesesExibicao.patchValue([]);
    }
  }
}
