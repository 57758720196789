<h4 class="title">DREI -  Adicionar</h4>

<form [formGroup]="form"  (ngSubmit)="submit()">

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="20">
      <input
          matInput
          [matDatepicker]="dp"
          placeholder="Ano"
          [formControl]="date"
          [matDatepickerFilter]="restrainMonths"
        />
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker
          #dp
          startView="multi-year"
          (yearSelected)="chosenYearHandler($event, dp)"
          (monthSelected)="chosenMonthHandler($event, dp)"
          
          panelClass="example-month-picker"
        >
        </mat-datepicker>
       <mat-error *ngIf="!form.controls['mes'].valid">Informe o mês</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="20">
      <input 
        #rentabilidadeAcumulada
        matInput 
        formControlName="rentabilidadeAcumulada" 
        required 
        [dropSpecialCharacters]="false" 
        placeholder="Rentabilidade acumulada no trimestre"
        mask="separator.4"
        [allowNegativeNumbers]="true"/>
      <mat-error *ngIf="!rentabilidadeAcumulada.valid">Informe a rentabilidade</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="20">
      <input matInput [matDatepicker]="picker" readonly required formControlName="dataAvaliacao" placeholder="Data da Última Avaliação Atuarial">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="20">
      <input 
        #metaRentabilidade
        matInput 
        formControlName="metaRentabilidade" 
        required 
        [dropSpecialCharacters]="false" 
        placeholder="Meta de rentabilidade"
        mask="separator.4"
        [allowNegativeNumbers]="true"/>
      <mat-error *ngIf="!metaRentabilidade.valid">Informe a meta de rentabilidade</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="20">

      <input 
        #inflacaoAcumulada
        matInput 
        formControlName="inflacaoAcumulada" 
        required 
        [dropSpecialCharacters]="false" 
        placeholder="Inflação acumulada no trimestre"
        mask="separator.4"
        [allowNegativeNumbers]="true"/>
      <mat-error *ngIf="!inflacaoAcumulada.valid">Informe a inflação acumulada</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column">
    <button fxFlex="20" type="submit" [disabled]="form.invalid" mat-raised-button color="primary"><i class="far fa-save"></i>Salvar</button>
  </div>
</form>

