import { Pipe, PipeTransform } from '@angular/core';
import { TiposUsuario, TipoUsuario } from 'src/app/_services/auth.service';
@Pipe({
  name: 'tipoUsuario'
})
export class TipoUsuarioPipe implements PipeTransform {

  transform(value: TipoUsuario, args?: any): any {
    return TiposUsuario.filter(t => t.value === value)[0].label;
  }

}
