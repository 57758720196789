import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UIService } from "src/app/_helpers/ui.service";
import { Carteira } from "src/app/_models/carteira.model";
import { CarteiraService } from "src/app/_services/carteira.service";
import { VisualizarInvestimentoComponent } from "../visualizar-investimento/visualizar-investimento.component";

export interface DialogData {
  idCliente: number;
  idInvestimento: number;
  investimento: Carteira;
}

@Component({
  selector: "app-dialog-alteracao-dados",
  templateUrl: "./dialog-alteracao-dados.component.html",
  styleUrls: ["./dialog-alteracao-dados.component.scss"],
})
export class DialogAlteracaoDadosComponent implements OnInit {
  form = new FormGroup({
    banco: new FormControl(""),
    agencia: new FormControl(""),
    conta: new FormControl(""),
    codigoAlternativo: new FormControl(""),
    tipo: new FormControl(""),
    agrupar: new FormControl(false),
  });

  constructor(
    private carteiraService: CarteiraService,
    public dialogRef: MatDialogRef<VisualizarInvestimentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private uiService: UIService
  ) {}

  ngOnInit() {
    this.form.patchValue({
      banco: this.data.investimento.banco,
      agencia: this.data.investimento.agencia,
      conta: this.data.investimento.conta,
      codigoAlternativo: this.data.investimento.codigoAlternativo,
      tipo: this.data.investimento.tipo,
      agrupar: this.data.investimento.agrupar,
    });
  }

  alterarDados() {
    const dados = {
      ...this.form.value,
    };
    this.carteiraService
      .alterarDadosBancarios(this.data.idInvestimento, dados)
      .subscribe(
        (success) => {
          this.uiService.showSnackBar(
            "Dados alterados com sucesso",
            null,
            4000
          );
          this.dialogRef.close();
        },
        (erro) => {
          this.uiService.showSnackBar("Erro ao alterar os dados", null, 4000);
        }
      );
  }
}
