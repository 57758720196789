<div id="dialog-delete">
  <h2 mat-dialog-title>Adicionando investimento a carteira</h2>
  <form [formGroup]="form">
    <mat-dialog-content>
      <mat-form-field style="width: 100%;">
        <input formControlName="limiteInferior" type="number" matInput placeholder="Limite inferior">
        <mat-error *ngIf="form.get('limiteInferior').valid">Informe o limite inferior</mat-error>
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <input formControlName="limiteSuperior" type="number" matInput placeholder="Limite superior">
        <mat-error *ngIf="form.get('limiteSuperior').valid">Informe o limite superior</mat-error>
      </mat-form-field>
      Confirmar adição do investimento para otimização? 
    </mat-dialog-content>
    <mat-dialog-actions>
      <button color="primary" [disabled]="!form.valid" type="submit" mat-flat-button (click)="confirmar()"><mat-icon>check</mat-icon> Ok</button>
      <button color="warn" mat-flat-button mat-dialog-close><mat-icon>close</mat-icon> Cancelar</button>
    </mat-dialog-actions>
  </form>
</div>