<!-- <div>
  <span>
    <mat-form-field>
      <form [formGroup]="formReimportarCotas">
        <input matInput [matDatepicker]="picker" formControlName="dataReimportar" placeholder="Data da Reimportação"
          min="2022-01-01" max="atual" readonly />
      </form>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <button type="button" (click)="reimportarCota()" matTooltip="Reimportar Cotas" mat-flat-button color="primary">
      Reimportar Cotas
    </button>
  </span>
</div> -->
<h2 class="title">Logs de importação CVM</h2>
<mat-list dense>
  <mat-list-item *ngFor="let log of logs">
    <mat-icon style="color:green" matListIcon>check</mat-icon>
    <p matLine>{{log.numFundos}} Fundos</p>
    <p matLine>{{log.numInformes}} Informes</p>
    <p><strong>{{log.data | date:'dd/MM/yyyy'}} {{log.hora}}</strong></p>
    <mat-divider></mat-divider>
  </mat-list-item>
</mat-list>