

<form [formGroup]="form" (ngSubmit)="salvarDados()" >
  <h4>EDITAR RIPP</h4>


  <div fxLayout="row" fxLayout.lt-md="column" class="col-3">
    <mat-form-field >
      <input matInput [matDatepicker]="picker" readonly formControlName="data_aplicacao_inicial" placeholder="Data de aplicação inicial">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
    <mat-form-field >
      <input
        #patrimonio_iquido
        matInput
        currencyMask
        formControlName="patrimonio_liquido"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' ,precision:8 }"
        placeholder="Patrimônio Líquido (R$)"
        aria-label="Patrimônio Líquido (R$)"
      />
    </mat-form-field>
  </div>
    <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
    <mat-form-field >
      <input
        #aplicacao_inicial
        matInput
        currencyMask
        formControlName="aplicacao_inicial"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ','  ,precision:8}"
        placeholder="Aplicação Inicial (R$)"
        aria-label="Aplicação Inicial (R$)"
      />
    </mat-form-field>
  </div>


  <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
    <mat-form-field >
      <input
        #cota_inicial
        matInput
        formControlName="cota_inicial"
        currencyMask
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' ,precision:8}"
        placeholder="Valor Unitário Cota Inicial (R$)"
        aria-label="Valor Unitário Cota Inicial (R$)"
      />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
    <mat-form-field>
      <input
        #qtd_cotas_inicial
        matInput
        type="number"
        formControlName="qtd_cotas_inicial"
        placeholder="Quantidade de Cotas"
        aria-label="Quantidade de Cotas"
      />
    </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
    <mat-form-field>
      <input
        #total_cotistas
        matInput
        type="number"
        formControlName="total_cotistas"
        placeholder="Total de Cotistas"
        aria-label="Total de Cotistas"
      />
    </mat-form-field>
  </div>




  <div fxLayout="row" fxLayout.lt-md="column" class="col-2">

    <mat-form-field>
      <input
      #percentual_cmn
      matInput
      formControlName="percentual_cmn"
      placeholder="Limite Percentual CMN"
      [dropSpecialCharacters]="false"
      aria-label="Limite Percentual CMN"
      mask="percent"
      sufix="%">
    </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
    <mat-form-field>
      <input
      #percentual_investido
      matInput
      formControlName="percentual_investido"
      placeholder="Percentual Investido"
      [dropSpecialCharacters]="false"
      aria-label="Percentual Investido"
      mask="percent"
      sufix="%">
    </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
      <mat-form-field>
      <input
      #percentual_autorizado
      matInput
      formControlName="percentual_autorizado"
      placeholder="Percentual Autorizado"
      [dropSpecialCharacters]="false"
      aria-label="Percentual Autorizado"
      mask="percent"
      sufix="%">
    </mat-form-field>
  </div>


  <br/>
  <div  [hidden]="!isLoading">
    <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
  </div>


  <div   class="buttons">
    <button type="submit" color="primary" mat-raised-button style="width:100%">
      <i class="fas fa-save"></i>Salvar
    </button>
  </div>
</form>
