<div>
  <h3>Editar valor do patrimônio Liquido</h3>
  <br />
  <form (ngSubmit)="salvarEdicao()" [formGroup]="form" fxLayout="column">
    <mat-form-field>
      <input
        matInput
        formControlName="patrimonioLiquido"
        placeholder="Valor do Patrimônio Líquido"
        required
        [(ngModel)]="patrimonioLiquido"
        aria-label="Valor do Patrimônio Líquido"
      />
      <mat-error *ngIf="form.get('patrimonioLiquido').hasError('required')">
        Informe o valor
      </mat-error>
    </mat-form-field>

    <br />
    <button mat-flat-button color="primary">Salvar Dados</button>
  </form>
</div>
