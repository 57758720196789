import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ResolucaoService } from 'src/app/_services/resolucao.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogLoadingComponent } from 'src/app/_helpers/dialog-loading/dialog-loading.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Enquadramento } from '../../../_models/resolucao.model';

@Component({
  selector: 'app-cadastro-resolucao',
  templateUrl: './cadastro-resolucao.component.html',
  styleUrls: ['./cadastro-resolucao.component.scss']
})
export class CadastroResolucaoComponent implements OnInit {
  form: FormGroup;
  idResolucao: number;
  customPatterns = { '0': { pattern: new RegExp('-|[0-9]') }, '9': { pattern: new RegExp('[0-9]') } }

  constructor(
    private fb: FormBuilder,
    private resolucaoService: ResolucaoService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.form = fb.group({
      id: null,
      nome: [null, Validators.required],
      legislacao: [null, Validators.required],
      tipo: [null],
      limite: [null, [Validators.min(0), Validators.max(100)]],
      limiteParticipacao: [null, [Validators.min(0), Validators.max(100)]],
      limiteInferior: [null, [Validators.min(0), Validators.max(100)]],
      limiteSuperior: [null, [Validators.min(0), Validators.max(100)]]
    })
  }


  ngOnInit() {
    
    this.idResolucao = +this.route.snapshot.paramMap.get('id');
    if(this.idResolucao) {
      this.resolucaoService.getResolucao(this.idResolucao).subscribe(resolucao => {
        this.form.patchValue(resolucao);
      })
    }
  }

  submit() {
  
    let salvo: Observable<any>;
    if(this.idResolucao) {
      salvo = this.resolucaoService.editarResolucao(this.idResolucao, this.form.value)
    } else {
      const { id, ...data} = this.form.value;
      salvo = this.resolucaoService.novaResolucao(data);
    }

    const dialogRef = this.dialog.open(DialogLoadingComponent, {
      data: {
        text: 'Salvando Resolução...',
        target: salvo,
        closeOnComplete: true,
        successMessage: 'Resolução salva com sucesso',
        errorMessage: ({error}) => `Erro ao salvar,  ${error.error}`
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.router.navigate(['/resolucao']);
      }
    })
  }

}
