<div class="title" fxLayout="row">
  <h1 fxFlex="70"  fxFlex.lt-md="50">Ranking estático</h1>
  <button fxFlex="30" fxFlex.lt-md="50" fxFlexAlign="center" color="primary" mat-stroked-button [routerLink]="['/otimizador', 'resultado']"><i class="fas fa-chart-line"></i> Otimizar a partir dos fundos selecionados </button>
</div>
<mat-spinner [diameter]="45" *ngIf="loading"></mat-spinner>
<div class="scroll">
  <table mat-table [dataSource]="dataSource" [hidden]="!dataSource" matSort matSortActive="rankingRetorno" matSortDirection="asc">
    <ng-container matColumnDef="cnpj">
      <th mat-header-cell *matHeaderCellDef class="left"> CNPJ Fundo </th>
      <td mat-cell *matCellDef="let element" class="left"> {{element.cnpj | mask: '00.000.000/0000-00'}} </td>
    </ng-container>
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef style="width: 40%; text-align: left"> Nome </th>
      <td mat-cell *matCellDef="let element" style="width: 40%; text-align: left" class="small"> {{element.nome}} </td>
    </ng-container>
    <ng-container matColumnDef="retorno">
      <th mat-header-cell *matHeaderCellDef> Retorno </th>
      <td mat-cell *matCellDef="let element"> {{element.retorno | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingRetorno" >
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Retorno </th>
      <td mat-cell *matCellDef="let element" [style.backgroundColor]="color(element.rankingRetorno)" class="center"> {{element.rankingRetorno}} </td>
    </ng-container>
    <ng-container matColumnDef="desvpad" >
      <th mat-header-cell *matHeaderCellDef> D. Padrão </th>
      <td mat-cell *matCellDef="let element"> {{element.desvpad | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingDesvpad">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank D. Padrão </th>
      <td mat-cell *matCellDef="let element" [style.backgroundColor]="color(element.rankingDesvpad)" class="center"> {{element.rankingDesvpad}} </td>
    </ng-container>
    <ng-container matColumnDef="sharpe">
      <th mat-header-cell *matHeaderCellDef> Sharpe </th>
      <td mat-cell *matCellDef="let element"> {{element.sharpe | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingSharpe">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Sharpe</th>
      <td mat-cell *matCellDef="let element" [style.backgroundColor]="color(element.rankingSharpe)" class="center"> {{element.rankingSharpe}} </td>
    </ng-container>
    <ng-container matColumnDef="var">
      <th mat-header-cell *matHeaderCellDef> VAR </th>
      <td mat-cell *matCellDef="let element"> {{element.var | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingVar">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank VAR </th>
      <td mat-cell *matCellDef="let element" [style.backgroundColor]="color(element.rankingVar)" class="center"> {{element.rankingVar}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
</div>