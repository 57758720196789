import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { FIList } from 'src/app/_models/fundo-investimento.models';
import { TipoUsuario } from 'src/app/_services/auth.service';
import { FIQuery, FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';
import { PaginatedDataSource } from 'src/app/_services/generic-datasource';
import { environment } from 'src/environments/environment';
import { AdicionarEnquadramentoFundoComponent } from '../resolucao/adicionar-enquadramento-fundo/adicionar-enquadramento-fundo.component';

@Component({
  selector: 'app-fundo-investimento',
  templateUrl: './fundo-investimento.component.html',
  styleUrls: ['./fundo-investimento.component.scss']
})
export class FundoInvestimentoComponent{
  tipoUsuario = TipoUsuario;
  columnsToDisplay = ['nome', 'cnpj', 'patrimonioLiquido', 'classe', 'informes', 'enquadramentos'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  fundoInvestimentos: FIList[];

  datasource = new PaginatedDataSource<FIList, FIQuery>(
    (request, query) => this.fundoInvestimentoService.listaPaginada(request, query),
    {property: 'cnpj', order: 'asc'},
    {search: ''},
    environment.LIMITE_REGISTROS_LISTA
  )

  constructor(
    private fundoInvestimentoService: FundoInvestimentoService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  adicionarEnquadramento(fundo: FIList) {
    this.dialog.open(AdicionarEnquadramentoFundoComponent, {
      width: '600px',
      data: {
        fundo
      }
    })
  }

  verEnquadros(fundo: FIList) {
    this.router.navigate(['enquadramentos', 'cnpj', fundo.cnpj.replace(/[^\d\s]/gi, '')])
  }


}
