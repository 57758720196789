<h1>Clientes</h1>

<div class="acoes">
  <mat-form-field>
    <input #in (input)="datasource.queryBy({search: in.value})" matInput placeholder="Filtro">
  </mat-form-field>   
    
  <button routerLink="novo" mat-raised-button color="accent">
    <mat-icon>person_add</mat-icon> Novo cliente
  </button>
</div>
<table mat-table [dataSource]="datasource" >
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef> Nome do cliente </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Nome:</span>
      <a [routerLink]="['editar', element.idCliente]">{{element.nome}}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Email:</span>
      <a [routerLink]="['editar', element.idCliente]">{{element.emailAdmin}}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="ativo">
    <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Ativo </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Ativo:</span>
      <i class="fa" [class.fa-user-times]="!element.ativo" [class.fa-user-check]="element.ativo"></i>
      {{element.ativo ? 'Ativo': 'Desativado'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="carteira">
    <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Carteira </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Carteira:</span>
      <button mat-stroked-button matTooltip="Abrir a carteira do cliente" color="primary" [routerLink]="['carteira', element.idCliente]" ><i
          class="fas fa-wallet"></i>Abrir</button>
      <button mat-icon-button matTooltip="Importar arquivo do extrato e gerar APRS" color="primary"><i class="fas fa-paperclip"(click)="abrirDialogImportacao(element)"></i></button>
    </td>
  </ng-container>
  <ng-container matColumnDef="acoes">
    <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Ações </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Ações:</span>
      <button mat-icon-button matTooltip="Desativar cliente" color="warn" (click)="apagarCliente(element)"><i class="fas fa-user-slash"></i></button>
      <button mat-icon-button matTooltip="Logar como usuário do cliente" color="primary" [routerLink]="['/proxy-usuario', element.idCliente]"><i class="fas fa-user-secret"></i></button>
    </td>
  </ng-container>
</table>
<div class="spinner-container" *ngIf="datasource.loading$ | async">
  <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
</div>
<mat-paginator *ngIf="datasource.page$ | async as page"
  [length]="page.totalElements" [pageSize]="page.size"
  [pageIndex]="page.number" [hidePageSize]="true" 
  (page)="datasource.fetch($event.pageIndex)">
</mat-paginator>