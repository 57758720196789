import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogLoadingComponent } from 'src/app/_helpers/dialog-loading/dialog-loading.component';
import { UIService } from 'src/app/_helpers/ui.service';
import { BlackListService } from 'src/app/_services/blacklist.service';


@Component({
  selector: 'app-dialog-cad-cvm-blacklist',
  templateUrl: './dialog-cad-cvm-blacklist.component.html',
  styleUrls: ['./dialog-cad-cvm-blacklist.component.scss']
})
export class DialogCadCVMBlackList implements OnInit {

  value:null;

  constructor(
    private blacklistService: BlackListService,
    public dialogRef: MatDialogRef<DialogCadCVMBlackList>,
    private dialog: MatDialog,
    private uiService: UIService,
    private _fb: FormBuilder) {
    }

  ngOnInit() {

  }

  onChange(e) {
    this.value = e.target.value;
  }

  submit() {
    console.log(this.value)
    if(this.value) {

        const loadingDialog = this.dialog.open(DialogLoadingComponent, {
          data: {
            text: 'Registrando ...',
            target: this.blacklistService.salvarBlackListCVM({value:this.value}),
            closeOnComplete: true,
            successMessage: 'Registro inserido com sucesso',
            errorMessage: ({error}) => `Erro: ${error.error}`
          }
        });
        loadingDialog.afterClosed().subscribe(result =>{
          if(result) {
            this.dialogRef.close();
          }
        })


      }
  }

}
