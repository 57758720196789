import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, throwError, Observable } from 'rxjs';
import { AuthService } from './../../_services/auth.service';
import { UIService } from './../../_helpers/ui.service';
import { exhaustMap, catchError, switchMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/_services/cliente.service';
import { trigger, transition, query, style, stagger, keyframes, animate } from '@angular/animations';
import { AppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { TermosComponent } from '../termos/termos.component';
import { Overlay } from '@angular/cdk/overlay';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { Usuario } from 'src/app/_models/user.models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class LoginComponent implements OnInit, OnDestroy {

  hideSenha = true;
  isLoading = false;
  logoCliente: string;
  private loadingSubs: Subscription;
  private usuario = new Subject<Usuario>();

  private usuarioLogado: Usuario;

  constructor(
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private clienteService: ClienteService,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private overlay: Overlay) { }

  ngOnInit() {
    this.loadingSubs = this.uiService.loadingChangeState.subscribe(
      isLoading => {
        this.isLoading = isLoading;
      }
    );

    this.store.select('usuario').subscribe((usuario: Usuario) => {
      this.logoCliente = usuario.logo;
      if (this.authService.isAuthenticated()) {
        this.router.navigate(['']);
      }
    });
    document.cookie = `access_token=`;
    this.authService.usuario$.subscribe(usuario => this.usuarioLogado = usuario);

    this.usuario.pipe(
      exhaustMap((usuario: Usuario) => {
        return this.authService.login(usuario);
      }),
    ).subscribe(
      (authenticated: boolean) => {
        if (authenticated) {
          if (!this.usuarioLogado.termosUso) {
            const scrollStrategy = this.overlay.scrollStrategies.reposition();
            const dialogTermos = this.dialog.open(TermosComponent, {
              disableClose: true,
              scrollStrategy: scrollStrategy
            });

            dialogTermos.afterClosed().subscribe(
              aceitou => {
                if (aceitou) {
                  this.authService.termos(aceitou).subscribe(v => {
                    this.router.navigate(['']);
                  });
                } else {
                  this.authService.logout('Você precisa aceitar os termos para usar o sistema');
                }
            });
          } else {
            this.router.navigate(['']);
          }
      }
      const msg = authenticated ? 'Usuário autenticado com sucesso' : 'Usuário ou senha incorretos';
      this.uiService.showSnackBar(msg, null, 3000);
    });
  }

  ngOnDestroy() {
    this.usuario.unsubscribe();
  }

  handleLogin(email: string, senha: string) {
    this.usuario.next(<Usuario>{eMail: email, pwd: senha, logo: this.logoCliente});
  }

  carregarImagemCliente(emailCliente) {
    this.logoCliente = '';
    if (emailCliente) {
      this.clienteService.retornaLogoCliente(null, emailCliente).subscribe(foto => {
        this.logoCliente = foto['foto'];
      });
    }
  }

  abrirDialogRecuperacaoSenha() {
    this.dialog.open(RecuperarSenhaComponent, {
      maxWidth: '80vw',
      minWidth: '50vw',
    });
  }
}
