<div class="flex">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="alterarSenha()">
        <mat-form-field>
          <input matInput #senha formControlName="senha" name="senha" autocomplete="new-password" placeholder="Nova senha" [type]="hideSenha ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="hideSenha = !hideSenha">{{hideSenha ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="form.controls['senha']?.errors?.passwordStrength">{{form.controls['senha'].errors.passwordStrength}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput #novaSenha formControlName="novaSenha" name="novaSenha" autocomplete="new-password" placeholder="Repetir a senha" [type]="hideSenha ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="hideSenha = !hideSenha">{{hideSenha ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="senha.value !== novaSenha.value">Senhas não conferem</mat-error>
          <mat-error *ngIf="form.controls['novaSenha']?.errors?.passwordStrength">{{form.controls['novaSenha'].errors.passwordStrength}}</mat-error>
        </mat-form-field>
        <div>
          <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
          <span style="clear: both;"></span>
          <button type="submit" [disabled]="form.invalid || senha.value !== novaSenha.value" mat-raised-button style="margin-top: 5px" color="primary"><mat-icon>input</mat-icon> Alterar a senha</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
