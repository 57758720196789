import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PageRequest, Page } from '../_models/PaginatedResult';
import { Observable } from 'rxjs';
import { Enquadramento, ViewEnquadramento, TipoEnquadramento } from '../_models/resolucao.model';
  
export interface ResolucaoQuery{
  search?: string,
  tipo?: TipoEnquadramento,
  pre2018?: boolean;
}

export interface EnquadramentoQuery{
  mes: string;
  ano: string;
  idCliente: string;
  idEnquadramento: string;
  cnpj: string;
}


@Injectable({
  providedIn: 'root'
})
export class ResolucaoService {
  private resolucaoURL = `${environment.PATH_API}/resolucao`;

  constructor(private http: HttpClient) { }

  lista(request: PageRequest<Enquadramento>, query: ResolucaoQuery, autocomplete: boolean = false):Observable<Page<Enquadramento>> {
    const url = `${this.resolucaoURL}`;
    let params = new HttpParams();
    if (query.search) {
      params = params.append('filtro', query.search);
    }
    if (query.tipo) {
      params = params.append('tipo', query.tipo);
    }
    if (query.pre2018 !== undefined) {
      params = params.append('pre2018', query.pre2018 ? "S" : "N");
    }
    if (autocomplete) {
      params = params.append('autocomplete', autocomplete ? "S" : "N");
    }
    params = params.append('page', request.page.toString());
    params = params.append('limit', request.size.toString());
    return this.http.get<Page<Enquadramento>>(url, {params: params});
  }

  novaResolucao(resolucao: Enquadramento) {
    const url = `${this.resolucaoURL}/`;
    return this.http.post<Enquadramento>(url, resolucao);
  }

  getResolucao(id: number) {
    const url = `${this.resolucaoURL}/${id}`;
    return this.http.get<Enquadramento>(url);
  }

  editarResolucao( id: number, resolucao: Enquadramento) {
    const url = `${this.resolucaoURL}/${id}`;
    return this.http.put(url, resolucao);
  }
  
  enquadramentosFundo( cnpj: string): Observable<Enquadramento[]> {
    cnpj = cnpj.replace(/[^\d\s]/gi, '');
    const url = `${this.resolucaoURL}/fundo/${cnpj}`;
    return this.http.get<Enquadramento[]>(url);
  }

  viewEnquadramentos(query: Partial<EnquadramentoQuery>): Observable<ViewEnquadramento[]> {
    const url = `${this.resolucaoURL}/enquadramentos`;
    let params = new HttpParams();
    Object.keys(query).forEach(chave => {
      params = params.append(chave, query[chave])
    })
    return this.http.get<ViewEnquadramento[]>(url, { params });
  }

  adicionarEnquadramento( id: number, cnpj: string) {
    const url = `${this.resolucaoURL}/add/${id}`;
    return this.http.post(url, { cnpj });
  }

  removerEnquadramento(id: number, cnpj: string) {
    const url = `${this.resolucaoURL}/del/${id}`;
    return this.http.post(url, { cnpj });
  }
}
