import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APR, APRSigner } from "../_models/arp.model";
import { Page, PageRequest } from "../_models/PaginatedResult";
import { environment } from "./../../environments/environment";

export interface AprQuery {
  search: string;
  status?: string;
  periodo?: string;
  idCliente?: number;
}

@Injectable({
  providedIn: "root",
})
export class APRService {
  private aprURL = `${environment.PATH_API}/APR`;

  constructor(private http: HttpClient) {}

  listaTodosPaginado(
    request: PageRequest<APR>,
    query: AprQuery
  ): Observable<Page<APR>> {
    const url = `${this.aprURL}/all`;
    let params = new HttpParams();
    if (query.search) {
      params = params.append("filtro", query.search);
    }
    if (query.status) {
      params = params.append("status", query.status);
    }
    if (query.idCliente) {
      params = params.append("idCliente", query.idCliente.toString());
    }
    if (query.periodo) {
      params = params.append("periodo", query.periodo);
    }
    params = params.append("page", request.page.toString());
    params = params.append("limit", request.size.toString());
    return this.http.get<Page<APR>>(url, { params: params });
  }

  listaPropriosPaginado(
    request: PageRequest<APR>,
    query: AprQuery
  ): Observable<Page<APR>> {
    const url = `${this.aprURL}/own`;
    let params = new HttpParams();
    if (query.search) {
      params = params.append("filtro", query.search);
    }
    if (query.status) {
      params = params.append("status", query.status);
    }
    if (query.periodo) {
      params = params.append("periodo", query.periodo);
    }
    params = params.append("page", request.page.toString());
    params = params.append("limit", request.size.toString());
    return this.http.get<Page<APR>>(url, { params: params });
  }

  listaAssinadoresCliente(idCliente: Number, name?: string) {
    const url = `${this.aprURL}/signers/${idCliente}`;
    let params = new HttpParams();
    if (name) {
      params = params.append("query", name);
    }
    return this.http.get<APRSigner[]>(url, { params });
  }

  parseExtrato(idCliente: number, filename: string, body: string) {
    const url = `${this.aprURL}/parse/${idCliente}`;
    return this.http.post<APR[]>(url, { filename, body });
  }

  novoAssinador(signer: APRSigner) {
    const url = `${this.aprURL}/signers/`;
    return this.http.post<APRSigner>(url, signer);
  }

  atualizarAssinador(id, signer: APRSigner) {
    const url = `${this.aprURL}/signers/${id}`;
    return this.http.put<APRSigner>(url, signer);
  }

  removerAssinador(id: number) {
    const url = `${this.aprURL}/signers/${id}`;
    return this.http.delete(url);
  }

  getApr(id: number) {
    const url = `${this.aprURL}/${id}`;
    return this.http.get<APR>(url);
  }

  criarApr(apr: APR) {
    const url = `${this.aprURL}/`;
    return this.http.post(url, apr);
  }

  editarApr(id: number, apr: APR) {
    const url = `${this.aprURL}/${id}`;
    return this.http.put(url, apr);
  }
}
