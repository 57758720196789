<h1><i class="fab fa-stack-overflow"></i>Conjuntos de fundos</h1>

<div class="acoes">
  <mat-form-field>
    <input #in (input)="datasource.queryBy({search: in.value})" matInput placeholder="Filtro">
  </mat-form-field>   

  <button routerLink="novo" mat-raised-button color="accent">
    <i class="fas fa-plus"></i> Novo conjunto de fundos
  </button>
</div>
<div class="spinner-container" *ngIf="true">
  <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="false"></mat-spinner>
</div>
<table mat-table [dataSource]="datasource">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef> Nome </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Nome:</span>
      <a [routerLink]="['editar', element.idConjunto]">{{element.nome}}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="acoes">
    <th mat-header-cell *matHeaderCellDef style="width: 20%;"> Ações </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Ações:</span>
      <button mat-raised-button>text</button>
    </td>
  </ng-container>
</table>
<div class="spinner-container" *ngIf="datasource.loading$ | async">
  <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
</div>
<mat-paginator *ngIf="datasource.page$ | async as page"
  [length]="page.totalElements" [pageSize]="page.size"
  [pageIndex]="page.number" [hidePageSize]="true" 
  (page)="datasource.fetch($event.pageIndex)">
</mat-paginator>