<h1 class="title">DREI - Demonstrativo de Rentabilidade e Evolução dos Investimentos</h1>

<div class="acoes">
  <!-- <mat-form-field>
    <mat-label>Mês</mat-label>
    <mat-select #mes placeholder="Mês" (selectionChange)="datasource.queryBy({ mes: mes.value })" >
      <mat-option>Selecione</mat-option>
      <mat-option value="1">01</mat-option>
      <mat-option value="2">02</mat-option>
      <mat-option value="3">03</mat-option>
      <mat-option value="4">04</mat-option>
      <mat-option value="5">05</mat-option>
      <mat-option value="6">06</mat-option>
      <mat-option value="7">07</mat-option>
      <mat-option value="8">08</mat-option>
      <mat-option value="9">09</mat-option>
      <mat-option value="10">10</mat-option>
      <mat-option value="11">11</mat-option>
      <mat-option value="12">12</mat-option>
    </mat-select>
  </mat-form-field> -->
  <button routerLink="novo" mat-raised-button color="accent" routerLink="novo" *ngIf="idCliente == null">
    <mat-icon>add</mat-icon> Novo DREI
  </button>
</div>

  <table mat-table [dataSource]="datasource">

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

    <ng-container matColumnDef="mes">
      <th mat-header-cell *matHeaderCellDef> Mês </th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Mês:</span>
        <a [routerLink]="['editar', element.id]">{{element.mes}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="rentabilidadeAcumulada">
      <th mat-header-cell *matHeaderCellDef> Rent. Acumulada Trimestre</th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Rent. Acumulada Trimestre:</span>
        <a [routerLink]="['editar', element.id]">{{element.rentabilidadeAcumulada / 100 | percent:"1.4-4"}}</a>
      </td>
    </ng-container>


    <ng-container matColumnDef="dataAvaliacao">
      <th mat-header-cell *matHeaderCellDef>Data última avaliação atuarial</th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Data última avaliação atuarial:</span>
        <a [routerLink]="['editar', element.id]">{{element.dataAvaliacao  | date:'dd/MM/yyyy'}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="metaRentabilidade">
      <th mat-header-cell *matHeaderCellDef>Meta Rentabilidade</th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Meta Rentabilidade:</span>
        <a [routerLink]="['editar', element.id]">{{element.metaRentabilidade / 100 | percent:"1.4-4"}}</a>
      </td>
    </ng-container>


    <ng-container matColumnDef="inflacaoAcumulada">
      <th mat-header-cell *matHeaderCellDef>Inflação Acumulada</th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Inflação Acumulada:</span>
        <a [routerLink]="['editar', element.id]">{{element.inflacaoAcumulada / 100  | percent:"1.4-4"}}</a>
      </td>
    </ng-container>


    <ng-container matColumnDef="acoes">
      <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Ações </th>
      <td mat-cell *matCellDef="let element">
        <span class="mobile-label">Ações:</span>
        <button mat-raised-button color="accent" (click)="exportarXML(element)"><i class="fas fa-download"></i>Exportar XML</button>
        <!-- &nbsp;<button mat-raised-button color="warn" (click)="apagar(element)"><i class="fas fa-trash"></i>Excluir</button> -->
      </td>
    </ng-container>


  </table>

