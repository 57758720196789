import { FormControl  } from '@angular/forms';
import { Injectable } from '@angular/core';
import { UsuarioService } from '../_services/usuario.service';
import { map, debounceTime } from 'rxjs/operators';

@Injectable()
export class UserValidator {
    constructor(private usuarioService: UsuarioService) {}

    checkEmail(control: FormControl): any {
        return this.usuarioService.usuarioExiste(control.value).pipe(
            debounceTime(1000),
            map(v => v['exists'] ? {userExists: true} : null)
        );
      }
}
