import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClienteService } from 'src/app/_services/cliente.service';
import { ValidateCPFCNPJ } from 'src/app/_validators/cpf_cnpj.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { UIService } from 'src/app/_helpers/ui.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from 'src/app/_helpers/dialog-confirm/dialog-confirm.component';
import { DialogLoadingComponent } from 'src/app/_helpers/dialog-loading/dialog-loading.component';
import { debounce, debounceTime, switchMap } from 'rxjs/operators';
import { ConjuntoService } from 'src/app/_services/conjunto.service';
import { UserValidator } from 'src/app/_validators/user.validator';
import { MetaService } from 'src/app/_services/meta.service';
import { Conjunto } from 'src/app/_models/conjuntos.model';
import { Cliente } from 'src/app/_models/cliente.model';
import { Meta } from 'src/app/_models/benchmark.models';
import { APRSigner } from 'src/app/_models/arp.model';

@Component({
  selector: 'app-cadastro-cliente',
  templateUrl: './cadastro-cliente.component.html',
  styleUrls: ['./cadastro-cliente.component.scss']
})
export class CadastroClienteComponent implements OnInit {

  idCliente: number;
  loading = true;
  filtroConjunto: BehaviorSubject<string>;
  conjuntoSelecionado: Conjunto;
  conjuntos: Conjunto[];
  metas: Meta[];

  conjuntos$: Observable<Conjunto[]>;
  form: FormGroup;
  masks = {telefone1: '(00) 0000-00009', telefone2: '(00) 0000-00009'};
  customPatterns = { '0': { pattern: new RegExp('-|[0-9]') }, '9': { pattern: new RegExp('[0-9]') } }
  constructor(
    private clienteService: ClienteService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private uiService: UIService,
    private conjuntoService: ConjuntoService,
    private router: Router,
    private userValidator: UserValidator,
    private metaService: MetaService) {
      this.filtroConjunto = new BehaviorSubject('');
      this.conjuntos = [];

      this.idCliente = +this.route.snapshot.paramMap.get('id');

      this.form = new FormGroup({
        nome: new FormControl(null, Validators.required),
        cnpj: new FormControl(null, [Validators.required, ValidateCPFCNPJ]),
        emailAdmin: new FormControl(null, [Validators.email], [userValidator.checkEmail.bind(this.userValidator)]),
        telefone1: new FormControl(null),
        telefone2: new FormControl(null),
        foto: new FormControl(null),
        meta: new FormControl(null),
        cep: new FormControl(null),
        logradouro: new FormControl(null),
        numero: new FormControl(null),
        complemento: new FormControl(null),
        bairro: new FormControl(null),
        municipio: new FormControl(null),
        ibge: new FormControl(null),
        uf: new FormControl(null),
        tipoEnquadramento: new FormControl(null),
        limitePolitica: new FormControl(null),
        acoes: new FormControl([]),
        assinadores: new FormControl([]),
      });
      if (this.idCliente) {
        this.form.get('emailAdmin').setAsyncValidators([]);
      }
    }


  ngOnInit() {
    if (this.idCliente) {
      this.loading = true;
      this.clienteService.buscarClientePorId(this.idCliente).subscribe(
        cliente => {
          console.log(cliente);
          this.form.patchValue(cliente);
          this.loading = false;
        },
        error => {},
        () => {
          this.mascaraTelefone('telefone1');
          this.mascaraTelefone('telefone2');
        }
      );
      this.clienteService.retornaLogoCliente(this.idCliente).subscribe(
        foto => this.form.patchValue(foto)
      );
      this.clienteService.buscarConjuntosCliente(this.idCliente).subscribe(
        conjuntos => this.conjuntos = conjuntos
      );
    } else {
      this.loading = false;
    }

    this.conjuntos$ = this.filtroConjunto.pipe(
      debounceTime(400),
      switchMap(filtro => this.conjuntoService.listarConjuntos(filtro))
    );

    this.metaService.listarMetas().subscribe(metas => {
      this.metas = metas;
    });
  }

  addAssinador(assinador: APRSigner) {
    const { assinadores } = this.form.value;
    if(assinador) {
      console.log([...assinadores, assinador]);
      this.form.patchValue({ assinadores: [...assinadores, assinador] })
    }    
  }

  mascaraTelefone = (field) => {
    if (this.form.value[field] != null && this.form.value[field].length <= 10) {
      this.masks[field] = '(00) 0000-00009';
    } else {
      this.masks[field] =  '(00) 0 0000-0000';
    }
  }

  consultarEnderecoViaCep(cep: string) {
    if (cep) {
      this.clienteService.getEnderecoPorCep(cep).subscribe(
        response => {
          this.form.patchValue(response);
          this.form.get('municipio').setValue(response['localidade']);
        }
      );
    }
    return false;
  }

  salvar() {
    const obj = <Cliente> this.form.value;
    obj.conjuntos = this.conjuntos.map(c => c.idConjunto);

    let salvo: Observable<any>;
    if (this.idCliente) {
      salvo = this.clienteService.atualizarCliente(this.idCliente, obj);
    } else {
      salvo = this.clienteService.salvarCliente(obj);
    }
    const dialogRef = this.dialog.open(DialogLoadingComponent, {
      data: {
        text: 'Salvando cliente...',
        target: salvo,
        closeOnComplete: true,
        successMessage: 'Cliente salvo com sucesso',
        errorMessage: 'Erro ao salvar, tente novamente'
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.router.navigateByUrl('/clientes');
      }
    });
  }

  handleFiltroConjunto = (valor: string) => this.filtroConjunto.next(valor);
  displayConjunto = (conjunto: Conjunto) => conjunto ? `${conjunto.nome}` : '';
  selectConjunto = (conjunto: Conjunto) => this.conjuntoSelecionado = conjunto;
  removerConjunto = (index: number) => this.conjuntos.splice(index, 1);
  adicionarConjunto = () => {
    this.filtroConjunto.next('');
    if (this.conjuntoSelecionado && !this.conjuntos.map(c => c.idConjunto).includes(this.conjuntoSelecionado.idConjunto)) {
      this.conjuntos.push(this.conjuntoSelecionado);
    }
  }
}
