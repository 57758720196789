<div id="dialog-delete">
  <div class="title-dialog">
      <h2 mat-dialog-title>Grafico para meta {{data.meta.nome}}</h2>
  </div>
  <mat-dialog-content>
    <form  [formGroup]="form" style="display: block;">
        <mat-slide-toggle formControlName="grafico" >Exibir graficos para essa meta.</mat-slide-toggle>
        <br/>
        <mat-divider></mat-divider>
        <br/>
        <mat-form-field style="width: 100%;">
          <mat-label>Campos a exibir</mat-label>
          <mat-select formControlName="camposGrafico" multiple>
            <mat-option value="percentualMes">Percentual mês</mat-option>
            <mat-option value="percentualAno">Percentual ano</mat-option>
            <mat-option value="percentual3">Percentual 3M</mat-option>
            <mat-option value="percentual6">Percentual 6M</mat-option>
            <mat-option value="percentual12">Percentual 12M</mat-option>
            <mat-option value="percentual18">Percentual 18M</mat-option>
            <mat-option value="percentual24">Percentual 24M</mat-option>
            
          </mat-select>
        </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="warn" mat-dialog-close><i class="fas fa-times"></i>Cancelar</button>
    <button mat-flat-button color="primary" (click)="salvarDadosMeta()"><i class="fas fa-check"></i>Confirmar</button>
  </mat-dialog-actions>
</div>