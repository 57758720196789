import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Documento } from "src/app/_models/documentos.model";
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { ComentarioService } from "src/app/_services/comentario.service";
import {
  DocumentoQuery,
  DocumentoService,
} from "src/app/_services/documento.service";
import { PaginatedDataSource } from "src/app/_services/generic-datasource";
import { environment } from "src/environments/environment";
import { CriarComentarioNovoComponent } from "./criar-comentario/criar-comentario-novo.component";

@Component({
  selector: "app-Comentarios-novo",
  templateUrl: "./Comentarios-novo.component.html",
  styleUrls: ["./Comentarios-novo.component.scss"],
})
export class ComentariosNovoComponent implements OnInit {
  tipoUsuario = TipoUsuario;

  columns = ["titulo", "tipo", "data", "arquivo"];
  datasource = new PaginatedDataSource<Documento, DocumentoQuery>(
    (request, query) =>
      this.comentarioService.listarDocumentosPaginadoNovo(request, query),
    { property: "data", order: "desc" },
    { search: "" },
    environment.LIMITE_REGISTROS_LISTA
  );

  constructor(
    private dialog: MatDialog,
    private comentarioService: ComentarioService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService._usuario.subscribe((user) => {
      if ([TipoUsuario.ADMIN, TipoUsuario.USUARIO].includes(user.tipoUser)) {
        this.columns = ["titulo", "tipo", "data", "arquivo", "acoes"];
      }
    });
  }

  abrirDialogNovo() {
    const dialog = this.dialog.open(CriarComentarioNovoComponent, {
      width: "500px",
    });

    dialog.afterClosed().subscribe((result) => {
      this.datasource.fetch(0);
    });
  }

  abrirDocumento(idDocumento: number) {
    this.comentarioService
      .buscarLinkDocumentoNovo(idDocumento)
      .subscribe((value: any) => {
        const { link } = value;
        window.open(link, "_blank");
      });
  }

  apagarDocumento(idDocumento: number) {
    const del = confirm(
      "O item selecionado será deletado. Confirme a operação"
    );
    if (del) {
      this.comentarioService
        .deleteDocumentoNovo(idDocumento)
        .subscribe((value: any) => {
          this.datasource.fetch(0);
        });
    }
  }
}
