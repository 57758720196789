import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Provisao, RegistroAnbima } from './../compliance-anbima.component';
@Component({
  selector: 'app-FundoCompliance',
  templateUrl: './FundoCompliance.component.html',
  styleUrls: ['./FundoCompliance.component.scss']
})
export class FundoComplianceComponent implements OnInit {

  fundo$: Subject<RegistroAnbima> = new Subject();
  public fundo: RegistroAnbima;

  @Input() set fundoEntrada(fundo: RegistroAnbima) {
    this.fundo$.next(fundo)
  }

  @Input()
  consolidado: boolean = false;

  public valorLimite: number = 150000000;

  totalPL: number = 0;
  totalAcoes: number = 0;
  totalRendaFixa: number = 0;
  totalMultimercado: number = 0;
  resumoProvisoes:any[] = [];
  chartAcoes: any[] = []
  chartDistribuicao: any[] = []

  constructor() {
    this.fundo$.subscribe(fundo => {

      this.fundo = fundo;

      this.totalPL = this.fundo.header.patrimonioLiquido;
      if(this.fundo.tituloPublico.length > 0) {
        this.totalRendaFixa = this.fundo.tituloPublico.reduce((acc, item) => acc + item.valorFinDisp + item.valorFinemGar,0)
      }
      this.resumoProvisoes = [
        { tipo: 'Débito', valor: 0, sinal: -1},
        { tipo: 'Crédito', valor: 0, sinal: 1}
      ]

      this.totalAcoes = this.fundo.acoes.filter(this.filtroClasseOperacaoC).reduce((acc, item) => acc + item.valorFinDisp + item.valorFinemGar, 0)

      this.chartAcoes = this.fundo.acoes.filter(this.filtroClasseOperacaoC).map(item =>{
        return { name: item.codAtivo, value: ((item.valorFinDisp + item.valorFinemGar) / this.fundo.header.patrimonioLiquido) * 100 }
      })

      this.resumoProvisoes = this.fundo.provisoes.reduce((acc, item: Provisao) => {
        if(item.tipo === 'C'){
          acc[1].valor += item.valor;
        } else {
          acc[0].valor += item.valor;
        }
        return acc;
      }, this.resumoProvisoes)

      let totalProvisoes = (this.resumoProvisoes[0].valor * -1) + (this.resumoProvisoes[1].valor)
      if(totalProvisoes < 0) {
        totalProvisoes *= -1
      }

      this.chartDistribuicao = [
        { name: 'Total ações', value: this.totalAcoes },
        { name: 'Total Renda Fixa', value: this.totalRendaFixa },
        { name: 'Total Provisões', value: totalProvisoes },
        { name: 'Total Disponibilidades', value: this.fundo.caixa.saldo },
      ]
    })
  }

  ngOnInit() {
  }

  filtroGarantia = (item: any) => item.valorFinemGar > 0;
  filtroDisponivel = (item: any) => item.valorFinDisp > 0;
  filtroClasseOperacaoC = (item: any) => item.classeOperacao == 'C';
}
