<h1 class="title">Resultado da otimização</h1>
<mat-spinner [diameter]="45" *ngIf="loading"></mat-spinner>

<div fxLayout="row" fxLayout.lt-md="column" *ngIf="!loading" fxLayoutAlign="start start" fxLayoutGap="20px">
  <div fxFlex="20" fxFlex.lt-md="50"><strong>Data base:</strong> {{this.informacoesOtimizacao.database}}</div>
  <div fxFlex="20" fxFlex.lt-md="50"><strong>Número de meses observados:</strong> {{this.informacoesOtimizacao.numMeses}} meses</div>
</div>

<div class="wrapper">
  <div [hidden]="loading" class="dados">
    <mat-tab-group animationDuration="500ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fas fa-table"></i>Dados
        </ng-template>
        <ng-template matTabContent>
          <div class="tab-content">
            <div class="table-container">
              <table mat-table *ngIf="pontosRentabilidade" [dataSource]="series" class="mat-elevation-z1">
                <ng-container matColumnDef="rentabilidade">
                  <th mat-header-cell *matHeaderCellDef> Rentabilidade </th>
                  <td mat-cell *matCellDef="let element"> {{element.value | percent:'1.3'}} </td>
                </ng-container>
                <ng-container matColumnDef="sharpe">
                  <th mat-header-cell *matHeaderCellDef> Sharpe </th>
                  <td mat-cell *matCellDef="let element"> {{element.name | percent:'1.3'}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['rentabilidade', 'sharpe']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['rentabilidade', 'sharpe'];"></tr>
              </table>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fas fa-chart-line"></i>Otimização
        </ng-template>
        <div class="tab-content">
          <mat-form-field *ngIf="!loading">
            <mat-select (selectionChange)="selecionadoRentabilidade($event.value)" [value]="otimizacaoSelecionada?.objetivo" placeholder="Qual a rentabilidade desejada?">
              <mat-option *ngFor="let rent of pontosRentabilidade" [value]="rent.value">{{rent.label}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="table-container">
            <table mat-table [dataSource]="dataSourceOtimizacao" matSortActive="peso" matSortDirection="desc" [hidden]="!otimizacaoSelecionada" matSort class="mat-elevation-z1">
              <ng-container matColumnDef="cnpj">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome/CNPJ Fundo </th>
                <td mat-cell style="width: 80%" *matCellDef="let element">
                    <small matLine>
                      {{element.nome}}
                    </small><br />
                    <small style="font-weight: bolder" matLine>
                      {{element.cnpj | mask: '00.000.000/0000-00'}}
                    </small>
                </td>
                <td mat-footer-cell *matFooterCellDef style="background: #f0f0f0; border-top: 2px solid #01579b;"> Total </td>
              </ng-container>
              <ng-container matColumnDef="peso">
                <th mat-header-cell style="text-align: center;" *matHeaderCellDef mat-sort-header> Pesos (%) </th>
                <td mat-cell style="text-align: center;" *matCellDef="let element"> {{element.peso | percent:'1.3'}} </td>
                <td mat-footer-cell *matFooterCellDef style="background: #f0f0f0; border-top: 2px solid #01579b;"> {{getTotalPercentual() | percent:'1.2'}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>
          </div>
          <p *ngIf="otimizacaoSelecionada">Fundos analisados: {{otimizacaoSelecionada.fundos.length}}</p>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fas fa-bolt"></i>Ranking dinâmico
        </ng-template>
        <ng-template matTabContent>
          <div class="tab-content">
            <div class="table-container">
              <table mat-table *ngIf="rankingDinamico" [dataSource]="rankingDinamico | async" class="mat-elevation-z1">
                <ng-container matColumnDef="cnpj">
                  <th mat-header-cell *matHeaderCellDef> Nome/CNPJ Fundo </th>
                  <td mat-cell style="width: 70%" *matCellDef="let element">
                    <small matLine>
                      {{element.nome}}
                    </small><br />
                    <small style="font-weight: bolder" matLine>
                      {{element.cnpj | mask: '00.000.000/0000-00'}}
                    </small>
                  </td>
                </ng-container>
                <ng-container matColumnDef="ranking">
                  <th mat-header-cell style="text-align: right" *matHeaderCellDef> Ranking </th>
                  <td mat-cell style="text-align: right" *matCellDef="let element"> {{element.rank}}º ({{element.total | percent:'1.3'}}) </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['cnpj', 'ranking']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['cnpj', 'ranking'];"></tr>
              </table>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <ng-template #template let-teste="teste" let-nome="nome" >
      <div style="position: relative; display: block">
        <div style="position: absolute; top: 25px; left: 0%; width: 150%; background: #FFF; z-index: 99999; border: 2px solid #01579b; border-radius: 5px; padding: 3px;">
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
            <div fxFlex="50"><strong>Nome:</strong> {{nome}}</div>
          </div>
        </div>
      </div>
    </ng-template>

  </div>
  <div class="grafico">
    <ngx-charts-line-chart
    *ngIf="resultado.length"
    [results]="resultado"
    [gradient]="false"
    [scheme]="colorScheme"
    [yScaleMin]="0"
    [xScaleMin]="0"
    [xAxis]="true"
    [yAxis]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    yAxisLabel="Rentabilidade"
    xAxisLabel="Sharpe"
    [yAxisTickFormatting]="formatarY"
    [xAxisTickFormatting]="formatarX"
    (select)="selecionadoRentabilidade($event.value)"
    [referenceLines]="referenceLines"
    [showRefLines]="true">
  </ngx-charts-line-chart>
</div>
</div>
