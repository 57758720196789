import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Enquadramento } from 'src/app/_models/resolucao.model';
import { FormControl } from '@angular/forms';
import { ResolucaoService } from 'src/app/_services/resolucao.service';
import { switchMap, catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-enquadramento',
  templateUrl: './autocomplete-enquadramento.component.html',
  styleUrls: ['./autocomplete-enquadramento.component.scss']
})
export class AutocompleteEnquadramentoComponent implements OnInit {
  filtroEnquadramento: BehaviorSubject<string>;
  enquadramentos$: Observable<Enquadramento[]>;
  enquadramento: Subject<Enquadramento>;

  @Input("control") control: FormControl;
  @Input("label") label: string;
  
  constructor(private resolucaoService: ResolucaoService) {
    this.filtroEnquadramento = new BehaviorSubject('');
    this.enquadramento = new Subject()
  }

  ngOnInit() {
    this.enquadramentos$ = this.filtroEnquadramento.pipe(
      switchMap(value => this.resolucaoService.lista({ page:0, size: 20, sort: { property: 'id', order: 'asc' } }, { search: value}, true).pipe(
        map(c => c.data),
        catchError(() => of([]))
      )),
    );
  }

  ngAfterViewInit() {
    this.enquadramento.subscribe(cliente => this.control.setValue(cliente));
  }

  handleFocus = () => this.filtroEnquadramento.next('');

  handleFiltro = (valor: string) => {
    this.filtroEnquadramento.next(valor);
  }
  displayFundo = (enquadramento: Enquadramento) => enquadramento ? `${enquadramento.nome} - ${enquadramento.legislacao } (${enquadramento.tipo})` : '';
  selectFundo = (enquadramento: Enquadramento) => this.enquadramento.next(enquadramento);

  ngOnDestroy() {
    this.enquadramento.complete()
  }
}