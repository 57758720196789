

<form [formGroup]="form" (ngSubmit)="salvarDados()" >
  <h4>EDITAR RIPP</h4>


  <!-- <div fxLayout="row" fxLayout.lt-md="column" class="col-3">
    <mat-form-field >
      <input matInput [matDatepicker]="picker" readonly formControlName="data_aplicacao_inicial" placeholder="Data de aplicação inicial">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div> -->
  <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
  <mat-form-field>
    <input
      #numero_apr
      matInput
      formControlName="numero_apr"
      placeholder="Número da APR"
      aria-label="Número da APR"
    />
  </mat-form-field>

  </div>
  <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
    <mat-form-field>
      <input
        #total_resgates
        matInput

        currencyMask
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' ,precision:8}"
        formControlName="total_resgates"
        placeholder="Total de Resgates"
        aria-label="Total de Resgates"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        #total_reaplicacao
        matInput

        currencyMask
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' ,precision:8}"
        formControlName="total_reaplicacao"
        placeholder="Total de Reinvestimentos"
        aria-label="Total de Reinvestimentos"
      />
    </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
      <mat-form-field>
        <input
          #saldo
          matInput
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' ,precision:8 }"
          formControlName="saldo"
          placeholder="Saldo"
          aria-label="Saldo"
        />
      </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
      <mat-form-field>
        <input
        #percentual_investido
        matInput
        formControlName="percentual_investido"
        placeholder="Percentual Investido"
        [dropSpecialCharacters]="false"
        aria-label="Percentual Investido"
        mask="percent"
        sufix="%">
      </mat-form-field>
      <mat-form-field>
        <input
          #rendimento_periodo
          matInput
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' ,precision:8}"
          formControlName="rendimento_periodo"
          placeholder="Rendimento no Período"
          aria-label="Rendimento no Período"
        />
      </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
        <mat-form-field >
          <input
            #cota_inicial
            matInput
            formControlName="cota_inicial"
            currencyMask
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' ,precision:8}"
            placeholder="Valor Unitário Cota Inicial (R$)"
            aria-label="Valor Unitário Cota Inicial (R$)"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            #qtd_cotas_inicial
            matInput
            type="number"
            formControlName="qtd_cotas_inicial"
            placeholder="Quantidade de Cotas"
            aria-label="Quantidade de Cotas"
          />
        </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" class="col-2">

          <mat-form-field>
            <input
              #total_cotistas
              matInput
              type="number"
              formControlName="total_cotistas"
              placeholder="Total de Cotistas"
              aria-label="Total de Cotistas"
            />
          </mat-form-field>
        </div>



  <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
    <mat-form-field >
      <input
        #patrimonio_iquido
        matInput
        currencyMask
        formControlName="patrimonio_liquido"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' ,precision:8 }"
        placeholder="Patrimônio Líquido (R$)"
        aria-label="Patrimônio Líquido (R$)"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        #rendimento_total
        matInput

        currencyMask
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' ,precision:8}"
        formControlName="rendimento_total"
        placeholder="Rendimento Total"
        aria-label="Rendimento Total"
      />
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
    <mat-form-field>
      <input
        #value_at_risk
        matInput

        formControlName="value_at_risk"
        placeholder="Value At Risk"
        [dropSpecialCharacters]="false"
        aria-label="Value At Risk"
        mask="percent"
        sufix="%"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        #volatilidade
        matInput

        formControlName="volatilidade"
        placeholder="Volatilidade"
        [dropSpecialCharacters]="false"
        aria-label="Volatilidade"
        mask="percent"
        sufix="%"
      />
    </mat-form-field>
  </div>






  <div fxLayout="row" fxLayout.lt-md="column" class="col-2">
    <mat-form-field>
      <input
        #fato_relevante
        matInput
        formControlName="fato_relevante"
        placeholder="Fato Relevante"
        aria-label="Fato Relevante"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        #provisionamento
        matInput
        formControlName="provisionamento"
        placeholder="Provisionamento"
        aria-label="Provisionamento"
      />
    </mat-form-field>
  </div>






  <div  fxLayout="row" fxLayout.lt-md="column">
    <mat-form-field>
      <mat-select required placeholder="Incluir no XML" formControlName="incluir_xml">
        <mat-option value="1"> Cadastro de Fundo de Investimento </mat-option>
        <mat-option value="2"> Alteração de Fundo de Investimento</mat-option>
        <mat-option value="3"> Não incluir no XML </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <br/>
  <div  [hidden]="!isLoading">
    <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
  </div>


  <div   class="buttons">
    <button type="submit" color="primary" mat-raised-button style="width:100%">
      <i class="fas fa-save"></i>Salvar
    </button>
  </div>
</form>
