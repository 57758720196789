import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resumoNomeFundo'
})
export class ResumoNomeFundoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value.replace("RENDA FIXA", "RF")
    value = value.replace("PREVIDENCIÁRIO", "PREV")
    value = value.replace("PREVIDENCIARIO", "PREV")
    value = value.replace("TÍTULOS", "TIT.")
    value = value.replace("PÚBLICOS", "PUB")
    value = value.replace("FUNDO DE INVESTIMENTO", "FI")
    value = value.replace("FUNDOS DE INVESTIMENTO", "FI")
    return value;
  }

}
