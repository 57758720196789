<h3>Alterando descrição da APR</h3>
<form
  [formGroup]="form"
  fxLayout="column"
  (ngSubmit)="salvar(form.value)"
  fxLayoutGap="20"
>

  <mat-form-field
  fxFlex="100"
  style="width: 100%;"
  >
  <textarea
    matInput
    placeholder="Descrição"
    formControlName="descricao"
    rows="6"
  ></textarea>
  </mat-form-field>

  <div class="buttons">
    <button
      mat-raised-button
      color="accent"
      type="submit"
      [disabled]="!form.valid"
    >
      <mat-icon>save</mat-icon>Salvar
    </button>
    <button mat-raised-button mat-dialog-close color="warn" type="button">
      <mat-icon>close</mat-icon> Cancelar
    </button>
  </div>
</form>