import {
  AfterViewInit, Component,




  ElementRef, OnDestroy, OnInit,


  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { DialogDeleteComponent } from "src/app/_helpers/dialog-delete/dialog-delete.component";
import { UIService } from "src/app/_helpers/ui.service";
import { Carteira, MovimentoCarteira } from "src/app/_models/carteira.model";
import {
  FIList, InformeFundo
} from "src/app/_models/fundo-investimento.models";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ClienteService } from "src/app/_services/cliente.service";
import { FundoInvestimentoService } from "src/app/_services/fundo-investimento.service";
import { DialogImportacaoExtrato } from '../dialog-importacao-extrato/dialog-importacao-extrato.component';
import { DialogAlteracaoDadosComponent } from "./dialog-alteracao-dados/dialog-alteracao-dados.component";
import { VisualizarInvestimentoComponent } from "./visualizar-investimento/visualizar-investimento.component";
import * as CryptoJS from 'crypto-js';
import moment from "moment";

@Component({
  selector: "app-carteira",
  templateUrl: "./carteira.component.html",
  styleUrls: ["./carteira.component.scss"],
})
export class CarteiraComponent implements OnInit, OnDestroy, AfterViewInit {
  idCliente: number;
  filtroFundo: BehaviorSubject<string>;
  fundos$: Observable<FIList[]>;
  fundoSelecionado: FIList;
  conjunto: Carteira[];
  idConjunto: number;
  complementoTitulo: string = "";
  saldo: number;

  form: FormGroup;

  showMessageNoQuote = false;

  @ViewChild("fundo", { static: true }) fundoInput: ElementRef;
  @ViewChild("form", { static: true }) formReset: ElementRef;

  constructor(
    public dialog: MatDialog,
    private fundoInvestimentoService: FundoInvestimentoService,
    private fb: FormBuilder,
    private uiService: UIService,
    private route: ActivatedRoute,
    private clienteService: ClienteService,
    private carteiraService: CarteiraService,
    private router: Router
  ) {
    this.filtroFundo = new BehaviorSubject("");
    this.form = fb.group({
      cnpjFundo: ["", Validators.required],
      dataInvestimento: [null, Validators.required],
      hora: ["10:00", Validators.required],
      valorInvestido: [{ value: null, disabled: true }, Validators.required],
      numCotas: [{ value: null, disabled: true }, Validators.required],
      valorCota: [{ value: null, disabled: true }, Validators.required],
      codigoAlternativo: [""],
      banco: [""],
      agencia: [""],
      conta: [""],
    });

    this.idCliente = route.snapshot.params["id"];
  }

  ngOnInit() {
    this.fundos$ = this.filtroFundo.pipe(
      switchMap((value) =>
        this.fundoInvestimentoService
          .filtroFundo(value)
          .pipe(catchError(() => of([])))
      )
    );
    this.conjunto = [];
    this.getCarteiraCliente();
    this.getSaldoCliente();
    if (this.idCliente) {
      this.clienteService.buscarClientePorId(this.idCliente).subscribe((c) => {
        this.complementoTitulo = ` - Cliente: ${c.nome}`;
      });
    }
  }

  getCarteiraCliente = () => {
    this.carteiraService
      .getCarteiraCliente(this.idCliente)
      .subscribe((dados) => (this.conjunto = dados));
  };

  getSaldoCliente = () => {
    this.carteiraService
      .getSaldoCliente(this.idCliente)
      .subscribe((saldo) => (this.saldo = saldo["saldo"]));
  };

  ngOnDestroy() {
    this.filtroFundo.unsubscribe();
  }

  openNew(path) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(path)
    );

    window.open(url, '_blank');
  }

  openNewExport(path, id) {
    const hash = CryptoJS.MD5(`${id}-${moment().format('DD/MM/YYYY')}-dinamico`);
    const url = `${path}/${id}/${hash}`;
    window.open(url, '_blank');
  }

  ngAfterViewInit() { }

  handleFiltro = (valor: string) => this.filtroFundo.next(valor);
  displayFundo = (fundo: FIList) =>
    fundo ? `${fundo.cnpj} - ${fundo.nome}` : "";

  selectFundo = (fundo: FIList) => {
    this.fundoSelecionado = fundo;
    this.form.patchValue({ cnpjFundo: fundo.cnpj, valorCota: null });
    this.form.get("valorInvestido").disable();
    this.form.get("valorCota").disable();
    this.form.get("numCotas").disable();
    if (this.form.value.dataInvestimento) {
      this.atualizaValorCota();
    }
  };

  removerFundo = (index: number) => this.conjunto.splice(index, 1);
  adicionarFundo = () => {
    this.filtroFundo.next("");
    const obj: Partial<Carteira> = {
      idCliente: this.idCliente,
      cnpjFundo: this.form.value.cnpjFundo,
      movimentos: [
        <MovimentoCarteira>{
          dataMovimento: this.form.value.dataInvestimento,
          hora: this.form.value.hora,
          valor: this.form.value.valorInvestido,
          numCotas: this.form.value.numCotas,
          operacao: "A",
        },
      ],
      codigoAlternativo: this.form.value.codigoAlternativo,
      banco: this.form.value.banco,
      agencia: this.form.value.agencia,
      conta: this.form.value.conta,
    };
    this.uiService
      .abrirDialogLoading(
        this.carteiraService.criarFundoCarteira(obj),
        "Adicionando fundo a carteira",
        "Fundo adicionado à carteira",
        "Erro ao adicionar fundo à carteira"
      )
      .subscribe((response) => {
        this.getCarteiraCliente();
        this.form.patchValue({
          cnpjFundo: "",
          dataInvestimento: null,
          hora: "10:00",
          valorInvestido: null,
          numCotas: null,
          valorCota: null,
          codigoAlternativo: "",
          banco: "",
          agencia: "",
          conta: "",
        });
        this.filtroFundo.next("");
        this.fundoInput.nativeElement.value = "";
      });
  };

  atualizaValorCota = () => {
    this.carteiraService
      .getValorCotaDia(
        this.form.value.cnpjFundo,
        this.form.value.dataInvestimento
      )
      .subscribe(
        (informe: InformeFundo) => {
          this.form.get("valorInvestido").enable();
          this.form.get("valorCota").enable();
          this.form.get("numCotas").enable();
          this.form.patchValue({ valorCota: informe.valorQuota });
          this.changeValorInvestido();
          this.showMessageNoQuote = false;
        },
        (error) => {
          this.showMessageNoQuote = true;
          this.form.get("valorInvestido").disable();
          this.form.get("valorCota").setValue(null);
          this.form.get("valorCota").disable();
          this.form.get("numCotas").setValue(null);
          this.form.get("numCotas").disable();
          this.uiService.showSnackBar(
            "Valor de cota inexistente ou não encontrado",
            "",
            3000
          );
        }
      );
  };

  changeValorInvestido = () => {
    const valorInvestido = this.form.value.valorInvestido;
    const valorCota = this.form.value.valorCota;
    this.form.patchValue({
      numCotas: this.carteiraService.calculaNumCotasPorValor(
        valorInvestido,
        valorCota
      ),
    });
  };

  abrirInvestimento = (investimento: Carteira) => {
    const dialog = this.dialog.open(VisualizarInvestimentoComponent, {
      data: {
        idCliente: investimento.idCliente,
        idInvestimento: investimento.id,
        investimento: investimento,
      },
      width: "90vw",
      height: "90vh",
    });

    dialog.beforeClosed().subscribe((v) => this.getSaldoCliente());
  };

  removerInvestimento = (investimento: Carteira) => {
    const dialog = this.dialog.open(DialogDeleteComponent, {
      data: {
        name: investimento.nomeFundo,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.carteiraService.removerFundo(investimento.id).subscribe(
          (success) => {
            this.uiService.showSnackBar(
              "Fundo removido com sucesso",
              "OK",
              4000
            );
            this.getCarteiraCliente();
          },
          (error) => {
            this.uiService.showSnackBar(error.error.status, "OK", 4000);
          }
        );
      }
    });
  };

  abrirEdicaoDadosBancarios = (investimento: Carteira) => {
    const dialog = this.dialog.open(DialogAlteracaoDadosComponent, {
      data: {
        idCliente: investimento.idCliente,
        idInvestimento: investimento.id,
        investimento: investimento,
      },
      width: "50vw",
    });

    dialog.beforeClosed().subscribe((v) => {
      this.getCarteiraCliente();
      this.getSaldoCliente();
    });
  };


  abrirDialogImportacao() {
    const dialogRef = this.dialog.open(DialogImportacaoExtrato, {
      width: '350px',
      data: { idCliente: this.idCliente }
    });
    dialogRef.beforeClosed().subscribe(result => {
      this.getCarteiraCliente();
      this.getSaldoCliente();
    });
  }


  navigate() {

    window.open("https://www.liasys.com.br/sisweb/audesp.php", "_blank");
  }
}
