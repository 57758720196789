<h1 class="title">Emissores de Alto Risco</h1>

<div class="acoes">

  <button mat-raised-button color="accent" (click)="abrirDialogNovo()">
    <mat-icon>add</mat-icon> Novo
  </button>

</div>


<table mat-table [dataSource]="datasource">

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef>Emissor</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Emissor:</span>
      {{element.value}}
    </td>
  </ng-container>

  <ng-container matColumnDef="acoes">
    <th mat-header-cell *matHeaderCellDef > Ações </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Ações:</span>
      <span style="display: flex; flex-flow: row; float: right;">
      <button mat-icon-button color="warn" (click)="excluirBlacklist(element)"  matTooltip="Excluir"><i class="fas fa-trash"></i></button>
    </span>
    </td>
  </ng-container>



</table>

