import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject, throwError, Observable } from 'rxjs';
import { AuthService } from './../../_services/auth.service';
import { UIService } from './../../_helpers/ui.service';
import { exhaustMap, catchError, switchMap, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ClienteService } from 'src/app/_services/cliente.service';
import { trigger, transition, query, style, stagger, keyframes, animate } from '@angular/animations';
import { AppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { TermosComponent } from '../termos/termos.component';
import { Overlay } from '@angular/cdk/overlay';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordStrengthValidator } from 'src/app/_validators/password-strength.validator';

@Component({
  selector: 'app-recuperacao-senha',
  templateUrl: './recuperacao-senha.component.html',
  styleUrls: ['./recuperacao-senha.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class RecuperacaoSenhaComponent implements OnInit {

  hideSenha = true;
  form: FormGroup;
  loading = false;

  constructor(
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute) {
      const token = route.snapshot.queryParamMap.get('token');
      this.form = fb.group({
        token: [token, Validators.required],
        senha: ['', [Validators.required, PasswordStrengthValidator]],
        novaSenha: ['', [Validators.required, PasswordStrengthValidator]]
      });
    }

  ngOnInit() {
    this.authService.validarTokenRecuperacao(this.form.value.token).subscribe(
      success => {
        this.uiService.showSnackBar('Token valido, prossiga pra recuperar seu acesso', 'OK', 4000);
      },
      error => {
        this.uiService.showSnackBar('Token expirado ou inválido', 'OK', 6000);
        this.router.navigate(['/login']);
      }
    );
  }

  alterarSenha() {
    this.loading = true;
    this.authService.recuperarSenha(this.form.value).subscribe(success => {
      this.loading = false;
      this.uiService.showSnackBar('Senha alterada com sucesso', 'Ok', 5000);
      this.router.navigate(['/login']);
    }, error => {
      this.loading = false;
      this.uiService.showSnackBar('Erro ao alterar a senha', 'Ok', 4000);
    });
  }

}
