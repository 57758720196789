import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { APRService } from 'src/app/_services/apr.service';
import { switchMap, catchError } from 'rxjs/operators';
import { APRSigner, FuncoesAPR } from 'src/app/_models/arp.model';

@Component({
  selector: 'app-autocomplete-assinadores',
  templateUrl: './autocomplete-assinadores.component.html',
  styleUrls: ['./autocomplete-assinadores.component.scss']
})
export class AutocompleteAssinadoresComponent implements AfterViewInit {
  filtroAssinador: BehaviorSubject<string>;
  assinadores: APRSigner[];
  assinador: Subject<APRSigner>;
  loaded = false;

  @Input("control") control: FormControl;
  @Input("label") label: string;
  @Input("cliente") cliente: FormControl;
  @Input("funcao") funcao: FuncoesAPR;
  
  constructor(private aprService: APRService) {
    this.filtroAssinador = new BehaviorSubject('');
    this.assinador = new Subject()
  }

  ngAfterViewInit () {
    this.cliente.valueChanges.subscribe(c => this.filtroAssinador.next(''));
    this.assinador.subscribe(cliente => this.control.setValue(cliente));
    this.filtroAssinador.pipe(
      switchMap(value => this.aprService.listaAssinadoresCliente(this.cliente.value.idCliente, value).pipe(
        catchError(() => {
          const l: APRSigner[] = []
          return of(l)
        })
      )),
    ).subscribe(assinadores => {
      this.assinadores = assinadores
      .sort((assA, assB) => assB.id - assA.id)
      .sort((assA, assB) => (assA.isGestor === assB.isGestor)? 0 : assA.isGestor? -1 : 1)
      .sort((assA, assB) => (assA.isProponente === assB.isProponente)? 0 : assA.isProponente? -1 : 1)
      .sort((assA, assB) => (assA.isResponsavel === assB.isResponsavel)? 0 : assA.isResponsavel? -1 : 1)
      .filter((assinador: APRSigner) => {
        const geral = !(assinador.isGestor || assinador.isResponsavel || assinador.isProponente);
        if (this.funcao === FuncoesAPR.GESTOR) return assinador.isGestor || geral
        if (this.funcao === FuncoesAPR.PROPONENTE) return assinador.isProponente || geral
        if (this.funcao === FuncoesAPR.RESPONSAVEL) return assinador.isResponsavel || geral
      });
      
      if(!this.loaded && Array.isArray(this.assinadores) && this.assinadores.length > 0) {
        this.loaded = true;
        if(this.filtroAssinador.value !== this.displayFundo(this.assinadores[0])){
          this.filtroAssinador.next(this.displayFundo(this.assinadores[0]))
          this.assinador.next(this.assinadores[0]);
        }
      }
    });
  }

  handleFocus = () => this.filtroAssinador.next('');

  handleFiltro = (valor: string) => {
    this.filtroAssinador.next(valor);
  }
  displayFundo = (cliente: APRSigner) => cliente ? `${cliente.nome} - ${cliente.cpfCnpj}` : '';
  selectFundo = (cliente: APRSigner) => this.assinador.next(cliente);

  ngOnDestroy() {
    this.assinador.complete()
  }


}
