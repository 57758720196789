import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from "@angular/material/dialog";
import moment from 'moment';
import { DialogConfirmacaoComponent } from "src/app/_helpers/dialog-confirmacao/dialog-confirmacao-component";
import { DialogLoadingComponent } from "src/app/_helpers/dialog-loading/dialog-loading.component";
import { APR, StatusAPR } from "src/app/_models/arp.model";
import { Cliente } from "src/app/_models/cliente.model";
import { PaginatedEndpoint } from "src/app/_models/PaginatedResult";
import { AprQuery, APRService } from "src/app/_services/apr.service";
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { PaginatedDataSource } from "src/app/_services/generic-datasource";
import { environment } from "src/environments/environment";
import { DialogDescricaoAPRComponent } from './DialogDescricaoAPR/DialogDescricaoAPR.component';

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-APR",
  templateUrl: "./APR.component.html",
  styleUrls: ["./APR.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class APRComponent implements OnInit, OnDestroy {
  atual = moment();
  date = new FormControl(moment());

  datasource: PaginatedDataSource<APR, AprQuery>;
  columns = [
    "fundo",
    "status",
    "operacao",
    "valor",
    "data",
    "conta",
    "geracao",
    "descricao",
  ];
  tipoUsuario = TipoUsuario; // Acesso da enum no html
  cliente = new FormControl(null);

  opcoesStatus = [
    StatusAPR.Pendente,
    StatusAPR.Finalizado,
    StatusAPR.Anulado,
    StatusAPR.Negado,
  ];

  _usuario$ = this.authService.usuario$.subscribe((usuario) => {
    let endpoint: PaginatedEndpoint<APR, AprQuery>;
    if ([TipoUsuario.ADMIN, TipoUsuario.USUARIO].includes(usuario.tipoUser)) {
      this.columns.splice(2, 0, "cliente");
      endpoint = (req, query) => this.aprService.listaTodosPaginado(req, query);
    } else {
      endpoint = (req, query) =>
        this.aprService.listaPropriosPaginado(req, query);
    }

    this.datasource = new PaginatedDataSource<APR, AprQuery>(
      endpoint,
      { property: "id", order: "asc" },
      { search: "" },
      environment.LIMITE_REGISTROS_LISTA
    );
  });


  constructor(
    private authService: AuthService,
    private aprService: APRService,
    private carteiraService: CarteiraService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.cliente.valueChanges.subscribe((cliente: Cliente) => {
      this.datasource.queryBy({ idCliente: cliente.idCliente })
    })
  }

  ngOnDestroy() {
    this._usuario$.unsubscribe();
  }

  geracaoPDF(id: number) {
    const url = `${environment.PATH_API}/apr/ExportAPRPDF/${id}`;
    this.authService.openWindowWithAuth(url);
  }

  alterarDescricaoAPR(apr: APR) {
    const dialogDescricao = this.dialog.open(DialogDescricaoAPRComponent, {
      data:{
        apr
      },
      minWidth: '50%',
      width: '75%',
      maxWidth: '700px'
    });
  }

  lancarApr(apr: APR) {
    const dialogConfirm = this.dialog.open(DialogConfirmacaoComponent, {
      data: {
        text: `Confirma a execução do movimento para a APR  ${apr.id}`,
      },
    });

    dialogConfirm.afterClosed().subscribe((confirmado) => {
      if (confirmado) {
        const dialogRef = this.dialog.open(DialogLoadingComponent, {
          data: {
            text: "Realizando lançamento apartir da APR...",
            target: this.carteiraService.lancarMovimentoApr(apr),
            closeOnComplete: true,
            successMessage: "Movimento gerado com sucesso",
            errorMessage: ({ error }) => `Erro: ${error.error}`,
          },
        });
        dialogRef.afterClosed().subscribe((c) => {
          if (c) {
            this.datasource.queryBy({ search: "" });
          }
        });
      }
    });
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue.startOf("month"));
    datepicker.close();
    this.datasource.queryBy({ periodo: ctrlValue.format("MMYYYY") })
  }
}
