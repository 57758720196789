<h3>
  Investimento - Fundo: {{ data.investimento.nomeFundo }} ({{
  data.investimento.cnpjFundo
  }})
</h3>
<div class="grid">
  <div style="grid-area: novo;">
    <form *appHasRole="[1, 2, 3, 4]" [formGroup]="form" style="
        margin: 0 0 1em 0;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 0.4em;
        background: #fff;
      " (ngSubmit)="!editando ? movimentar() : atualizarMovimento()">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px">
        <mat-form-field fxFlex="40">
          <input matInput [matDatepicker]="picker" [matDatepickerFilter]="restrainWeekend"
            (dateChange)="atualizaValorCota()" formControlName="dataInvestimento" readonly
            placeholder="Data do investimento" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex="20">
          <input matInput mask="Hh:m0" [dropSpecialCharacters]="false" formControlName="hora" placeholder="Hora" />
        </mat-form-field>
        <mat-form-field fxFlex="40">
          <mat-label>Operação</mat-label>
          <mat-select formControlName="operacao" required>
            <mat-option value="M">Amortização</mat-option>
            <mat-option value="A">Aplicação</mat-option>
            <mat-option value="R">Resgate</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <small *ngIf="showMessageNoQuote" style="color: red;">Valor da cota para data selecionada não existe ou não foi
        encontrada.</small>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px">
        <mat-form-field fxFlex="40">
          <input #valor matInput currencyMask formControlName="valorInvestido" (keyup)="atualizaValorCota()"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 2 }" placeholder="Valor investido"
            required aria-label="Valor do investimento" />
          <mat-error *ngIf="!valor.valid">Informe o valor do investimento</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="20">
          <input #valorCota matInput currencyMask formControlName="valorCota" readonly="true" [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ',',
              precision: 8
            }" placeholder="Valor da cota na data" required aria-label="Valor da cota" />
          <mat-error *ngIf="!valorCota.valid">Informe o valor da cota</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="40">
          <input style="text-align: right;" #cotas matInput formControlName="numCotas" placeholder="Número de cotas"
            required aria-label="Número de cotas" />
          <mat-error *ngIf="!cotas.valid">Informe o número de cotas</mat-error>
        </mat-form-field>
      </div>

      <div
        *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO, tipoUsuario.ADMIN_CLIENTE, tipoUsuario.USUARIO_CLIENTE]">
        <mat-checkbox formControlName="gerarApr">Gerar APR</mat-checkbox>
      </div>

      <button *ngIf="!editando" type="submit" matTooltip="Movimentar" mat-flat-button [disabled]="form.invalid"
        color="primary">
        <i class="fas fa-angle-double-down"></i>Movimentar
      </button>

      <button *ngIf="editando" type="submit" matTooltip="Confirmar alteração" mat-flat-button [disabled]="form.invalid"
        color="primary">
        <i class="fas fa-check"></i>Confirmar alteração
      </button>
      <button *ngIf="editando" (click)="cancelarEdicao()" style="margin-left: 0.5em;" type="button"
        matTooltip="Movimentar" mat-flat-button [disabled]="form.invalid" color="warn">
        <i class="fas fa-times"></i>Cancelar
      </button>
      <span *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]">
        <button *ngIf="editando" type="button" (click)="deletarMovimento()" style="float: right;"
          matTooltip="Apagar Movimento" mat-button color="warn">
          <i class="fas fa-trash"></i>Apagar movimento
        </button>
      </span>
    </form>
  </div>
  <div style="grid-area: grafico;">
    <ngx-charts-line-chart *ngIf="dadosSaldo.length" style="max-height: 100%;" [results]="dadosSaldo" [gradient]="false"
      [scheme]="colorScheme" [yScaleMin]="0" [xScaleMin]="0" [yAxis]="true" [showXAxisLabel]="false"
      [showYAxisLabel]="true" yAxisLabel="Saldo">
      <!-- [yAxisTickFormatting]="formatarY" [xAxisTickFormatting]="formatarX"-->
    </ngx-charts-line-chart>
  </div>

  <div style="grid-area: movimentos; max-height: 75vh; overflow-y: auto;">
    <span *appHasRole="[tipoUsuario.ADMIN]">

      <mat-form-field>
        <form [formGroup]="formCorrigir">
          <input matInput [matDatepicker]="picker" formControlName="dataCorrigir" readonly
            placeholder="Data de Atualização" />
        </form>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <button type="button" (click)="corrigirCota()" matTooltip="Atualizar Saldo" mat-flat-button color="primary">
        Atualizar Saldo
      </button>
    </span>

    <h3>Movimentos</h3>
    <mat-list>
      <span *ngFor="let movimento of movimentos">
        <mat-list-item
          *ngIf="movimento.operacao == 'R' || movimento.operacao == 'A' || movimento.operacao == 'M' || movimento.operacao == 'E' ">
          <i style="font-weight: bold;" matLine>{{
            movimento.dataMovimento | date: "dd/MM/yy HH:mm"
            }}</i>

          <i matLine> {{ movimento.operacao | movimentoCarteira }} </i>
          <i matLine>
            {{ movimento.numCotas | number: "1.0-8":"pt-br" }} cotas
          </i>
          <strong matLine>{{ movimento.valor | currency: "BRL" }} </strong>
          <strong>
            {{ movimento.saldo.saldoFinal | currency: "BRL" }}
          </strong>
          <button mat-icon-button (click)="loadMovimento(movimento)" color="primary" matTooltip="Alterar o movimento">
            <i class="fas fa-edit"></i>
          </button>
          <br />

          <div *ngIf="movimento.operacao != 'M'">
            <button *ngIf="!movimento.aprId" mat-flat-button color="warn" (click)="novaApr(movimento)"
              matTooltip="Gerar APR">
              <i class="fas fa-plus-circle"></i>APR
            </button>
            <button *ngIf="movimento.aprId" mat-flat-button color="primary" (click)="abrirApr(movimento.aprId)"
              matTooltip="Abrir APR">
              <i class="fas fa-file-pdf"></i>APR
            </button>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>

        <mat-list-item *ngIf="movimento.operacao == 'D'" style="
            background: #fff;
            border-radius: 5px;
            border: 1px solid #ccc;
            margin: 3px;
            font-size: 14px;
          ">
          <em matLine>A: {{ movimento.dataMovimento | date: "dd/MM/yy" }}</em>

          <em style="font-size: 14px;" matLine>Valor cota:
            {{
            movimento.saldo.valorCota | currency: "BRL":"symbol":"1.2-9"
            }}</em>
          <em style="font-size: 14px;" matLine>Número de cotas:
            {{
            movimento.saldo.saldoCotas
            }}</em>
          <strong style="font-size: 16px;">
            {{ movimento.saldo.saldoFinal | currency: "BRL":"symbol":"1.2-6" }}
          </strong>
        </mat-list-item>
        <mat-list-item *ngIf="movimento.operacao == 'C'" style="
            background: #fff;
            border-radius: 5px;
            border: 1px solid #ccc;
            margin: 3px;
            font-size: 14px;
          ">
          <em matLine style="color: red">Ajuste saldo: {{ movimento.dataMovimento | date: "dd/MM/yy" }}</em>
          <em style="font-size: 14px;" matLine>Valor cota:
            {{
            movimento.saldo.valorCota | currency: "BRL":"symbol":"1.2-9"
            }}</em>
          <strong style="font-size: 16px;">
            {{ movimento.saldo.saldoFinal | currency: "BRL":"symbol":"1.2-6" }}
          </strong>
        </mat-list-item>

        <mat-list-item *ngIf="movimento.operacao == 'PA' || movimento.operacao == 'PR'" style="
            background: #fff;
            border-radius: 5px;
            border: 1px solid #ccc;
            margin: 3px;
            font-size: 14px;
          ">
          <em matLine style="font-weight: bold;">
            <span *ngIf="movimento.operacao == 'PA'">Aplicação</span>
            <span *ngIf="movimento.operacao == 'PR'">Resgate</span>
          </em>
          <em matLine style="color: orange">Operação pendente de cotas: {{ movimento.dataMovimento | date: "dd/MM/yy"
            }}</em>
          <em style="font-size: 14px;" matLine>Valor da operação:
            {{
            movimento.valor | currency: "BRL":"symbol":"1.2-9"
            }}</em>
        </mat-list-item>



      </span>
    </mat-list>
  </div>
</div>
<mat-dialog-actions class="actions">
  <button (click)="lancamentoAjusteSaldo()" mat-stroked-button matTooltip="Lançamento de ajuste de saldo">
    <i class="fas fa-dollar-sign"></i>Lançamento de ajuste de saldo
  </button>

</mat-dialog-actions>