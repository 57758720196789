import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { reducer as OtimizadorReducer } from './otimizador.reducer';
import { reducer as UsuarioReducer } from './usuario.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';


export interface State {

}

export const reducers: ActionReducerMap<State> = {
  otimizador: OtimizadorReducer,
  usuario: UsuarioReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['otimizador', 'usuario'], rehydrate: true, removeOnUndefined: true})(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

