import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import {
  BenchmarkService,
  BenchmarkListDataSource,
  BenchmarkQuery,
} from "src/app/_services/benchmark.service";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { tap } from "rxjs/operators";
import { UIService } from "src/app/_helpers/ui.service";
import { DialogDeleteComponent } from "src/app/_helpers/dialog-delete/dialog-delete.component";
import { FormControl } from "@angular/forms";
import { PaginatedDataSource } from "src/app/_services/generic-datasource";
import { environment } from "src/environments/environment";
import { Benchmark, BenchmarkList } from "src/app/_models/benchmark.models";

@Component({
  selector: "app-benchmark",
  templateUrl: "./benchmark.component.html",
  styleUrls: ["./benchmark.component.scss"],
})
export class BenchmarkComponent {
  columnsToDisplay = [
    "nome",
    "data",
    "percMes",
    "perc12",
    "perc24",
    "percAno",
    "apagar",
  ];

  datasource = new PaginatedDataSource<BenchmarkList, BenchmarkQuery>(
    (request, query) =>
      this.benchmarkService.listaBenchmarkPaginado(request, query),
    { property: "idBench", order: "asc" },
    { search: "" },
    environment.LIMITE_REGISTROS_LISTA
  );

  constructor(
    private benchmarkService: BenchmarkService,
    private uiService: UIService,
    private dialog: MatDialog
  ) {}

  removerBenchmark(benchmark: Benchmark) {
    const dialog = this.dialog.open(DialogDeleteComponent, {
      data: { name: benchmark.nome },
    });
    dialog.afterClosed().subscribe((response) => {
      if (response) {
        this.uiService
          .abrirDialogLoading(
            this.benchmarkService.removerBenchmark(benchmark.idBench),
            "Removendo benchmark...",
            "Benchmark removido com sucesso",
            "Erro ao excluir benchmark, podem haver referências a este benchmark."
          )
          .subscribe((v) => {
            this.datasource.queryBy({ search: "" });
          });
      }
    });
  }
}
