<h1 class="title">Cadastro de Usuário</h1>
<mat-spinner [diameter]="30" style="margin-top: 1em" [strokeWidth]="5" *ngIf="isLoading | async"></mat-spinner>
<form [formGroup]="form" *ngIf="!(isLoading | async)" (ngSubmit)="salvar()">
  <h3>Dados do usuário</h3>
  <mat-form-field>
    <input #nome matInput formControlName="nome" placeholder="Nome do usuário" required aria-label="Nome">
    <mat-error *ngIf="!nome.valid">Informe o nome do usuário</mat-error>
  </mat-form-field>
  <mat-form-field>
    <input #email matInput formControlName="eMail" type="email" placeholder="Email" required aria-label="email">
    <mat-error *ngIf="!email.valid">Informe o Email</mat-error>
  </mat-form-field>
  <mat-form-field>
    <input #senha matInput formControlName="pwd" type="password" autocomplete="new-password" placeholder="Senha" required aria-label="senha">
    <mat-error *ngIf="form.controls['pwd'].errors?.passwordStrength">{{form.controls['pwd'].errors?.passwordStrength}}</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-select placeholder="Tipo do usuário" formControlName="tipoUser" required>
      <mat-option *ngFor="let tipo of (tiposUsuario)" [value]="tipo.value">
        {{tipo.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="buttons">
    <button mat-raised-button color="accent" type="submit" [disabled]="!form.valid">
      <mat-icon>save</mat-icon>Cadastrar usuário
    </button>
    <button mat-raised-button color="warn" routerLink="/usuarios">
      <mat-icon>close</mat-icon> Cancelar
    </button>
  </div>
</form>
