import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { InformacoesOtimizacao } from '../_services/otimizador.service';

// Section 2
export const OTIMIZAR = '[OTIMIZADOR] Otimizar';
export const LIMPAR_OTIMIZADOR = '[OTIMIZADOR] Limpar';

// Section 3
export class Otimizar implements Action {
    readonly type = OTIMIZAR;

    constructor(public payload: InformacoesOtimizacao) { }
}

export class LimparOtimizador implements Action {
    readonly type = LIMPAR_OTIMIZADOR;

    constructor() { }
}

// Section 4
export type Actions = Otimizar | LimparOtimizador;
