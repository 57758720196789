<h1>Usuários</h1>

<div class="acoes">
  <mat-form-field>
    <input matInput placeholder="Filtro">
  </mat-form-field>   
  
  <button routerLink="" mat-raised-button color="accent">
    <mat-icon>search</mat-icon> Buscar
  </button>
  
  <button routerLink="novo" mat-raised-button color="accent">
    <mat-icon>person_add</mat-icon> Novo Usuário
  </button>
</div>

<table mat-table [dataSource]="datasource">
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef> Nome usuário </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Nome:</span>
      <a [routerLink]="['editar', element.idUser]">{{element.nome}}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Email:</span>
      <a [routerLink]="['editar', element.idUser]">{{element.eMail}}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="tipo">
    <th mat-header-cell *matHeaderCellDef> Tipo do usuário </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Tipo do usuário:</span>
      <a [routerLink]="['editar', element.idUser]">{{element.tipoUser | tipoUsuario}}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="acoes">
    <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Ações </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Ações:</span>
    </td>
  </ng-container>
</table>
