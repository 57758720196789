import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RentabilidadeFundo, RentabilidadeFundoQuery } from 'src/app/_models/fundo-investimento.models';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { delay } from 'rxjs/operators';
import { MatDatepicker } from '@angular/material/datepicker';
import { forkJoin } from 'rxjs';


export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-relatorio-rentabilidade-fundo',
  templateUrl: './relatorio-rentabilidade-fundo.component.html',
  styleUrls: ['./relatorio-rentabilidade-fundo.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class RelatorioRentabilidadeFundoComponent implements OnInit, AfterViewInit {
  atual = moment();
  date = new FormControl(moment());
  zoom = new FormControl(1);

  query: RentabilidadeFundoQuery;

  columnsToDisplay = ['dataInicial','valorInicial', 'dataFinal', 'valorFinal', 'percentualRetorno']
  columnsToDisplayPeriodo = ['periodo', 'dataInicial','valorInicial', 'dataFinal', 'valorFinal', 'percentualRetorno']
  cnpj: string;
  anoAtual: RentabilidadeFundo[];
  registrosAno: RentabilidadeFundo[];
  
  rentabilidadePeriodo: RentabilidadeFundo[];

  constructor(
    private fiService: FundoInvestimentoService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    window.onafterprint = (e) => this.authService.willPrint$.next(false);
  }

  ngOnInit() {
    this.cnpj = this.route.snapshot.paramMap.get('cnpj');
    this.authService.willPrint$.pipe(delay(500)).subscribe(willPrint => {
      if (willPrint) {
        window.print();
      }
    });
  }

  ngAfterViewInit(): void {
    this.chosenYearHandler(moment(), null);
  }

  chosenYearHandler(normalizedYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {

    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf('year'));
    if(datepicker){
      datepicker.close();
    }
    this.query = {
      dataInicial: ctrlValue.clone().startOf('year').add(-1, 'day').toDate(),
      dataFinal: ctrlValue.clone().endOf('year').toDate(),
    }
    this.retrieveRentabilidades(this.query);
  }

  retrieveRentabilidades(query: RentabilidadeFundoQuery) {
    this.anoAtual = [];
    this.registrosAno = [];
    const inicioMes = moment().startOf('month');
    this.fiService.getRentabilidadeFundo(this.cnpj.replace(/[^\d\s]/gi, ''), query).subscribe(rentabilidade => {
      this.anoAtual = rentabilidade
    });
    this.fiService.getRentabilidadeFundo(this.cnpj.replace(/[^\d\s]/gi, ''), { ...query, mensal: 'S' }).subscribe(rentabilidade => {
      this.registrosAno = rentabilidade
    })
    
    this.rentabilidadePeriodo = [];

    forkJoin({
      meses3: this.fiService.getRentabilidadeFundo(this.cnpj.replace(/[^\d\s]/gi, ''), { dataFinal: inicioMes.toDate(), dataInicial: inicioMes.clone().add(-3, "months").toDate() }),
      meses6: this.fiService.getRentabilidadeFundo(this.cnpj.replace(/[^\d\s]/gi, ''), { dataFinal: inicioMes.toDate(), dataInicial: inicioMes.clone().add(-6, "months").toDate() }),
      meses12: this.fiService.getRentabilidadeFundo(this.cnpj.replace(/[^\d\s]/gi, ''), { dataFinal: inicioMes.toDate(), dataInicial: inicioMes.clone().add(-12, "months").toDate() }),
      meses24: this.fiService.getRentabilidadeFundo(this.cnpj.replace(/[^\d\s]/gi, ''), { dataFinal: inicioMes.toDate(), dataInicial: inicioMes.clone().add(-24, "months").toDate() }),
    }).subscribe(retornos => {
      const { meses3, meses6, meses12, meses24 } = retornos;
      const rentabilidadePeriodo = []
      if(Array.isArray(meses3)) {
        rentabilidadePeriodo.push({ tipo: '3M', ...meses3[0]} as any)      
      }
      if(Array.isArray(meses6)) {
        rentabilidadePeriodo.push({ tipo: '6M', ...meses6[0]} as any)      
      }
      if(Array.isArray(meses12)) {
        rentabilidadePeriodo.push({ tipo: '12M', ...meses12[0]} as any)      
      }
      if(Array.isArray(meses24)) {
        rentabilidadePeriodo.push({ tipo: '24M', ...meses24[0]} as any)      
      }
      this.rentabilidadePeriodo = rentabilidadePeriodo;
    })

  //   this.fiService.getRentabilidadeFundo(this.cnpj.replace(/[^\d\s]/gi, ''), { dataFinal: inicioMes.toDate(), dataInicial: inicioMes.clone().add(-3, "months").toDate() }).subscribe(rentabilidade => {
  //     if(Array.isArray(rentabilidade)) {
  //       this.rentabilidadePeriodo.push({ tipo: '3M', ...rentabilidade[0]} as any)
  //     }
  //  })

  //   this.fiService.getRentabilidadeFundo(this.cnpj.replace(/[^\d\s]/gi, ''), { dataFinal: inicioMes.toDate(), dataInicial: inicioMes.clone().add(-6, "months").toDate() }).subscribe(rentabilidade => {
  //     if(Array.isArray(rentabilidade)) {
  //       this.rentabilidadePeriodo.push({ tipo: '6M', ...rentabilidade[0]} as any)
  //     }
  //   })
  //   this.fiService.getRentabilidadeFundo(this.cnpj.replace(/[^\d\s]/gi, ''), { dataFinal: inicioMes.toDate(), dataInicial: inicioMes.clone().add(-12, "months").toDate() }).subscribe(rentabilidade => {
  //     if(Array.isArray(rentabilidade)) {
  //       this.rentabilidadePeriodo.push({ tipo: '12M', ...rentabilidade[0]} as any)
  //     }
  //   })
  //   this.fiService.getRentabilidadeFundo(this.cnpj.replace(/[^\d\s]/gi, ''), { dataFinal: inicioMes.toDate(), dataInicial: inicioMes.clone().add(-24, "months").toDate() }).subscribe(rentabilidade => {
  //     if(Array.isArray(rentabilidade)) {
  //       this.rentabilidadePeriodo.push({ tipo: '24M', ...rentabilidade[0]} as any)
  //     }
  //   })
  }

  print() {
    this.authService.willPrint$.next(true);
  }


}
