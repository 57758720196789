import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Page, PageRequest } from '../_models/PaginatedResult';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Conjunto } from '../_models/conjuntos.model';
import { Cliente } from '../_models/cliente.model';
import { FIList } from '../_models/fundo-investimento.models';

export interface ConjuntoQuery{
    search: string
  }

@Injectable()
export class ConjuntoService {
    constructor(private httpClient: HttpClient) { }
    private url = `${environment.PATH_API}/conjunto`;

    listarConjuntos(nome?: string):Observable<Conjunto[]> {
        let params = new HttpParams();
        if (nome) {
            params = params.append('nome', nome);
        }
        params = params.append('page', '0');
        params = params.append('limit', '50');
        return this.httpClient.get<Page<Conjunto>>(this.url, {params: params}).pipe(
            map(c => c.data)
        );
    }

    listarConjuntosPaginado(request: PageRequest<Conjunto>, query: ConjuntoQuery):Observable<Page<Conjunto>>{
        let params = new HttpParams();
        if (query.search) {
            params = params.append('nome', query.search);
        }
        params = params.append('page', request.page.toString());
        params = params.append('limit', request.size.toString());
        return this.httpClient.get<Page<Conjunto>>(this.url, {params: params});
    }

    salvarConjunto(obj: Conjunto) {
        return this.httpClient.post(this.url, obj);
    }

    atualizarConjunto(idConjunto: number, obj: Conjunto) {
        const url = `${this.url}/${idConjunto}`;
        return this.httpClient.put(url, obj);
    }

    getConjunto(idConjunto: number) {
        const url = `${this.url}/${idConjunto}`;
        return this.httpClient.get<Conjunto>(url);
    }

    getFundosConjunto(idConjunto: number) {
        const url = `${this.url}/${idConjunto}/fundos`;
        return this.httpClient.get<FIList[]>(url);
    }

    getClientesConjunto(idConjunto: number) {
        const url = `${this.url}/${idConjunto}/clientes`;
        return this.httpClient.get<Cliente[]>(url);
    }

    getConjuntosDoCliente() {
        const url = `${this.url}/usuario/`;
        return this.httpClient.get<Conjunto[]>(url);
    }
}

