<div>
  <h4>Selecione a conta</h4>
  <div>
    <div
      class="box"
      *ngFor="let item of data.fundos"
      (click)="selectConta(item)"
    >
      Banco: {{ item.banco || "Não informado" }}<br />
      Agência: {{ item.agencia || "Não informado" }}<br />
      Conta: {{ item.conta || "Não informado" }}<br />
    </div>
  </div>
  <button mat-button mat-dialog-close color="primary" style="width: 100%;">
    Fechar
  </button>
</div>
