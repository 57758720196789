<form class="box" fxLayout="column" fxFlex="70" (ngSubmit)="addItem(acao.value)">
  <h3 style="margin: 0">Ações permitidas para o cliente</h3>
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
    <mat-form-field style="width: 100%">
      <mat-label>Ações</mat-label>
      <mat-select #acao>
        <mat-option>Selecione</mat-option>
        <mat-option *ngFor="let option of acoes" [value]="option">{{option}}</mat-option>
      </mat-select>
      <mat-error *ngIf="!acao.value">Informe uma ação para o cliente</mat-error>
    </mat-form-field>
    <div class="buttons">
      <button mat-raised-button color="accent" type="submit" [disabled]="!acao.value"><mat-icon>add</mat-icon>Adicionar ação</button>
    </div>
  </div>
  <mat-list>
    <mat-list-item *ngFor="let acao of acoesCliente; let i = index" class="mat-elevation-z1">
      <h3 matLine> <strong>{{acao.codigoAcao}}</strong> </h3>
      <button type="button" mat-icon-button color="primary" (click)="removeItem(acao.codigoAcao)" matTooltip="Remover ação"><i
        class="fas fa-trash"></i></button>
      </mat-list-item>
    </mat-list>
</form>
