<h1 class="title">Índices gráficos</h1>
<div style="display: flex; flex-flow: row">
  <form class="hidePrint" style="flex: 1">
    <mat-form-field>
      <input readonly required="false" matInput [matDatepicker]="dp" placeholder="Ano" [value]="novaData?.toDate()"
        [formControl]="date.value" [min]="minDate" [max]="maxDate" />
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event, dp)"
        panelClass="example-month-picker">
      </mat-datepicker>
    </mat-form-field>


    <button mat-flat-button color="primary" style="
        flex-grow: 0;
        align-self: center;
        justify-self: center;
        margin-right: 10px;
        margin-left: 15px;
      " (click)="filtro()">
      Filtrar
    </button>
    <!-- <strong>
      Data do último saldo: {{ ultimaDataSaldo | date : "dd/MM/yyyy" }}
    </strong> -->
  </form>
</div>
<div>
  <app-grafico-meta *ngFor="let meta of metas" [meta]="meta" [data]="data"></app-grafico-meta>
</div>