import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta } from 'src/app/_models/benchmark.models';
import { UIService } from 'src/app/_helpers/ui.service';
import { MetaService } from 'src/app/_services/meta.service';

interface DialogData {
  meta: Meta;
}

@Component({
  selector: 'app-dialog-grafico-meta',
  templateUrl: './dialog-grafico-meta.component.html',
  styleUrls: ['./dialog-grafico-meta.component.scss']
})
export class DialogGraficoMetaComponent implements OnInit {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogGraficoMetaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private uiService: UIService,
    private metaService: MetaService
  ) {
    this.form = _fb.group({
      grafico: [this.data.meta.grafico ? true : false, Validators.required],
      camposGrafico: [this.data.meta.camposGrafico?.split(',') ?? [], Validators.required]
    });
  }

  ngOnInit() {
  }

  salvarDadosMeta() {
    const obj = {
      grafico: this.form.value.grafico,
      camposGrafico: this.form.value.camposGrafico.join(',')
    }

    const dlg = this.uiService.abrirDialogLoading(
      this.metaService.atualizarMetaGraficos(this.data.meta.idMeta, obj),
      "Salvando informações de graficos",
      "Dados salvos com sucesso",
      "Erro ao salvar",
      true,
      );

    dlg.subscribe(c => {
      if(c) {
        this.dialogRef.close();
      }
    })

  }

}
