import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Documento } from 'src/app/_models/documentos.model';
import { AuthService, TipoUsuario } from 'src/app/_services/auth.service';
import { DocumentoQuery, DocumentoService } from 'src/app/_services/documento.service';
import { PaginatedDataSource } from 'src/app/_services/generic-datasource';
import { environment } from 'src/environments/environment';
import { CriarDocumentoComponent } from './criar-documento/criar-documento.component';

@Component({
  selector: 'app-Documentos',
  templateUrl: './Documentos.component.html',
  styleUrls: ['./Documentos.component.scss']
})
export class DocumentosComponent implements OnInit {

  tipoUsuario = TipoUsuario;

  columns = ['titulo', 'tipo', 'arquivo'];
  datasource = new PaginatedDataSource<Documento, DocumentoQuery>(
    (request, query) => this.documentoService.listarDocumentosPaginado(request, query),
    {property: 'data', order: 'desc'},
    {search: ''},
    environment.LIMITE_REGISTROS_LISTA
  )


  constructor(
    private dialog: MatDialog,
    private documentoService: DocumentoService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService._usuario.subscribe(user => {
      if([TipoUsuario.ADMIN, TipoUsuario.USUARIO].includes(user.tipoUser)) {
        if(!this.columns.includes("acoes")) {
          this.columns.push("acoes");
        }
      }
    })
  }

  abrirDialogNovo() {
    const dialog = this.dialog.open(CriarDocumentoComponent, {
      width: '500px'
    });

    dialog.afterClosed().subscribe(result => {
      this.datasource.fetch(0);
    })
  }

  abrirDocumento(idDocumento: number) {
    this.documentoService.buscarLinkDocumento(idDocumento).subscribe((value: any) => {
      const { link } = value;
      window.open(link, '_blank');
    })
  }

  apagarDocumento(idDocumento: number) {
    const del = confirm("O item selecionado será deletado. Confirme a operação")
    if(del) {
      this.documentoService.deleteDocumento(idDocumento).subscribe((value: any) => {
        this.datasource.fetch(0);
      })
    }
  }



}
