<h1>Nova APR</h1>
<form
  [formGroup]="form"
  fxLayout="column"
  (ngSubmit)="salvar(form.value)"
  fxLayoutGap="20"
>
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
    <mat-form-field fxFlex="20">
      <input
        matInput
        [readOnly]="fromMovement"
        [matDatepicker]="picker"
        readonly
        formControlName="dataOperacao"
        (dateChange)="atualizaValorCota()"
        placeholder="Selecione a data da APR"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field fxFlex="30">
      <mat-label>Operação</mat-label>
      <mat-select [disabled]="fromMovement" formControlName="operacao" required>
        <mat-option value="A">Aplicação</mat-option>
        <mat-option value="R">Resgate</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="20">
      <input
        #valor
        [disabled]="fromMovement"
        [readOnly]="fromMovement"
        matInput
        currencyMask
        formControlName="valor"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
        placeholder="Valor"
        required
        aria-label="Valor"
        (keyup)="atualizarNumeroCotas()"
      />
      <mat-error *ngIf="!valor.valid">Informe o valor da APR</mat-error>
    </mat-form-field>
  </div>

  <div class="box" style="width: 70%; margin-top: 10px;">
    <div fxLayout="column">
      <div fxFlex="70" *ngIf="!fromMovement">
        <app-autocomplete-fundo-investimentos [control]="fundo">
        </app-autocomplete-fundo-investimentos>
      </div>

      <div *ngIf="fundo.value !== null" fxLayout="row" fxLayoutGap="5px ">
        <mat-form-field fxFlex="70">
          <input
            matInput
            type="text"
            disabled
            placeholder="Nome do fundo"
            [value]="fundo.value.nome"
          />
        </mat-form-field>
        <mat-form-field fxFlex="30">
          <input
            matInput
            type="text"
            disabled
            placeholder="CNPJ do fundo"
            [value]="fundo.value.cnpj"
          />
        </mat-form-field>
      </div>
      <div *ngIf="existeCota" fxLayout="row" fxLayoutGap="5px ">
        <mat-form-field fxFlex="50">
          <input
            #valorCota
            matInput
            disabled
            readonly
            currencyMask
            formControlName="valorCota"
            [options]="{
              prefix: 'R$ ',
              thousands: '.',
              decimal: ',',
              precision: 8
            }"
            placeholder="Valor da cota na data"
            aria-label="Valor na data"
          />
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <input
            #numCotas
            matInput
            readonly
            style="text-align: right;"
            [value]="numeroCotas"
            placeholder="Número de cotas"
            aria-label="Número de cotas"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div
    *appHasRole="[
      tipoUsuario.ADMIN,
      tipoUsuario.USUARIO,
      tipoUsuario.ADMIN_CLIENTE
    ]"
    class="box"
    style="width: 70%; margin-top: 10px;"
  >
    <div fxLayout="column">
      <div fxFlex="70" *ngIf="!fromMovement && !isCliente">
        <app-autocomplete-clientes [control]="cliente" label="Cliente">
        </app-autocomplete-clientes>
      </div>

      <div *ngIf="cliente.value !== null" fxLayout="row" fxLayoutGap="5px ">
        <mat-form-field fxFlex="70">
          <input
            matInput
            type="text"
            disabled
            placeholder="Nome do cliente"
            [value]="cliente.value.nome"
          />
        </mat-form-field>
        <mat-form-field fxFlex="30">
          <input
            matInput
            type="text"
            disabled
            placeholder="CNPJ do cliente"
            [value]="cliente.value.cnpj"
          />
        </mat-form-field>
      </div>
      <span *ngIf="cliente.value !== null" fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="33">
          <app-autocomplete-assinadores
            [cliente]="cliente"
            [funcao]="funcoesAPR.GESTOR"
            [control]="gestor"
            label="Gestor"
          >
          </app-autocomplete-assinadores>
        </div>
        <div fxFlex="33">
          <app-autocomplete-assinadores
            [cliente]="cliente"
            [funcao]="funcoesAPR.PROPONENTE"
            [control]="proponente"
            label="Proponente"
          >
          </app-autocomplete-assinadores>
        </div>
        <div fxFlex="33">
          <app-autocomplete-assinadores
            [cliente]="cliente"
            [funcao]="funcoesAPR.RESPONSAVEL"
            [control]="responsavel"
            label="Responsável"
          >
          </app-autocomplete-assinadores>
        </div>
      </span>
    </div>
  </div>

  <div class="box" style="width: 70%; margin-top: 10px;">
    <div fxLayout="column">
      <h3>Informações de conta</h3>
      <div fxLayout="row" fxLayoutGap="5px ">
        <mat-form-field fxFlex="33">
          <input
            matInput
            type="text"
            placeholder="Banco"
            formControlName="banco"
          />
        </mat-form-field>

        <mat-form-field fxFlex="33">
          <input
            matInput
            type="text"
            placeholder="Agência"
            formControlName="agencia"
          />
        </mat-form-field>

        <mat-form-field fxFlex="33">
          <input
            matInput
            type="text"
            disabled
            placeholder="Conta"
            formControlName="conta"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <mat-form-field
    *appHasRole="[tipoUsuario.ADMIN, tipoUsuario.USUARIO]"
    fxFlex="70"
    style="width: 70%;"
  >
    <textarea
      matInput
      placeholder="Descrição"
      formControlName="descricao"
      rows="5"
    ></textarea>
  </mat-form-field>

  <mat-form-field
    *appHasRole="[tipoUsuario.ADMIN_CLIENTE, tipoUsuario.USUARIO_CLIENTE]"
    fxFlex="70"
    style="width: 70%;"
  >
    <textarea
      matInput
      placeholder="Motivo"
      formControlName="motivo"
      rows="5"
    ></textarea>
  </mat-form-field>

  <div class="buttons">
    <button
      mat-raised-button
      color="accent"
      type="submit"
      [disabled]="!form.valid"
    >
      <mat-icon>save</mat-icon>Gerar APR
    </button>
    <button mat-raised-button color="warn" type="button" routerLink="/apr">
      <mat-icon>close</mat-icon> Cancelar
    </button>
  </div>
</form>
