<h1 class="title">Alteração em lote de fundos de investimento</h1>

<form  [formGroup]="form">
  <h3 class="title">Dados do fundo</h3>
  <div class="col-2">
    <mat-form-field>
      <mat-select placeholder="Tipo I (Antes de Março/2018)" required formControlName="tipo1">
        <mat-option>Selecione</mat-option>
        <mat-optgroup [label]="grupo.key" *ngFor="let grupo of (legislacaoPre2018 | groupBy:'grupo')">
            <mat-option [value]="item.id" *ngFor="let item of grupo.value">{{item.descricao}}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Tipo II (Após Março/2018)" required formControlName="tipo2">
        <mat-option>Selecione</mat-option>
        <mat-optgroup [label]="grupo.key" *ngFor="let grupo of (legislacao | groupBy:'grupo')">
            <mat-option [value]="item.id" *ngFor="let item of grupo.value">{{item.descricao}}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </div>
  
  <mat-accordion multi="true">
    <mat-expansion-panel *ngFor="let grupo of grupos" [formGroupName]="grupo.field">
      <mat-expansion-panel-header>
        <strong>{{grupo.label}}</strong> {{nomeF.value ? ' - Nome: '+nomeF.value : ''}} {{cnpjF.value?' - CNPJ: '+cnpjF.value : ''}}
      </mat-expansion-panel-header>
      <div class="col-2">
        <mat-form-field>
          <input #nomeF matInput formControlName="nome" placeholder="Nome" aria-label="Nome">
        </mat-form-field>
        <mat-form-field>
          <input #cnpjF matInput formControlName="cnpj" mask="00.000.000/0000-00" [dropSpecialCharacters]="false" placeholder="CNPJ" aria-label="cnpj">
          <mat-error *ngIf="!form.get(grupo.field).get('cnpj').valid">CNPJ Inválido</mat-error>
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field  *ngIf="form.get(grupo.field).enabled">
          <input matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" formControlName="patrimonio" placeholder="Patrimonio" aria-label="Patrimonio">
        </mat-form-field>
        <mat-form-field  *ngIf="form.get(grupo.field).enabled">
          <input matInput [matDatepicker]="picker" readonly formControlName="dataInicio" placeholder="Data de início">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  
  
  <h3 class="title">Benchmark</h3>
  <div class="col-2">
    <mat-form-field>
      <mat-select class="benchmark" required placeholder="Benchmark" formControlName="benchmark">
        <mat-option *ngFor="let benchmark of benchmarks" [value]="benchmark.idBench">{{benchmark.nome}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input #taxa matInput formControlName="taxaBenchmark" placeholder="Taxa Benchmark" [dropSpecialCharacters]="false" aria-label="Taxa Benchmark" mask="percent" sufix="%">
      <mat-error *ngIf="!taxa.valid">Informe a taxa do Benchmark</mat-error>
    </mat-form-field>
  </div>
  <div class="col-2">
    <mat-form-field>
      <input #taxaAdm matInput formControlName="taxaAdm" placeholder="Taxa administrativa" [dropSpecialCharacters]="false" aria-label="Taxa administrativa" mask="percent" sufix="%">
      <mat-error *ngIf="!taxaAdm.valid">Informe a Taxa administrativa</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-select required placeholder="Grau de Risco" formControlName="grauRisco">
        <mat-option value="B"> Baixo </mat-option>
        <mat-option value="M"> Médio </mat-option>
        <mat-option value="A"> Alto </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <h3>Selecionar fundos para alteração</h3>
  <div class="acoes col-2">
    <mat-form-field>
        <input matInput #filtro placeholder="Filtro" (keyup.enter)="handleFilter(filtro.value)">
    </mat-form-field>

    <button (click)="handleFilter(filtro.value)" mat-raised-button color="accent">
        <mat-icon>search</mat-icon> Buscar
    </button>
  </div>
  <div class="buttons">
    <button mat-raised-button color="accent" type="submit" [disabled]="!form.valid"><mat-icon>save</mat-icon>Salvar fundo de investimento</button>
    <button mat-raised-button color="warn" routerLink="/fundo-investimento"><mat-icon>close</mat-icon> Cancelar</button>
  </div>
</form>