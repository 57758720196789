<h1 class="title">Vincular clientes a análise</h1>
<button mat-icon-button matTooltip="Voltar" (click)="voltar()">
  <i class="fas fa-arrow-left"></i>Voltar
</button>
<br />
<br />

<h3 *ngIf="!loading">Adicione clientes para esta análise</h3>
<div
  style="
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
  "
>
  <mat-form-field class="largura-select">
    <mat-label>Selecione um cliente</mat-label>
    <mat-select
      [(value)]="valorSelecionado"
      (selectionChange)="selecionarOpcao($event)"
      class="largura-select"
    >
      <input
        matInput
        placeholder="Buscar cliente"
        [(ngModel)]="filtroOpcoes"
        class="largura-select input"
      />
      <mat-option *ngFor="let opcao of filtrarOpcoes()" [value]="opcao.valor">
        {{ opcao.nome }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button color="primary" (click)="vincularCliente()">
    <i class="fas fa-plus-circle"></i> Vincular Cliente
  </button>
</div>
<br />
<br />
<div *ngIf="loading" class="spinner">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<br />
<h3 *ngIf="!loading">Listagem de clientes já vinculados</h3>
<table mat-table [dataSource]="datasource" *ngIf="!loading">
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>
  <ng-container matColumnDef="nomeCliente">
    <th mat-header-cell *matHeaderCellDef>Cliente</th>
    <td mat-cell *matCellDef="let element">
      {{ element.nomeCliente }}
    </td>
  </ng-container>
  <ng-container matColumnDef="acoes">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Ações</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Ações:</span>

      <button
        mat-icon-button
        matTooltip="Remover Cliente"
        (click)="excluirCliente(element.idComentarioBonitao, element.idCliente)"
        color="warn"
      >
        <i class="fas fa-user"></i>
      </button>
    </td>
  </ng-container>
</table>
