import { Component, OnInit, Inject } from '@angular/core';
import { APR } from 'src/app/_models/arp.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CarteiraService } from 'src/app/_services/carteira.service';
import { UIService } from 'src/app/_helpers/ui.service';
import { APRService } from 'src/app/_services/apr.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogLoadingComponent } from 'src/app/_helpers/dialog-loading/dialog-loading.component';

export interface DialogData {
  apr: APR;
}

@Component({
  selector: 'app-DialogDescricaoAPR',
  templateUrl: './DialogDescricaoAPR.component.html',
  styleUrls: ['./DialogDescricaoAPR.component.css']
})
export class DialogDescricaoAPRComponent implements OnInit {

  constructor(
    private aprService: APRService,
    private carteiraService: CarteiraService,
    public dialogRef: MatDialogRef<DialogDescricaoAPRComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private uiService: UIService) { }

  form = new FormGroup({
    descricao: new FormControl('', [Validators.required])
  })

  ngOnInit() {
    this.form.patchValue({ descricao: this.data.apr.descricao })
  }

  closeDialog(result: any){
    if(result) {
      this.dialogRef.close(result)
    }
  }

  salvar(obj: APR) {
    const dialogRef = this.dialog.open(DialogLoadingComponent, {
      data: {
        text: "Alterando APR",
        target: this.aprService.editarApr(this.data.apr.id, obj),
        closeOnComplete: true,
        successMessage: "APR salva com sucesso!",
        errorMessage: ({ error }) => `Erro: ${error.error}`,
      },
    });
    dialogRef.afterClosed().subscribe((c) => {
      if (c) {
        this.dialogRef.close()
      }
    });
  }

}
