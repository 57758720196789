import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FundoInvestimentoService } from "src/app/_services/fundo-investimento.service";
import moment from "moment";

@Component({
  selector: "app-editar-patrimonio",
  templateUrl: "./editar-patrimonio.component.html",
  styleUrls: ["./editar-patrimonio.component.scss"],
})
export class EditarPatrimonioComponent implements OnInit {
  form: FormGroup;
  patrimonioLiquido: number = this.data.data.valorPatrimonioLiquido;
  // Recebe os dados do componente que abriu o modal
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditarPatrimonioComponent>,
    private _fb: FormBuilder,
    private fundoInvestimentoService: FundoInvestimentoService
  ) {
    this.form = _fb.group({
      patrimonioLiquido: _fb.control("", Validators.required),
    });
  }

  ngOnInit() {
    console.log("dados recebidos: ", this.data);
    this.patrimonioLiquido = this.data.data.valorPatrimonioLiquido;
  }

  salvarEdicao(): void {
    this.fundoInvestimentoService
      .editarPatrimonioLiquido(
        moment(this.data.data.dataCompetencia).format("YYYY-MM-DD"),
        this.patrimonioLiquido,
        this.data.cnpj.replace(/[^\d]/g, "")
      )
      .subscribe(() => {
        window.location.reload();
        this.dialogRef.close({ novoValor: this.patrimonioLiquido });
      });
  }

  fecharModal(): void {
    // Feche o modal sem retornar dados
    this.dialogRef.close();
  }
}
