import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIService } from 'src/app/_helpers/ui.service';
import { Carteira } from 'src/app/_models/carteira.model';

export interface DialogData {
  fundo: Carteira,
}


@Component({
  selector: 'app-dialog-movimentos-simulador',
  templateUrl: './dialog-movimentos-simulador.component.html',
  styleUrls: ['./dialog-movimentos-simulador.component.scss']
})
export class DialogMovimentosSimuladorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogMovimentosSimuladorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private uiService: UIService){}

  ngOnInit() {
  }

}
