import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { InformacoesOtimizacao } from '../_services/otimizador.service';
import { Usuario } from '../_models/user.models';

// Section 2
export const LOGIN = '[LOGIN] login';
export const LOGOUT = '[LOGIN] Logout';

// Section 3
export class Login implements Action {
    readonly type = LOGIN;

    constructor(public payload: Usuario) { }
}

export class Logout implements Action {
    readonly type = LOGOUT;

    constructor() { }
}

// Section 4
export type Actions = Login | Logout;
