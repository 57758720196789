import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('https://viacep.com.br/ws')) {
      const copiedReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
      return next.handle(copiedReq);
    } else if (!req.url.endsWith('login') &&
               !req.url.endsWith('logo') &&
               !req.url.endsWith('forgot') &&
               !req.url.includes('forgot?token=')
    ) {
      let headers = req.headers.append('Authorization', 'Bearer ' + this.authService.getUsuarioLogado().token);
      if(this.authService.proxyUserId){
        headers = headers.append('proxyUserId', this.authService.proxyUserId.toString());
      }
      const copiedReq = req.clone({
        headers: headers,
      });
      return next.handle(copiedReq);
    } else {
      const copiedReq = req.clone({ headers: req.headers.set('xAccessToken', 'expeliarmus') });
      return next.handle(copiedReq);
    }
  }
}
