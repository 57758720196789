<div style="display: flex; flex-flow: row; align-items: center">
  <form class="hidePrint" style="z-index: 9999; display: flex; width: 100%">
    <mat-form-field style="z-index: 9999">
      <input
        matInput
        [matDatepicker]="dp"
        [max]="atual"
        placeholder="Mês/Ano"
        [formControl]="date"
        [min]="minDate"
      />
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker
        #dp
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, dp)"
        panelClass="example-month-picker"
      >
      </mat-datepicker>
    </mat-form-field>
    <button
      mat-flat-button
      color="primary"
      style="
        flex-grow: 0;
        align-self: center;
        justify-self: center;
        margin-right: 10px;
        margin-left: 15px;
      "
      (click)="filtro()"
    >
      Exportar
    </button>
    <!-- <strong>
      Data do último saldo: {{ ultimaDataSaldo | date : "dd/MM/yyyy" }}
    </strong> -->
    <!-- <span style="flex: 1"></span>
    <button
      mat-flat-button
      (click)="imprimir()"
      color="primary"
      style="flex-grow: 0; align-self: center; justify-self: center"
    >
      <i class="fas fa-print"></i> Versão para impressão
    </button> -->
  </form>
</div>

<div class="page" style="justify-content: center; align-self: center">
  <img
    style="
      justify-self: center;
      align-self: center;
      width: auto;
      max-height: 250px;
    "
    [src]="'data:image/png;base64,' + cliente?.foto"
  />
  <br />
  <h1 style="text-align: center">{{ cliente?.nome?.toUpperCase() }}</h1>
</div>

<table mat-table [dataSource]="exportacaoList">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Data do Filtro</th>
    <td mat-cell *matCellDef="let item">
      {{ item.data | date : "MM/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="criadoEm">
    <th mat-header-cell *matHeaderCellDef>Data da Solicitação</th>
    <td mat-cell *matCellDef="let item">
      {{ item.criadoEm | date : "dd/MM/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let item">{{ item.status }}</td>
  </ng-container>

  <ng-container matColumnDef="download">
    <th mat-header-cell *matHeaderCellDef>Opções</th>
    <td mat-cell *matCellDef="let item">
      <button
        mat-flat-button
        color="primary"
        [disabled]="!(item.status === 'Finalizado')"
        style="flex-grow: 0; align-self: center; justify-self: center"
        (click)="downloadArquivo(item)"
      >
        Baixar Arquivo
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="colunas"></tr>
  <tr mat-row *matRowDef="let row; columns: colunas"></tr>
</table>
