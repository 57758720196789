import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService, TipoUsuario, TiposUsuario } from 'src/app/_services/auth.service';
import { UIService } from 'src/app/_helpers/ui.service';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DialogConfirmComponent } from 'src/app/_helpers/dialog-confirm/dialog-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogLoadingComponent } from 'src/app/_helpers/dialog-loading/dialog-loading.component';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { PasswordStrengthValidator } from 'src/app/_validators/password-strength.validator';
import { UserValidator } from 'src/app/_validators/user.validator';
import { Usuario } from 'src/app/_models/user.models';

@Component({
  selector: 'app-cadastro-usuario',
  templateUrl: './cadastro-usuario.component.html',
  styleUrls: ['./cadastro-usuario.component.scss']
})
export class CadastroUsuarioComponent implements OnInit {

  form: FormGroup;

  idUsuario: number;

  tiposUsuario = TiposUsuario;
  isLoading: Subject<boolean>;

  constructor(
    private authService: AuthService,
    private uiService: UIService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private usuarioService: UsuarioService,
    private userValidator: UserValidator
    ) {
      this.form = new FormGroup({
        nome: new FormControl(null, [Validators.required]),
        eMail: new FormControl(null, [Validators.required, Validators.email], [this.userValidator.checkEmail.bind(this.userValidator)]),
        pwd: new FormControl(null, [Validators.required, PasswordStrengthValidator]),
        tipoUser: new FormControl(null, [Validators.required])
      });

    }

  ngOnInit() {
    this.isLoading = this.uiService.loadingChangeState;
    this.idUsuario = +this.route.snapshot.paramMap.get('id');
    if (this.idUsuario) {
      this.isLoading.next(true);
      this.usuarioService.getUsuario(this.idUsuario).subscribe(
        usuario => {
          this.isLoading.next(false);
          this.form.patchValue(usuario);
        }, error => {
          this.isLoading.next(false);
          this.router.navigate(['usuarios']);
          this.uiService.showSnackBar('Erro ao buscar os dados do usuário, tente novamente', 'OK', 4000);
        }
      );
    }

    const tipoUsuarioAtual = this.authService.getUsuarioLogado().tipoUser;
    if (tipoUsuarioAtual === TipoUsuario.ADMIN) {
      this.tiposUsuario = this.tiposUsuario.filter(t => t.value <= 2);
    } else if (tipoUsuarioAtual === TipoUsuario.ADMIN_CLIENTE) {
      this.tiposUsuario = this.tiposUsuario.filter(t => t.value > 2);
    }
  }

  salvar() {
    const usuario = <Usuario> this.form.value;
    const dialogRef = this.dialog.open(DialogLoadingComponent, {
      data: {
        text: 'Salvando usuário...',
        target: this.authService.register(usuario),
        closeOnComplete: true,
        successMessage: 'Usuário salvo com sucesso',
        errorMessage: 'Erro ao salvar, tente novamente'
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.router.navigateByUrl('/usuarios');
      }
    });
  }
}

