import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { AuthService, TipoUsuario } from '../_services/auth.service';

@Directive({
  selector: '[appHasAcao]'
})
export class HasAcaoDirective {

  @Input() appHasAcao: string[];

  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
        const tipoUsuario = this.authService.getUsuarioLogado().tipoUser;
        if ([TipoUsuario.ADMIN, TipoUsuario.USUARIO].includes(tipoUsuario)) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else if(Array.isArray(this.appHasAcao)) {
          const acoesUsuario = this.authService.getUsuarioLogado().acoes;
          if(Array.isArray(acoesUsuario) && acoesUsuario.some(acao => this.appHasAcao.includes(acao))) {
            if (!this.isVisible) {
              this.isVisible = true;
              this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
    }
  }
}
