import { DatePipe } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Moment } from "moment";
import { environment } from "src/environments/environment";
import { APR } from "../_models/arp.model";
import {
  Carteira,
  MovimentoCarteira,
  Retorno,
  RetornoAnual,
} from "../_models/carteira.model";
import {
  InformeFundo,
  InformeCorrigirCota,
} from "../_models/fundo-investimento.models";
import { FundoAudesp } from "../_models/fundos-audesp.model";
import { MedidasRisco } from "../_components/riscos-novo/risco-novos.types";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class CarteiraService {
  constructor(private http: HttpClient) {}

  getValorCotaDia = (cnpj: string, data: Date) => {
    cnpj = cnpj.replace(/[^\d\s]/gi, "");
    const dateString = new DatePipe("pt-BR").transform(data, "yyyy-MM-dd");
    const url = `${environment.PATH_API}/fi/informe/${cnpj}/${dateString}`;
    return this.http.get<InformeFundo>(url);
  };

  postCorrigirCota = (obj: object) => {
    const url = `${environment.PATH_API}/carteira/investimentos/ajustecota`;
    return this.http.post(url, obj, { responseType: "text" });
  };

  calculaNumCotasPorValor = (valorInvestimento: number, valorCota: number) => {
    return (valorInvestimento / valorCota).toFixed(9);
  };

  criarFundoCarteira = (fundoCarteira) => {
    const url = `${environment.PATH_API}/carteira/`;
    return this.http.post(url, fundoCarteira);
  };

  alterarDadosBancarios = (idInvestimento, obj) => {
    const url = `${environment.PATH_API}/carteira/${idInvestimento}`;
    return this.http.put(url, obj);
  };

  amotizacaoFundo = (idInvestimento, movimento) => {
    const url = `${environment.PATH_API}/carteira/amortizacao/${idInvestimento}`;
    return this.http.post<MovimentoCarteira>(url, movimento);
  };

  resgateFundo = (idInvestimento, movimento) => {
    const url = `${environment.PATH_API}/carteira/resgate/${idInvestimento}`;
    return this.http.post<MovimentoCarteira>(url, movimento);
  };

  aplicacaoFundo = (idInvestimento, movimento) => {
    const url = `${environment.PATH_API}/carteira/aplicacao/${idInvestimento}`;
    return this.http.post<MovimentoCarteira>(url, movimento);
  };

  lancarMovimentoApr = (apr: APR) => {
    const url = `${environment.PATH_API}/carteira/fromapr`;
    return this.http.post<MovimentoCarteira>(url, [apr]);
  };

  lancarAjusteSaldo = (
    idInvestimento,
    movimento: Partial<MovimentoCarteira>
  ) => {
    const url = `${environment.PATH_API}/carteira/ajuste_saldo/${idInvestimento}`;
    return this.http.post(url, movimento);
  };

  lancarTodasAprs = (aprs: APR[]) => {
    const url = `${environment.PATH_API}/carteira/fromapr`;
    return this.http.post<MovimentoCarteira>(url, aprs);
  };

  atualizacaoMovimento = (idInvestimento, idMovimento, movimento) => {
    const url = `${environment.PATH_API}/carteira/ajuste/${idInvestimento}/${idMovimento}`;
    return this.http.put(url, movimento);
  };

  removerMovimento = (idInvestimento, idMovimento) => {
    const url = `${environment.PATH_API}/carteira/delete/${idInvestimento}/${idMovimento}`;
    return this.http.delete(url);
  };

  getCarteiraCliente = (idCliente: number, cnpj?: string, periodo?: Date) => {
    const url = `${environment.PATH_API}/carteira/${idCliente}`;
    let params = new HttpParams();
    if (cnpj) {
      params = params.append("cnpj", cnpj.replace(/\D/g, ""));
    }
    if (periodo) {
      params = params.append("periodo", periodo.toISOString());
    }
    return this.http.get<Carteira[]>(url, { params });
  };

  getFundosCarteiraAcumulado = (idCliente: number, periodo?: string) => {
    const url = `${environment.PATH_API}/carteira/efetivo-acumulados/${idCliente}`;
    let params = new HttpParams();
    if (periodo) {
      params = params.append("periodo", periodo);
    }
    return this.http.get<any>(url, { params });
  };

  getTotalAcumulado = (idCliente: number, periodo?: string) => {
    const url = `${environment.PATH_API}/carteira/efetivo-acumulados-totais/${idCliente}`;
    let params = new HttpParams();
    if (periodo) {
      params = params.append("periodo", periodo);
    }
    return this.http.get<any>(url, { params });
  };

  getSaldoCliente = (idCliente: number) => {
    const url = `${environment.PATH_API}/carteira/saldo/${idCliente}`;
    return this.http.get(url);
  };

  getMovimentosInvestimento = (idCliente: number, idInvestimento: number) => {
    const url = `${environment.PATH_API}/carteira/${idCliente}/investimento/${idInvestimento}`;
    return this.http.get<MovimentoCarteira[]>(url);
  };

  removerFundo = (idInvestimento) => {
    const url = `${environment.PATH_API}/carteira/${idInvestimento}`;
    return this.http.delete(url);
  };

  relatorioRetornos = (
    idCliente: number,
    periodo: Moment,
    tipoFundo: String
  ) => {
    const url = `${environment.PATH_API}/carteira/retorno/${idCliente}`;
    let params = new HttpParams();
    params = params.append("periodo", periodo.format("YYYY-MM-DD"));
    params = params.append(
      "tipoFundo",
      tipoFundo != null ? tipoFundo.toString() : ""
    );
    return this.http.get<Retorno[]>(url, { params: params });
  };

  relatorioRetornosAnual = (idCliente: number, periodo: Moment) => {
    const url = `${
      environment.PATH_API
    }/carteira/anual/${idCliente}/${periodo.format("YYYY")}`;
    return this.http.get<RetornoAnual[]>(url);
  };

  relatorioRetornosAcumulado = (idCliente: number, ano: Moment) => {
    const url = `${environment.PATH_API}/carteira/retorno-acumulado/${idCliente}`;
    let params = new HttpParams();
    params = params.append("ano", ano.format("YYYY"));
    return this.http.get<any[]>(url, { params: params });
  };

  getCarteiraAudespCliente = (idCliente: number, periodo: String) => {
    const url = `${environment.PATH_API}/carteira/fundos-audesp/${idCliente}/${periodo}`;

    return this.http.get<FundoAudesp[]>(url);
  };

  getNovoMedidasRisco = (
    idCliente: number,
    data: any
  ): Observable<MedidasRisco[]> => {
    const ano = data.slice(0, 4); // Obtém os primeiros 4 caracteres para o ano
    const mes = data.slice(5, 7); // Obtém os caracteres da posição 5 ao 7 para o mês
    const url = `${environment.PATH_API}/novoBonitao/medidas-risco/${idCliente}?ano=${ano}&mes=${mes}`;
    return this.http.get<MedidasRisco[]>(url);
  };
}
