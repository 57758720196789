import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BlackListBaixoRisco, CVMBlackList } from '../_models/blacklist.model';
import { Credenciamento } from '../_models/credenciamento.model';
import { Page, PageRequest } from '../_models/PaginatedResult';

export interface BlackListQuery{
  search: string
}

@Injectable({
  providedIn: 'root'
})
export class BlackListService {

  constructor(private http: HttpClient) { }


  listarCVMPaginado(request: PageRequest<CVMBlackList>, query: BlackListQuery):Observable<Page<CVMBlackList>> {
    const url = `${environment.PATH_API}/cvmblacklist`;
    let params = new HttpParams();

    params = params.append('page', request.page.toString());
    params = params.append('limit', request.size.toString());
    const dados = this.http.get<Page<CVMBlackList>>(url, {params: params});

    console.log(dados);
    return dados;
  }



  listarBaixoRiscoPaginado(request: PageRequest<BlackListBaixoRisco>, query: BlackListQuery,id?:string):Observable<Page<BlackListBaixoRisco>> {
    const url = `${environment.PATH_API}/emissorbaixoriscoblacklist`;
    let params = new HttpParams();

    params = params.append('page', request.page.toString());
    params = params.append('limit', request.size.toString());
    return this.http.get<Page<BlackListBaixoRisco>>(url, {params: params});
  }

  listarCVM():Observable<CVMBlackList[]> {
    const url = `${environment.PATH_API}/cvmblacklist/all`;
    let params = new HttpParams();

    const dados = this.http.get<CVMBlackList[]>(url, {params: params});

    return dados;
  }



  listarBaixoRisco():Observable<BlackListBaixoRisco[]> {
    const url = `${environment.PATH_API}/emissorbaixoriscoblacklist/all`;
    let params = new HttpParams();

    return this.http.get<BlackListBaixoRisco[]>(url, {params: params});
  }

  salvarBlackListCVM(obj){
    const url = `${environment.PATH_API}/cvmblacklist`;
    return this.http.post<Credenciamento>(url, obj);
  }

  salvarBlackListBaixoRisco(obj){
    const url = `${environment.PATH_API}/emissorbaixoriscoblacklist`;
    return this.http.post<Credenciamento>(url, obj);
  }

  removerBlackListCVM(id: Number) {
    const url = `${environment.PATH_API}/cvmblacklist/${id}`;
    return this.http.delete(url);
  }

  removerBlackListBaixoRisco(id: Number) {
    const url = `${environment.PATH_API}/emissorbaixoriscoblacklist/${id}`;
    return this.http.delete(url);
  }
}

