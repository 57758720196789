import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  AnaliseRelatorio,
  Comentario,
  Documento,
} from "../_models/documentos.model";
import { Drei } from "../_models/drei.mode";
import { Page, PageRequest } from "../_models/PaginatedResult";

export interface DocumentoQuery {
  search: string;
}

@Injectable({
  providedIn: "root",
})
export class ComentarioService {
  constructor(private http: HttpClient) {}

  listarDocumentosPaginado(
    request: PageRequest<Comentario>,
    query: DocumentoQuery,
    id?: string
  ): Observable<Page<Comentario>> {
    const url = `${environment.PATH_API}/comentario`;
    let params = new HttpParams();
    if (query.search) {
      params = params.append("titulo", query.search);
    }
    params = params.append("page", request.page.toString());
    params = params.append("limit", request.size.toString());
    return this.http.get<Page<Comentario>>(url, { params: params });
  }

  listarDocumentosPaginadoNovo(
    request: PageRequest<Comentario>,
    query: DocumentoQuery,
    id?: string
  ): Observable<Page<Comentario>> {
    const url = `${environment.PATH_API}/comentarioNovo`;
    let params = new HttpParams();
    if (query.search) {
      params = params.append("titulo", query.search);
    }
    params = params.append("page", request.page.toString());
    params = params.append("limit", request.size.toString());
    return this.http.get<Page<Comentario>>(url, { params: params });
  }

  listarAnaliseRelatorio(): Observable<Page<AnaliseRelatorio>> {
    const url = `${environment.PATH_API}/comentarioBonitao`;

    return this.http.get<Page<AnaliseRelatorio>>(url);
  }

  criarDocumento(documento: any) {
    const url = `${environment.PATH_API}/comentario`;
    return this.http.post(url, documento);
  }

  criarDocumentoNovo(documento: any) {
    const url = `${environment.PATH_API}/comentarioNovo`;
    return this.http.post(url, documento);
  }

  addAnalise(obj: AnaliseRelatorio) {
    const url = `${environment.PATH_API}/comentarioBonitao`;
    return this.http.post(url, obj);
  }

  deleteAnalise(idAnalise: any) {
    const url = `${environment.PATH_API}/comentarioBonitao/${idAnalise}`;
    return this.http.delete(url);
  }

  buscarListaClientesDaAnalise(idAnalise: any) {
    const url = `${environment.PATH_API}/comentarioBonitao/${idAnalise}/cliente`;
    return this.http.get(url);
  }

  vincularClientes(obj: any) {
    const url = `${environment.PATH_API}/ComentarioBonitao/vincularCliente`;
    return this.http.post(url, obj);
  }

  deleteVinculo(idComentarioBonitao: number, idCliente: number) {
    const url = `${environment.PATH_API}/ComentarioBonitao/${idComentarioBonitao}/cliente/${idCliente}`;
    return this.http.delete(url);
  }

  buscarLinkDocumento(idDocumento: any) {
    const url = `${environment.PATH_API}/comentario/link/${idDocumento}`;
    return this.http.get(url);
  }

  buscarLinkDocumentoNovo(idDocumento: any) {
    const url = `${environment.PATH_API}/comentarioNovo/link/${idDocumento}`;
    return this.http.get(url);
  }

  deleteDocumento(idDocumento: any) {
    const url = `${environment.PATH_API}/comentario/${idDocumento}`;
    return this.http.delete(url);
  }

  deleteDocumentoNovo(idDocumento: any) {
    const url = `${environment.PATH_API}/comentarioNovo/${idDocumento}`;
    return this.http.delete(url);
  }
}
