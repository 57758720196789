<h1>Nova resolução</h1>
<form [formGroup]="form" (ngSubmit)="submit()">
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
    <div fxLayout="column" fxFlex="70">
      <mat-form-field fxFlex="70">
        <mat-label required>Nome</mat-label>
        <input matInput type="text" placeholder="Nome" formControlName="nome">
      </mat-form-field>
      <mat-form-field fxFlex="70">
        <mat-label required>Legislação</mat-label>
        <input matInput type="text" placeholder="Legislação" formControlName="legislacao">
      </mat-form-field>
      <mat-form-field fxFlex="70">
        <mat-label required>Tipo</mat-label>
        <mat-select required placeholder="Tipo" formControlName="tipo">
          <mat-option>Selecione</mat-option>
          <mat-option value="RPPS">RPPS</mat-option>
          <mat-option value="FP">FP</mat-option>
          <mat-option value="PRO-GESTAO">PRÓ-GESTÃO</mat-option>
          <mat-option value="OUTROS">OUTROS</mat-option>
        </mat-select>
      </mat-form-field>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
        <mat-form-field fxFlex="50">
          <input matInput formControlName="limite" required [dropSpecialCharacters]="false" placeholder="Limite Resolução" aria-label="Limite Resolução" [patterns]="customPatterns" mask="0*.0000" sufix="%">
        </mat-form-field>
        <mat-form-field fxFlex="50" *ngIf="form.value.tipo === 'RPPS'">
          <input matInput formControlName="limiteParticipacao" required [dropSpecialCharacters]="false" placeholder="Limite participação" aria-label="Limite participação" [patterns]="customPatterns" mask="0*.0000" sufix="%">
        </mat-form-field>

        <mat-form-field fxFlex="50" *ngIf="form.value.tipo === 'FP'">
          <input matInput formControlName="limiteInferior" required [dropSpecialCharacters]="false" placeholder="Limite Inferior" aria-label="Limite Inferior" [patterns]="customPatterns" mask="0*.0000" sufix="%">
        </mat-form-field>
        <mat-form-field fxFlex="50" *ngIf="form.value.tipo === 'FP'">
          <input matInput formControlName="limiteSuperior" required [dropSpecialCharacters]="false" placeholder="Limite Superior" aria-label="Limite Superior" [patterns]="customPatterns" mask="0*.0000" sufix="%">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button mat-raised-button color="accent" type="submit" [disabled]="!form.valid"><mat-icon>save</mat-icon>Salvar</button>
    <button mat-raised-button color="warn" type="button" routerLink="/resolucao"><mat-icon>close</mat-icon> Cancelar</button>
  </div>

</form>
