import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BenchmarkService } from './../../../_services/benchmark.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { UIService } from 'src/app/_helpers/ui.service';
import { DialogConfirmComponent } from 'src/app/_helpers/dialog-confirm/dialog-confirm.component';
import { MetaService } from 'src/app/_services/meta.service';
import { CotaMeta, Meta } from 'src/app/_models/benchmark.models';

@Component({
  selector: 'app-cadastro-metas',
  templateUrl: './cadastro-metas.component.html',
  styleUrls: ['./cadastro-metas.component.scss']
})
export class CadastroMetasComponent implements OnInit {

  // Para focar novamente no campo
  @ViewChild('percentual12', { static: true }) percentual12: ElementRef;

  idMeta: number;

  formBenchmark = new FormGroup({
    nome: new FormControl(null, Validators.required)
  });

  form = new FormGroup({
    data: new FormControl(new Date(), Validators.required),
    percentualMes: new FormControl(null, Validators.required),
    percentualAno: new FormControl(null, Validators.required),
    percentual3: new FormControl(null, Validators.required),
    percentual6: new FormControl(null, Validators.required),
    percentual12: new FormControl(null, Validators.required),
    percentual18: new FormControl(null, Validators.required),
    percentual24: new FormControl(null, Validators.required),
  });

  columnsToDisplay = ['data', 'percentualMes', 'percentualAno', 'percentual3',
                    'percentual6', 'percentual12', 'percentual18', 'percentual24', 'remover'];
  meta: Meta;
  quotes: CotaMeta[];
  quotesDatasource: MatTableDataSource<CotaMeta>;

  constructor(
    private route: ActivatedRoute,
    private metaService: MetaService,
    private dialog: MatDialog,
    private uiService: UIService,
    private router: Router) {}

  ngOnInit() {
    this.idMeta = +this.route.snapshot.paramMap.get('id');
    this.quotes = new Array<CotaMeta>();
    this.quotesDatasource = new MatTableDataSource(this.quotes);
    if (this.idMeta) {
      this.metaService.getMeta(this.idMeta).subscribe(
        (meta: Meta) => {
          this.formBenchmark.patchValue(meta);
          this.meta = meta;
          this.quotes = meta.quotes;
          this.quotesDatasource = new MatTableDataSource(this.quotes);
        }
      );
    }
  }

  salvar() {
    if (!this.idMeta) { // Criar novo benchmark
      this.meta = {
        nome: this.formBenchmark.get('nome').value,
        quotes: this.quotes
      };
      this.metaService.criarMeta(this.meta).subscribe(
        response => {
          const dialogRef = this.dialog.open(DialogConfirmComponent, {
            width: '500px',
            data: {text: 'Meta salva com sucesso!'}
          });
          dialogRef.afterClosed().subscribe(
            (result) => this.router.navigate(['metas'])
          );
        },
        error => {
          this.uiService.showSnackBar('Erro ao salvar os dados da meta', null, 4000);
        }
      );
    } else {
      this.meta.quotes = this.quotes; // Atualiza os quotes para salvar
      this.metaService.atualizarMeta(this.meta).subscribe(
        (response) => {
          const dialogRef = this.dialog.open(DialogConfirmComponent, {
            width: '500px',
            data: {text: 'Meta salva com sucesso!'}
          });
          dialogRef.afterClosed().subscribe(
            (result) => this.router.navigate(['metas'])
          );
        },
        error => {
          this.uiService.showSnackBar('Erro ao salvar os dados da meta', null, 4000);
        }
      );
    }
  }

  adicionarQuota() {
    let quote: CotaMeta;
    quote = <CotaMeta>{
      ...this.form.value
    };
    console.log(quote);
    if (this.idMeta) {
      quote.id =  this.idMeta;
    }
    this.quotes.push(quote);
    this.quotesDatasource.data = this.quotes;
    this.form.reset();
    this.form.get('data').setValue(new Date());
    this.percentual12.nativeElement.focus();
  }

  removerQuota = (index) => {
    this.quotes = this.quotes.filter((v, i) => i !== index);
    this.quotesDatasource.data = this.quotes;
  }

}
