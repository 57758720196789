import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";

@Component({
  selector: "app-view-analise-relatorio",
  templateUrl: "./view-analise-relatorio.component.html",
  styleUrls: ["./view-analise-relatorio.component.scss"],
})
export class ViewAnaliseRelatorioComponent implements OnInit {
  dataFormatada: any;
  constructor(
    public dialogRef: MatDialogRef<ViewAnaliseRelatorioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dataFormatada = new Date(this.data.data).toLocaleDateString("pt-BR", {
      timeZone: "UTC",
      year: "numeric",
      month: "long",
    });
  }
}
