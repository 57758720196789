

 <p *ngIf="!laminaResumo">
    <strong>GESTÃO DE RISCO - MERCADO:</strong> O valor dos ativos que compõem a carteira do fundo pode aumentar ou diminuir de acordo com flutuações de preços e cotações de mercado, mudanças no cenário político e
econômico, alterações nas taxas de juros e, ainda, com os resultados das empresas emitentes de valores mobiliários (ações, debêntures, notas promissórias, entre outros).
<br/>

<strong>Indice Sharpe:</strong> O índice de Sharpe é uma medida que permite a classicação do fundo em termos de desempenho global, sendo considerados melhores os fundos com maior índice. Vale lembrar ainda que o índice
varia se o resultado for calculado com base em rentabilidades semanais, mensais ou anuais.
<br/>
<strong>VaR</strong> - Value at Risk: Value-at-risk ou Valor sob Risco pode ser definido como perda máxima, num determinado período, sob condições normais de mercado, com determinado grau de confiança.

  </p>



  <table mat-table [dataSource]="indices" [hidden]="!indices">
    <ng-container matColumnDef="cnpj">
      <th mat-header-cell *matHeaderCellDef class="left"> CNPJ Fundo </th>
      <td mat-cell *matCellDef="let element" class="left"> {{element.cnpj | mask: '00.000.000/0000-00'}} </td>
    </ng-container>
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef style="width: 40%; text-align: left"> Nome </th>
      <td mat-cell *matCellDef="let element" style="width: 40%; text-align: left" class="small"> {{element.nome}} </td>
    </ng-container>
    <ng-container matColumnDef="saldo">
      <th mat-header-cell *matHeaderCellDef> Saldo </th>
      <td mat-cell *matCellDef="let element" class="small"> {{element.saldo | currency: 'BRL'}} </td>
    </ng-container>
    <ng-container matColumnDef="benchmark">
      <th mat-header-cell *matHeaderCellDef> Benchmark </th>
      <td mat-cell *matCellDef="let element" class="small"> {{element.benchmark}} </td>
    </ng-container>
    <ng-container matColumnDef="retorno">
      <th mat-header-cell *matHeaderCellDef> Retorno </th>
      <td mat-cell *matCellDef="let element"> {{element.retorno | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingRetorno" >
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Retorno </th>
      <td mat-cell *matCellDef="let element" class="center"> {{element.rankingRetorno}} </td>
    </ng-container>
    <ng-container matColumnDef="desvpad" >
      <th mat-header-cell *matHeaderCellDef> D. Padrão </th>
      <td mat-cell *matCellDef="let element"> {{element.desvpad | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingDesvpad">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank D. Padrão </th>
      <td mat-cell *matCellDef="let element"  class="center"> {{element.rankingDesvpad}} </td>
    </ng-container>
    <ng-container matColumnDef="sharpe">
      <th mat-header-cell *matHeaderCellDef> Sharpe </th>
      <td mat-cell *matCellDef="let element"> {{element.sharpe | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingSharpe">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Sharpe</th>
      <td mat-cell *matCellDef="let element" class="center"> {{element.rankingSharpe}} </td>
    </ng-container>
    <ng-container matColumnDef="var">
      <th mat-header-cell *matHeaderCellDef> VAR 12M</th>
      <td mat-cell *matCellDef="let element"> {{element.var | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="vol12">
      <th mat-header-cell *matHeaderCellDef> VOL 12M</th>
      <td mat-cell *matCellDef="let element"> {{element.vol12 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="vol6">
      <th mat-header-cell *matHeaderCellDef> VOL 6M</th>
      <td mat-cell *matCellDef="let element"> {{element.vol6 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="vol3">
      <th mat-header-cell *matHeaderCellDef> VOL 3M</th>
      <td mat-cell *matCellDef="let element"> {{element.vol3 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingVar">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank VAR </th>
      <td mat-cell *matCellDef="let element" class="center"> {{element.rankingVar}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>

  <br/>
  <table mat-table [dataSource]="[1]" [hidden]="!indices" >
    <ng-container matColumnDef="cnpj">
      <th mat-header-cell *matHeaderCellDef class="left"></th>
      <td mat-cell *matCellDef="let element" class="left"> Total </td>
    </ng-container>
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef style="width: 40%; text-align: left"> </th>
      <td mat-cell *matCellDef="let element" style="width: 40%; text-align: left" class="small"> Geral </td>
    </ng-container>
    <ng-container matColumnDef="saldo">
      <th mat-header-cell *matHeaderCellDef> Saldo </th>
      <td mat-cell *matCellDef="let element" class="small"> {{saldoTotal | currency:"BRL"}}</td>
    </ng-container>
    <ng-container matColumnDef="benchmark">
      <th mat-header-cell *matHeaderCellDef> Benchmark </th>
      <td mat-cell *matCellDef="let element" class="small"> </td>
    </ng-container>
    <ng-container matColumnDef="sharpe">
      <th mat-header-cell *matHeaderCellDef> Sharpe </th>
      <td mat-cell *matCellDef="let element"> {{sharpeGeral | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="var">
      <th mat-header-cell *matHeaderCellDef> VAR 12M</th>
      <td mat-cell *matCellDef="let element"> {{ varGeral | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="vol12">
      <th mat-header-cell *matHeaderCellDef> VOL 12M</th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>
    <ng-container matColumnDef="vol6">
      <th mat-header-cell *matHeaderCellDef> VOL 6M</th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>
    <ng-container matColumnDef="vol3">
      <th mat-header-cell *matHeaderCellDef> VOL 3M</th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>

  <span *ngIf="menorVar" style="text-align: center;">
    <br/>
    <br/>
    O fundo {{ menorVar.nome | resumoNomeFundo }} obteve o menor VaR% dentre os fundos da
  carteira, sendo assim, é o fundo que apresenta menor risco.
  <br/><br/>
  </span>

  <span *ngIf="!laminaResumo">
  <h2>
    Provisão de risco - Mercado
  </h2>
  <table mat-table [dataSource]="provisao" [hidden]="!provisao"  >
    <ng-container matColumnDef="cnpj">
      <th mat-header-cell *matHeaderCellDef class="left"> CNPJ Fundo </th>
      <td mat-cell *matCellDef="let element" class="left"> {{element.cnpj | mask: '00.000.000/0000-00'}} </td>
    </ng-container>
    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef style="width: 40%; text-align: left"> Nome </th>
      <td mat-cell *matCellDef="let element" style="width: 40%; text-align: left" class="small"> {{element.nome}} </td>
    </ng-container>
    <ng-container matColumnDef="benchmark">
      <th mat-header-cell *matHeaderCellDef> Benchmark </th>
      <td mat-cell *matCellDef="let element" class="small"> {{element.benchmark}} </td>
    </ng-container>
    <ng-container matColumnDef="saldo">
      <th mat-header-cell *matHeaderCellDef> Saldo </th>
      <td mat-cell *matCellDef="let element" class="small"> {{element.saldo | currency:'BRL'}} </td>
    </ng-container>
    <ng-container matColumnDef="retorno">
      <th mat-header-cell *matHeaderCellDef> Retorno </th>
      <td mat-cell *matCellDef="let element"> {{element.retorno | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingRetorno" >
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Retorno </th>
      <td mat-cell *matCellDef="let element" class="center"> {{element.rankingRetorno}} </td>
    </ng-container>
    <ng-container matColumnDef="desvpad" >
      <th mat-header-cell *matHeaderCellDef> D. Padrão </th>
      <td mat-cell *matCellDef="let element"> {{element.desvpad | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingDesvpad">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank D. Padrão </th>
      <td mat-cell *matCellDef="let element"  class="center"> {{element.rankingDesvpad}} </td>
    </ng-container>
    <ng-container matColumnDef="sharpe">
      <th mat-header-cell *matHeaderCellDef> Sharpe </th>
      <td mat-cell *matCellDef="let element"> {{element.sharpe | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingSharpe">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Sharpe</th>
      <td mat-cell *matCellDef="let element" class="center"> {{element.rankingSharpe}} </td>
    </ng-container>
    <ng-container matColumnDef="var">
      <th mat-header-cell *matHeaderCellDef> VAR 12M</th>
      <td mat-cell *matCellDef="let element"> {{element.var * 1000 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="vol12">
      <th mat-header-cell *matHeaderCellDef> VOL 12M</th>
      <td mat-cell *matCellDef="let element"> {{element.vol12 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="vol6">
      <th mat-header-cell *matHeaderCellDef> VOL 6M</th>
      <td mat-cell *matCellDef="let element"> {{element.vol6 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="vol3">
      <th mat-header-cell *matHeaderCellDef> VOL 3M</th>
      <td mat-cell *matCellDef="let element"> {{element.vol3 | percent:'1.6'}} </td>
    </ng-container>
    <ng-container matColumnDef="rankingVar">
      <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank VAR </th>
      <td mat-cell *matCellDef="let element" class="center"> {{element.rankingVar}} </td>
    </ng-container>
    <ng-container matColumnDef="valorDU">
      <th mat-header-cell *matHeaderCellDef> VAR% BASE 1 DIA<br/>
        CONFIANÇA 95%<br/>
        252 DU* (R$) </th>
      <td mat-cell *matCellDef="let element"> {{element.saldoDU | currency:'BRL'}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsProvisao"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsProvisao;"></tr>
  </table>



<br/>
  <table border="1" style="width: 60%;">
    <thead><th colspan="2">Risco de mercado</th></thead>
    <tbody>
      <tr>
        <td>Saldo Total</td>
        <td>{{saldoTotal | currency: 'BRL'}}</td>
      </tr>
      <tr>
        <td>Provisão para perda e Investimentos:</td>
        <td>{{provisaoPerda | currency: 'BRL'}}</td>
      </tr>
      <tr>
        <td>% Provisão de perda</td>
        <td>{{percentualPerda | percent:'1.1-4'}}</td>
      </tr>
      <tr>
        <td>% Aceitavel perda</td>
        <td>{{percentualAceitavelPerda | percent:'1.1-4'}}</td>
      </tr>
      <tr>
        <td>Limite de utilização</td>
        <td
          [class.green]="(percentualPerda / percentualAceitavelPerda) <= 0.50"
          [class.yellow]="(percentualPerda / percentualAceitavelPerda) > 0.50 && (percentualPerda / percentualAceitavelPerda) < 0.75"
          [class.yellow]="(percentualPerda / percentualAceitavelPerda) >= 0.75"
        >
        {{(percentualPerda / percentualAceitavelPerda) | percent:'1.1-4'}}
      </td>
      </tr>
    </tbody>
  </table>
</span>

<br/>
<span *ngIf="laminaResumo">
  <h4>INDICE SHARPE  </h4>
  <p style="text-align: center;">O índice de Sharpe é uma medida que permite a classificação do fundo em
    termos de desempenho global, sendo considerados melhores os fundos com
    maior índice. Vale lembrar ainda que o índice varia se o resultado for
    calculado com base e rentabilidades semanais, mensais ou anuais
    </p>
<table mat-table [dataSource]="indices" [hidden]="!indices" >
  <ng-container matColumnDef="cnpj">
    <th mat-header-cell *matHeaderCellDef class="left"> CNPJ Fundo </th>
    <td mat-cell *matCellDef="let element" class="left"> {{element.cnpj | mask: '00.000.000/0000-00'}} </td>
  </ng-container>
  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef style="width: 40%; text-align: left"> Nome </th>
    <td mat-cell *matCellDef="let element" style="width: 40%; text-align: left" class="small"> {{element.nome}} </td>
  </ng-container>
  <ng-container matColumnDef="saldo">
    <th mat-header-cell *matHeaderCellDef> Saldo </th>
    <td mat-cell *matCellDef="let element" class="small"> {{element.saldo}} </td>
  </ng-container>
  <ng-container matColumnDef="benchmark">
    <th mat-header-cell *matHeaderCellDef> Benchmark </th>
    <td mat-cell *matCellDef="let element" class="small"> {{element.benchmark}} </td>
  </ng-container>
  <ng-container matColumnDef="rankingRetorno" >
    <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Retorno </th>
    <td mat-cell *matCellDef="let element" class="center"> {{element.rankingRetorno}} </td>
  </ng-container>
  <ng-container matColumnDef="desvpad" >
    <th mat-header-cell *matHeaderCellDef> D. Padrão </th>
    <td mat-cell *matCellDef="let element"> {{element.desvpad | percent:'1.6'}} </td>
  </ng-container>
  <ng-container matColumnDef="rankingDesvpad">
    <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank D. Padrão </th>
    <td mat-cell *matCellDef="let element"  class="center"> {{element.rankingDesvpad}} </td>
  </ng-container>
  <ng-container matColumnDef="sharpe">
    <th mat-header-cell *matHeaderCellDef style="text-align: right;"> Sharpe </th>
    <td mat-cell *matCellDef="let element" style="text-align: right;"> {{element.sharpe | percent:'1.6'}} </td>
  </ng-container>
  <ng-container matColumnDef="rankingSharpe">
    <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank Sharpe</th>
    <td mat-cell *matCellDef="let element" class="center"> {{element.rankingSharpe}} </td>
  </ng-container>
  <ng-container matColumnDef="var">
    <th mat-header-cell *matHeaderCellDef> VAR 12M</th>
    <td mat-cell *matCellDef="let element"> {{element.var  * 1000 | percent:'1.6'}} </td>
  </ng-container>
  <ng-container matColumnDef="vol12">
    <th mat-header-cell *matHeaderCellDef> VOL 12M</th>
    <td mat-cell *matCellDef="let element"> {{element.vol12 | percent:'1.6'}} </td>
  </ng-container>
  <ng-container matColumnDef="vol6">
    <th mat-header-cell *matHeaderCellDef> VOL 6M</th>
    <td mat-cell *matCellDef="let element"> {{element.vol6 | percent:'1.6'}} </td>
  </ng-container>
  <ng-container matColumnDef="vol3">
    <th mat-header-cell *matHeaderCellDef> VOL 3M</th>
    <td mat-cell *matCellDef="let element"> {{element.vol3 | percent:'1.6'}} </td>
  </ng-container>
  <ng-container matColumnDef="rankingVar">
    <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Rank VAR </th>
    <td mat-cell *matCellDef="let element" class="center"> {{element.rankingVar}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsSharpe"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsSharpe;"></tr>
</table>
<br/>
<table mat-table [dataSource]="[1]" [hidden]="!indices">
  <ng-container matColumnDef="cnpj">
    <th mat-header-cell *matHeaderCellDef class="left"></th>
    <td mat-cell *matCellDef="let element" class="left"> Total </td>
  </ng-container>
  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef style="width: 40%; text-align: left"> </th>
    <td mat-cell *matCellDef="let element" style="width: 40%; text-align: left" class="small"> Geral </td>
  </ng-container>
  <ng-container matColumnDef="saldo">
    <th mat-header-cell *matHeaderCellDef> Saldo </th>
    <td mat-cell *matCellDef="let element" class="small"> </td>
  </ng-container>
  <ng-container matColumnDef="benchmark">
    <th mat-header-cell *matHeaderCellDef> Benchmark </th>
    <td mat-cell *matCellDef="let element" class="small"> </td>
  </ng-container>
  <ng-container matColumnDef="sharpe">
    <th mat-header-cell *matHeaderCellDef style="text-align: right;"> Sharpe </th>
    <td mat-cell *matCellDef="let element" style="text-align: right;"> {{sharpeGeral | percent:'1.6'}} </td>
  </ng-container>
  <ng-container matColumnDef="var">
    <th mat-header-cell *matHeaderCellDef> VAR 12M</th>
    <td mat-cell *matCellDef="let element"> {{ varGeral | percent:'1.6'}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsSharpe"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsSharpe;"></tr>
</table>
<br/>
<br/>
<span *ngIf="maiorSharpe" style="text-align: center;">
  O fundo {{maiorSharpe.nome | resumoNomeFundo}} teve o melhor Índice Sharpe
  positivo, portanto foi o fundo que teve melhor desempenho analisando a relação
  risco/retorno.
</span>
</span>

