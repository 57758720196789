<h3>{{ meta.nome }}</h3>
<mat-divider> </mat-divider>
<div *ngIf="chartData" [class.widthmax]="relatorioDinamico">
  <canvas
    baseChart
    height="150"
    [datasets]="chartData"
    [labels]="labels"
    [plugins]="barChartPlugins"
    [options]="barChartOptions"
    [legend]="true"
    chartType="line"
  ></canvas>
</div>

<table style="margin-bottom: 20px" *ngIf="dadosGrafico">
  <thead>
    <tr>
      <th>Mês</th>
      <th
        style="text-align: center"
        *ngFor="let item of dadosGrafico[0]?.series"
      >
        {{ formatarX(item.name) }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of dadosGrafico">
      <td>{{ item.name }}</td>
      <td style="text-align: center" *ngFor="let cota of item.series">
        {{ cota.value / 100 | percent : "1.2-4" }}
      </td>
    </tr>
  </tbody>
</table>
