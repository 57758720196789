import { Pipe, PipeTransform } from '@angular/core';
import { AnbimaService } from '../_services/anbima.service';

@Pipe({
  name: 'Asset'
})
export class AssetPipe implements PipeTransform {

  constructor(private anbimaService: AnbimaService){}

  transform(value: any, args?: any): any {
    return this.anbimaService.getAssetByCodAtivo(value, args);
  }

}
