import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { Moment } from "moment";
import { BehaviorSubject } from "rxjs";
import { delay } from "rxjs/operators";
import { Retorno } from "src/app/_models/carteira.model";
import { AuthService } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ClienteService } from "src/app/_services/cliente.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY",
  },
  display: {
    dateInput: "YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-relatorio-retornos-acumulado",
  templateUrl: "./relatorio-retornos-acumulado.component.html",
  styleUrls: ["./relatorio-retornos-acumulado.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RelatorioRetornosAcumuladoComponent implements OnInit {
  @Input()
  relatorioDinamico: Boolean = false;
  @Input()
  laminaResumo: Boolean = false;

  dataEntrada$ = new BehaviorSubject<string>(null);
  @Input() set dataEntrada(value: string) {
    this.dataEntrada$.next(value);
  }

  @Output()
  loadingStatus = new EventEmitter<boolean>();

  atual = moment();
  date = new FormControl(moment().year(2025));
  columnsToDisplay = [
    "mes",
    "saldoAnterior",
    "aplicacoes",
    "resgates",
    "saldoAtual",
    "retorno",
    "percentualRetorno",
    "meta",
    "atingimento",
  ];
  columnsToDisplayTotal = [
    "mes",
    "aplicacoes",
    "resgates",
    "retorno",
    "percentualRetorno",
    "meta",
    "atingimento",
  ];
  widthColumns = `${100 / this.columnsToDisplay.length}%`;
  registros: Retorno[] = [];
  registrosExibicao: Retorno[] = [];
  complementoTitulo: string = "";

  minDate: Date;
  maxDate: Date;
  idCliente: number;
  totais = [];
  zoom = new FormControl(1);
  mesesExibicao = new FormControl([]);
  mesesDisponiveis = [];

  isLoading: boolean = false;

  constructor(
    private carteiraService: CarteiraService,
    private clienteService: ClienteService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.idCliente = +route.snapshot.paramMap.get("idCliente");
  }

  ngOnInit() {
    this.minDate = new Date(2024, 0, 1, 0, 0, 0);
    this.maxDate = new Date(2025, 11, 31, 23, 59, 59);
    this.isLoading = true;
    if (!this.relatorioDinamico) {
      window.onafterprint = (e) => this.authService.willPrint$.next(false);
    } else {
      console.log("is dinamico");
      this.dataEntrada$.subscribe((value) => {
        console.log(value);
        const dt = moment(value, "DD/MM/YYYY");
        this.date.setValue(dt);
        this.retrieveRetornos(dt);
      });
    }
    if (!this.relatorioDinamico) {
      this.authService.willPrint$.pipe(delay(500)).subscribe((willPrint) => {
        if (willPrint) {
          window.print();
        }
      });
    }

    if (this.laminaResumo) {
      this.columnsToDisplay = [
        "mes",
        "percentualRetorno",
        "meta",
        "atingimento",
      ];

      this.widthColumns = `${100 / this.columnsToDisplay.length}%`;

      this.columnsToDisplayTotal = [
        "mes",
        "percentualRetorno",
        "meta",
        "atingimento",
      ];
    }

    if (this.idCliente) {
      this.clienteService.buscarClientePorId(this.idCliente).subscribe((c) => {
        this.complementoTitulo = ` - Cliente: ${c.nome}`;
      });
    }

    if (this.dataEntrada) {
      this.date.setValue(moment(this.dataEntrada));
      this.retrieveRetornos(moment(this.dataEntrada));
    }

    this.mesesExibicao.valueChanges.subscribe((v) => {
      this.registrosExibicao = this.registros.filter((r) =>
        v.includes(r.periodo) && v.saldoAtual !== 0
      );

      this.totais = [
        {
          totalSaldoAnterior: this.registrosExibicao.reduce(
            (acc, r) => acc + r.saldoAnterior,
            0
          ),
          totalSaldoAtual: this.registrosExibicao.reduce(
            (acc, r) => acc + r.saldoAtual,
            0
          ),
          totalResgates: this.registrosExibicao.reduce(
            (acc, r) => acc + r.resgates,
            0
          ),
          totalAplicacoes: this.registrosExibicao.reduce(
            (acc, r) => acc + r.aplicacoes,
            0
          ),

          totalRetornos: this.registrosExibicao.reduce(
            (acc, r) => acc + r.retorno,
            0
          ),

          totalRetornoPercentual:
            this.registrosExibicao.reduce(
              (acc, r) => acc * (r.retornoPercentual + 1),
              1
            ) - 1,

          totalRetornoPercentualCampo:
            this.cortarDuasCasasDecimais(this.registrosExibicao.reduce(
              (acc, r) => acc * (r.retornoPercentual + 1),
              1
            ) - 1),
          // totalMeta:
          //   this.registrosExibicao.reduce((acc, r) => acc * (r.meta + 1), 1) -
          //   1,
          totalMeta:
            this.registrosExibicao.reduce((acc, r) => acc * (r.meta + 1), 1) - 1
          ,
          totalMetaCampo: this.cortarDuasCasasDecimais(this.registrosExibicao.reduce((acc, r) => acc * (r.meta + 1), 1) - 1),
        },
      ];

      this.totais = this.totais.map((total) => ({
        ...total,
        // atingimento: total.totalRetornoPercentual / total.totalMeta,
        // atingimento: (total.totalMeta < 0) ? ((total.totalRetornoPercentual - total.totalMeta) / (total.totalMeta * -1)) : total.totalRetornoPercentual / total.totalMeta,
        atingimento: this.cortarDuasCasasDecimais((total.totalMeta < 0) ? ((total.totalRetornoPercentual - total.totalMeta) / (total.totalMeta * -1)) : total.totalRetornoPercentual / total.totalMeta),
      }));

      console.log(this.totais);
    });

    this.isLoading = false;
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.isLoading = true;
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
    datepicker.close();
    this.retrieveRetornos(ctrlValue);
    this.isLoading = false;
  }

  cortarDuasCasasDecimais(value) {
    var valor = value * 100;

    const valorStr = valor.toString();
    if (valorStr.includes('.')) {
      return valorStr.slice(0, valorStr.indexOf('.') + 3).replace('.', ',') + "%";
    }

    return valorStr + "%"; // Se não houver ponto decimal, retorna o número inteiro como está
  }

  retrieveRetornos(date: Moment) {
    this.loadingStatus.emit(false);
    this.carteiraService
      .relatorioRetornosAcumulado(this.idCliente, date)
      .subscribe((v) => {
        this.registros = v;
        this.mesesDisponiveis = this.registros.map((registro) => {
          return registro.periodo;
        });

        if (this.relatorioDinamico) {
          const mes = this.date.value.clone().endOf("month");

          console.log(this.mesesDisponiveis, mes);
          this.mesesDisponiveis = this.mesesDisponiveis.filter((c, i) => {
            return moment(c, "YYYY-MM-DD").isSameOrBefore(mes, "day");
          });

          console.log(this.mesesDisponiveis);
        }
        console.log("TESTE");

        this.mesesExibicao.patchValue([...this.mesesDisponiveis]);
        this.loadingStatus.emit(true);
      });
  }

  print() {
    this.authService.willPrint$.next(true);
  }

  toggleMeses() {
    if (this.mesesExibicao.value.length === 0) {
      this.mesesExibicao.patchValue([...this.mesesDisponiveis]);
    } else {
      this.mesesExibicao.patchValue([]);
    }
  }
}
