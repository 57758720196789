import { Component, OnInit } from "@angular/core";
import {
  FormBuilder, FormGroup,
  Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UIService } from 'src/app/_helpers/ui.service';
import { CicloVida, FluxosResgate } from 'src/app/_models/ciclo-vida.models';
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
@Component({
  selector: "app-simular-ciclo-vida",
  templateUrl: "./simular-ciclo-vida.component.html",
  styleUrls: ["./simular-ciclo-vida.component.scss"],
})
export class NovoCicloVidaComponent implements OnInit {
  TipoUsuario = TipoUsuario;
/*
  filtroFundo: BehaviorSubject<string>;
  fundos$: Observable<FIList[]>;
  fundoSelecionado: FIList;

  filtroCliente: BehaviorSubject<string>;
  clientes$: Observable<Cliente[]>;
  clienteSelecionado: Cliente;

  conjunto: FIList[];


  idConjunto: number;
*/

  columnsToDisplay = ['ano', 'saldoAtualizado'];

  fluxosResgate : FluxosResgate[];

  cicloVida:CicloVida;
  form: FormGroup;

  dados_calculados:boolean;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.form = fb.group({
      capitalInvestido: [0, Validators.required],
      retiradaAnualPrevista: [0, Validators.required],
      jurosReaisAnual: [0, Validators.required],

    });
  }

  ngOnInit() {

  }

  simularCicloVida(){

    this.dados_calculados = false;
    const obj = {...this.form.value};

    this.cicloVida = obj;

    const initValue = +obj.capitalInvestido;

    const juros = +obj.jurosReaisAnual;

    const retirada = +obj.retiradaAnualPrevista;

    let lastValue = initValue;
    let currValue = initValue;

    let fluxos =new  Array<FluxosResgate>();

    let anos = 0;
    while(currValue > 0 ){

      const jur = (lastValue * juros/100);
      currValue = (lastValue + jur);

      currValue -= retirada;

      lastValue = currValue;
      if(lastValue > 0){
        anos += 1;
        const flx = {
          ano : anos,
          saldoAtualizado: lastValue
        }
        fluxos.push(flx);
      }

    }


    this.cicloVida.tempoRealInvestimento=anos;
    this.fluxosResgate = fluxos;
    this.dados_calculados = true;


  }


}
