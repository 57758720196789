<h3>Enquadramentos do fundo</h3>
<form (ngSubmit)="submit()">
  <span *appHasRole="[tipoUsuario.USUARIO, tipoUsuario.ADMIN]">
    <app-autocomplete-enquadramento [control]="enquadramento"></app-autocomplete-enquadramento>
    <div class="buttons">
      <button mat-raised-button color="accent" type="submit" [disabled]="!enquadramento.valid"><mat-icon>save</mat-icon>Adicionar enquadramento ao fundo</button>
    </div>
  </span>
  <mat-list>
    <mat-list-item *ngFor="let enquadramento of enquadramentos$ | async; let i = index" class="mat-elevation-z1">
      <h3 matLine> <strong>{{enquadramento.legislacao}}</strong> ({{enquadramento.tipo}})</h3>
      <p matLine><b>Nome: </b> {{enquadramento.nome}}</p>
      <p matLine><b>Limite: </b> {{enquadramento.limite && (enquadramento.limite / 100) | percent:'1.2-4' }}</p>
      <p matLine *ngIf="enquadramento.tipo === 'RPPS'"><b>Limite participação: </b>{{enquadramento.limiteParticipacao && (enquadramento.limiteParticipacao / 100) | percent:'1.2-4' }}</p>
      <p matLine *ngIf="enquadramento.tipo === 'PRO-GESTAO'"><b>Limite participação: </b>{{enquadramento.limiteParticipacao && (enquadramento.limiteParticipacao / 100) | percent:'1.2-4' }}</p>
      <p matLine *ngIf="enquadramento.tipo === 'FP'"><b>Limite inferior: </b>{{enquadramento.limiteInferior && (enquadramento.limiteInferior / 100) | percent:'1.2-4' }}</p>
      <p matLine *ngIf="enquadramento.tipo === 'FP'"><b>Limite superior: </b>{{enquadramento.limiteSuperior && (enquadramento.limiteSuperior / 100) | percent:'1.2-4' }}</p>
      <button *appHasRole="[tipoUsuario.USUARIO, tipoUsuario.ADMIN]" type="button" mat-icon-button color="primary" (click)="remover(enquadramento)" matTooltip="Remover enquadramento"><i
        class="fas fa-trash"></i></button>
      </mat-list-item>
  </mat-list>
</form>
