import { Cliente } from "./cliente.model";
import { Usuario } from "./user.models";

export enum StatusAPR {
  Pendente = "Pendente",
  Finalizado = "Finalizado",
  Anulado = "Anulado",
  Negado = "Negado",
}

export enum FuncoesAPR {
  GESTOR = "Gestor",
  PROPONENTE = "Proponente",
  RESPONSAVEL = "Responsável",
}

export interface APR {
  id: number;
  nomeFundo: string;
  cnpjFundo: string;
  dataOperacao: Date;
  status: StatusAPR;

  clienteId: number;
  cliente: Cliente;

  conta: string;
  agencia: string;
  banco: string;

  documentId: string;
  operacao: string;
  valor: number;
  valorCota: number;
  descricao: string;
  motivo: string;

  proponenteId: number;
  proponente: APRSigner;

  gestorId: number;
  gestor: APRSigner;

  responsavelId: number;
  responsavel: APRSigner;

  movimentoId: number;
}

export interface APRSigner {
  id: number;
  nome: string;
  cpfCnpj: string;
  certificadora: string;
  validade: Date;
  funcao: string;
  idCliente: number;
  cliente: Cliente;
  adicionadoEm: Date;
  isGestor: boolean;
  isProponente: boolean;
  isResponsavel: boolean;
}

export interface APRHistory {
  idHistorico: number;
  idArp: number;
  arp: APR;
  statusAnterior: StatusAPR;
  status: StatusAPR;
  descricao: string;
  dataOperacao: Date;
  idUser: number;
  user: Usuario;
}
