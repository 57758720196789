
<div *ngIf="chartData" [class.widthmax]="relatorioDinamico">
  <canvas baseChart height="150"
      [datasets]="chartData"
      [labels]="labels"
      [legend]="true"
      chartType="line"
      [options]="ChartOptionsPercent"
      [plugins]="barChartPlugins"
    ></canvas>
  </div>
<table *ngIf="registros[0] && registros[0]?.series">
  <thead>
    <th></th>
    <th *ngFor="let mes of registros[0]?.series" style="text-align: center;">{{mes.name}}</th>
  </thead>
  <tbody>
    <tr *ngFor="let serie of registros">
      <td>{{serie.name}}</td>
      <td *ngFor="let item of serie.series" style="text-align: center;">
        {{(item.value / 100) | percent:'1.3-3'}}
      </td>
    </tr>
  </tbody>
</table>
<span style="display: block; page-break-after: always;" class="page-break"></span>
<h2>Grafico - Evolução do patrimônio</h2>

<!-- <ngx-charts-line-chart
  [results]="evolucaoPatrimonial"
  [view]="[1000, 600]"
  [xAxis]="true"
  [yAxis]="true"
  [tooltipDisabled]="relatorioDinamico"
  [scheme]="colorSchema"
  [showXAxisLabel]="true"
  [showYAxisLabel]="true"
  xAxisLabel="Mês"
  yAxisLabel="Patrimônio"
  legendTitle="Legenda">
</ngx-charts-line-chart> -->
<div *ngIf="chartDataEvolucao" [class.widthmax]="relatorioDinamico">
  <canvas baseChart height="150"
      [datasets]="chartDataEvolucao"
      [labels]="labels"
      [legend]="true"
      [options]="ChartOptionsMoney"
      [plugins]="barChartPlugins"
      chartType="line"
    ></canvas>
  </div>
<table style="font-size: 0.7em !important;">
  <thead>
    <th *ngFor="let mes of evolucaoPatrimonialRetono[0]?.series" style="text-align: center;font-size: 1.1em !important;">{{mes.name}}</th>
  </thead>
  <tbody>
    <tr *ngFor="let serie of evolucaoPatrimonialRetono">
      <td *ngFor="let item of serie.series" style="text-align: center;font-size: 1.1em !important;">
        {{(item.value) | currency:'BRL'}}
      </td>
    </tr>
  </tbody>
</table>
<span style="display: block; page-break-after: always;" class="page-break"></span>
<h2>Grafico - Retorno mensal</h2>
<div *ngIf="chartDataEvolucao" [class.widthmax]="relatorioDinamico">
  <canvas baseChart height="150"
      [datasets]="chartDataRetorno"
      [labels]="labels"
      [legend]="true"
      chartType="line"
      [options]="ChartOptionsMoney"
      [plugins]="barChartPlugins"
    ></canvas>
  </div>

<span class="page-break"></span>