import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { Usuario } from 'src/app/_models/user.models';
import { AuthService, TipoUsuario } from './../../_services/auth.service';

@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.scss']
})
export class PainelComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer: MatSidenav;

  anoAtual = moment().format("YYYY");

  show =  true;
  watcher: Subscription;
  over: Subject<Boolean> = new Subject<Boolean>(); // Subject para ocultar o menu ao clicar quando for xs ou sm
  opened: boolean;
  mode: string;
  usuario: Usuario;
  tipoUsuario = TipoUsuario;

  constructor(private media: MediaObserver,
              private router: Router,
              private authService: AuthService,
  ) { }

  ngAfterContentInit(){
    this.authService.usuario$.subscribe(usuario => this.usuario = usuario);
    this.watcher = this.media.asObservable().subscribe((change: MediaChange[]) => {
      if (change[0].mqAlias === 'sm' || change[0].mqAlias === 'xs') {
        this.opened = false;
        this.over.next(true);
        this.mode = 'over';
      } else {
        this.opened = true;
        this.over.next(false);
        this.mode = 'side';
      }
    });

    this.over.subscribe((isOver) => this.mode = isOver ? 'over' : 'side');

    this.router.events.pipe(
      filter((a) => this.mode === 'over' && a instanceof NavigationEnd)
    ).subscribe(_ => this.drawer ? this.drawer.close() : null);

    this.authService.authenticated$.pipe(
      withLatestFrom(this.authService.willPrint$),
      map(([a, b]) => a && !b)
    ).subscribe(
      (show) => this.show = show
    );

    this.authService.willPrint$.pipe(
      withLatestFrom(this.authService.authenticated$),
      map(([a, b]) => !a && b)
    ).subscribe(
      (show) => this.show = show
    );
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.watcher.unsubscribe();
    this.over.unsubscribe();
  }

  get proxied() {
    return this.authService.proxyUserId ? true : false;
  }

  get loggedIn() {
    return this.usuario != null;
  }

  logout() {
    this.authService.logout();
  }
}
