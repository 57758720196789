import { Directive, OnInit, ElementRef, HostListener, Input, TemplateRef, ViewContainerRef, EmbeddedViewRef } from '@angular/core';
import { BehaviorSubject, throwError, of } from 'rxjs';
import { debounceTime, switchMap, flatMap } from 'rxjs/operators';
import { FundoInvestimentoService } from '../_services/fundo-investimento.service';

@Directive({
  selector: '[appFundoInvestimentos]',
})
export class FundoDirective implements OnInit {
  @Input() cnpj: string;
  @Input() template: TemplateRef<any>;
  createdRef: EmbeddedViewRef<any>;
  hover: BehaviorSubject<boolean>;

  constructor(
    private elm: ElementRef,
    private viewContainer: ViewContainerRef,
    private fundoService: FundoInvestimentoService) { }

  ngOnInit() {
    this.cnpj = this.cnpj.replace(/[^\d\.\s]/gi, '').substr(0, 9);
    this.hover = new BehaviorSubject(false);
    this.hover.pipe(
      switchMap((show: boolean) => {
        if (show) {
          return this.fundoService.lista('cnpj', this.cnpj);
        } else {
          this.viewContainer.clear();
          return of([]);
        }
      }),
      flatMap(v => v),
      debounceTime(100),
    ).subscribe(value => {
      if (value) {
        this.createdRef = this.viewContainer.createEmbeddedView(this.template, value);
      }
    }, error => {
        this.viewContainer.clear();
    });
  }

  @HostListener('mouseenter') mouseover(event: Event) {
    this.hover.next(true);
  }
  @HostListener('mouseleave') mouseleave(event: Event) {
    this.hover.next(false);
  }
}

