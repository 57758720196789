import { Component, OnInit } from "@angular/core";
import {
  FormBuilder, FormGroup
} from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { DialogConfirmComponent } from 'src/app/_helpers/dialog-confirm/dialog-confirm.component';
import { UIService } from 'src/app/_helpers/ui.service';
import { Drei } from 'src/app/_models/drei.mode';
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { DreiQuery, DreiService } from 'src/app/_services/drei.service';
import { PaginatedDataSource } from 'src/app/_services/generic-datasource';
import { environment } from 'src/environments/environment';
@Component({
  selector: "app-drei",
  templateUrl: "./drei.component.html",
  styleUrls: ["./drei.component.scss"],
})
export class DreiComponent implements OnInit {
  TipoUsuario = TipoUsuario;


  datasource = new PaginatedDataSource<Drei, DreiQuery>(
    (request, query) => this.dreiService.listarDreiPaginado(request, query,this.idCliente),
    {property: 'mes', order: 'asc'},
    {search: ''},
    environment.LIMITE_REGISTROS_LISTA
  )


  idCliente:string;

  columnsToDisplay = ['mes', 'rentabilidadeAcumulada', 'dataAvaliacao', 'metaRentabilidade', 'inflacaoAcumulada', 'acoes'];

  form: FormGroup;

  dados_calculados:boolean;

  constructor(
    private dreiService:DreiService,
    private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.form = fb.group({

    });
  }

  ngOnInit() {

    this.idCliente = this.route.snapshot.paramMap.get("idCliente");
  }

  exportarXML(drei:Drei){

    this.dreiService.exportarXml(drei);

  }


  apagar(drei:Drei){
    this.dreiService.deleteDrei(drei).subscribe(  response => {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        width: '500px',
        data: {text: 'DREI excluído com sucesso!'}
      });
      dialogRef.afterClosed().subscribe(
        (result) => this.router.navigate(['drei'])
      );
    },
    error => {
      this.uiService.showSnackBar('Erro ao excluído o DREI', null, 4000);
    });
  }

}
