import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { isFunction } from 'util';
export interface DialogData {
  text: string;
  target: Observable<any>;
  closeOnComplete?: boolean;
  successMessage: string;
  errorMessage:any;
}

@Component({
  selector: 'app-dialog-loading',
  templateUrl: './dialog-loading.component.html',
  styleUrls: ['./dialog-loading.component.scss']
})
export class DialogLoadingComponent implements OnInit {

  success: boolean;
  responseData: any;

  constructor(
    public dialogRef: MatDialogRef<DialogLoadingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.data.target.subscribe(
      response => {
        this.responseData = response;
        this.success = true;
        if (this.data.closeOnComplete) {
           setTimeout(() => this.dialogRef.close(this.responseData), 3000);
        }
      },
      error => {
        if(isFunction(this.data.errorMessage)) {
          this.data.errorMessage = this.data.errorMessage(error)
        }
        this.success = false;
        this.responseData = false;
        if (this.data.closeOnComplete) {
           setTimeout(() => this.dialogRef.close(this.responseData), 10000);
        }
      }
    );
  }
}
