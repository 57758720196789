import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UIService } from 'src/app/_helpers/ui.service';
import { FIList } from 'src/app/_models/fundo-investimento.models';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';

export interface DialogData {
  idCliente: number;
}

@Component({
  selector: 'app-dialog-adicionar-fundo',
  templateUrl: './dialog-adicionar-fundo.component.html',
  styleUrls: ['./dialog-adicionar-fundo.component.scss']
})
export class DialogAdicionarFundoComponent implements OnInit {

  filtroFundo: BehaviorSubject<string>;
  fundos$: Observable<FIList[]>;
  fundoSelecionado: FIList;

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogAdicionarFundoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private uiService: UIService,
    private fundoInvestimentoService: FundoInvestimentoService,
    private fb: FormBuilder) {
      this.filtroFundo = new BehaviorSubject("");
      this.form = fb.group({
        cnpjFundo: ["", Validators.required],
        valorInvestido: ['', Validators.required],
      });
    }

  ngOnInit() {
    this.fundos$ = this.filtroFundo.pipe(
      switchMap((value) =>
        this.fundoInvestimentoService
          .filtroFundo(value)
          .pipe(catchError(() => of([])))
      )
    );
  }

  handleFiltro = (valor: string) => this.filtroFundo.next(valor);
  displayFundo = (fundo: FIList) =>
    fundo ? `${fundo.cnpj} - ${fundo.nome}` : "";

  selectFundo = (fundo: FIList) => {
    this.fundoSelecionado = fundo;
    console.log(fundo.cnpj)
    this.form.patchValue({ cnpjFundo: fundo.cnpj });
  };

  submit(){
    const obj = this.form.value;
    if(!obj.cnpjFundo || !obj.valorInvestido) {
      this.uiService.showSnackBar("Informe o fundo e valor", null, 3000);
      return
    }
    this.dialogRef.close(this.form.value);
  }

}
