<h1 class="title">
  Logar como usuário do cliente
</h1>

<table mat-table [dataSource]="datasource">
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef> Nome usuário </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Nome:</span>
      <a [routerLink]="['editar', element.idUser]">{{element.nome}}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Email:</span>
      <a [routerLink]="['editar', element.idUser]">{{element.eMail}}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="tipo">
    <th mat-header-cell *matHeaderCellDef> Tipo do usuário </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Tipo do usuário:</span>
      <a [routerLink]="['editar', element.idUser]">{{element.tipoUser | tipoUsuario}}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="acoes">
    <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Ações </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Ações:</span>
        <button mat-flat-button color="primary" (click)="changePassword(element)">Resetar Senha</button>
        <br/>
        <br/>
        <button mat-flat-button color="primary" (click)="logarComoProxy(element)">Logar como este usuário</button>
    </td>
  </ng-container>
</table>
