import { Action } from '@ngrx/store';
import * as UsuarioActions from '../actions/usuario.action';
import { Usuario } from '../_models/user.models';
// Section 1
const initialState: Partial<Usuario>  = {
};

// Section 2
export function reducer(state: Partial<Usuario> = initialState, action: UsuarioActions.Actions) {

    // Section 3
    switch (action.type) {
        case UsuarioActions.LOGIN:
            return action.payload;
        case UsuarioActions.LOGOUT:
            return {logo: state.logo}; // Salva apenas o logo
        default:
            return state;
    }
}