<div class="hidePrint" style="display: flex; flex-flow: row;">
<form style="flex:1" class="hidePrint">
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="dp"
      [max]="atual"
      placeholder="Mês/Ano"
      [formControl]="date"
      [min]="minDate"
      [max]="maxDate"
    />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
      #dp
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, dp)"
      panelClass="example-month-picker"

    >
    </mat-datepicker>
  </mat-form-field>
  <button
    class="hidePrint"
    (click)="print()"
    mat-flat-button
    color="primary"
  >
    Imprimir
  </button>
</form>

</div>

<h2>Medidas de risco</h2>
<br/>

<app-var-fundos-carteira  [investimentos]="investimentos" [dataEntrada]="data.format('DD/MM/YYYY')" ></app-var-fundos-carteira>
<br />
<h2>Risco de liquidez</h2>

<app-resumo-carteira [relatorioDinamico]="true"  [columnsToAdd]="['resgate']" [graficoLiquidez]="true" [dataEntrada]="data.format('DD/MM/YYYY')" ></app-resumo-carteira>
