import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';
import { exhaustMap } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidateCNPJ } from 'src/app/_validators/cpf_cnpj.validator';
import { BenchmarkService } from 'src/app/_services/benchmark.service';
import { UIService } from 'src/app/_helpers/ui.service';
import { BenchmarkList } from 'src/app/_models/benchmark.models';
import { FIList } from 'src/app/_models/fundo-investimento.models';

@Component({
  selector: 'app-alteracao-lote',
  templateUrl: './alteracao-lote.component.html',
  styleUrls: ['./alteracao-lote.component.scss']
})
export class AlteracaoLoteComponent implements OnInit, OnDestroy, AfterViewInit {

  fundoInvestimentos: FIList[];
  filtro = new Subject<string>();

  grupos = [
    {label: 'Administrador', field: 'administrador'},
    {label: 'Gestor', field: 'gestor'},
    {label: 'Custodiante', field: 'custodiante'},
    {label: 'Corretora', field: 'corretora'},
    {label: 'Consultoria', field: 'consultoria'},
    {label: 'Distribuidor', field: 'distribuidor'},
  ];

  benchmarks: BenchmarkList[];
  segmentos: string[];

  legislacaoPre2018: any[] = [];
  legislacao: any[] = [];

  form = new FormGroup({
    prazoResgate: new FormControl(null),
    segmento: new FormControl(null),
    benchmark: new FormControl(null),
    taxaBenchmark: new FormControl(null),
    taxaAdm: new FormControl(null),
    grauRisco: new FormControl(null, Validators.required),
    tipo1: new FormControl(null, Validators.required),
    tipo2: new FormControl(null, Validators.required),
    administrador: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    gestor: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    custodiante: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    corretora: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    consultoria: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    }),
    distribuidor: new FormGroup({
      nome: new FormControl(null),
      patrimonio: new FormControl(null),
      cnpj: new FormControl(null, [ValidateCNPJ]),
      dataInicio: new FormControl(null)
    })
  });

  constructor(
    private fundoInvestimentoService: FundoInvestimentoService,
    private benchmarkService: BenchmarkService,
    private uiService: UIService) { }

  ngOnInit() {
    this.filtro.pipe(
      exhaustMap(value => {
        let tipoFiltro = null;
        tipoFiltro = value.match(/[^\d\s]/gi) ? 'nome' : 'cnpj';
        if (tipoFiltro === 'cnpj') {
          value = value.replace(/[^\d\s]/gi, '');
        }
        return this.fundoInvestimentoService.lista(tipoFiltro, value);
      })
    ).subscribe(fundos => this.fundoInvestimentos = fundos);

    this.benchmarkService.listaBenchmark().subscribe(
      (response: BenchmarkList[]) => {
        this.benchmarks = response;
      },
      (error) => {
        this.uiService.showSnackBar('Erro carregando benchmarks', null, 3000);
      }
    );

    this.fundoInvestimentoService.getClassesCVM().subscribe(
      (response: object[]) => {
        this.segmentos = response.filter(c => c['classe']).map((c) => c['classe']);

        if ( !this.segmentos.includes('Fundo de Renda Variável') ) {
          this.segmentos.push('Fundo de Renda Variável');
        }
        this.segmentos = [...this.segmentos.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)];
      },
      (error) => {
        this.uiService.showSnackBar('Erro carregando segmentos', null, 3000);
      }
    );

    this.fundoInvestimentoService.getLegislacao(true).subscribe(r => {
      this.legislacaoPre2018 = r;
      console.log(r);
    });

    this.fundoInvestimentoService.getLegislacao(false).subscribe(r => {
      this.legislacao = r;
      console.log(r);
    });
  }

  ngAfterViewInit() {
    this.handleFilter('');
  }

  ngOnDestroy() {
    this.filtro.unsubscribe();
  }

  handleFilter(value: string) {
    this.filtro.next(value);
  }

}
