<div>
  <h3>Cadastro de Comentários Para o Novo Relatório</h3>
  <br />
  <form (ngSubmit)="submitArquivo()" [formGroup]="form" fxLayout="column">
    <mat-form-field>
      <input
        #nome
        matInput
        formControlName="titulo"
        placeholder="Título"
        required
        aria-label="Título do documento"
      />
      <mat-error *ngIf="!nome.valid">Informe o título do documento</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        #data
        matInput
        type="month"
        (change)="onChangeData($event)"
        formControlName="data"
        placeholder="Data"
        required
        aria-label="Data do documento"
      />
      <mat-error *ngIf="!data.valid">Informe a data do documento</mat-error>
    </mat-form-field>
    <!-- <mat-form-field>
      <input
        #tipo
        matInput
        formControlName="tipo"
        placeholder="Tipo"
        required
        aria-label="Tipo do documento"
      />
      <mat-error *ngIf="!nome.valid">Informe o tipo do documento</mat-error>
    </mat-form-field> -->
    <!-- <app-autocomplete-clientes [control]="cliente" label="Cliente">
    </app-autocomplete-clientes> -->
    <br />
    <input type="file" (change)="changeArquivo($event)" style="width: 100%" />
    <br />
    <button mat-flat-button color="primary">Enviar</button>
  </form>
</div>
