import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { UIService } from "src/app/_helpers/ui.service";
import { AudespRipp } from "src/app/_models/audesp.model";
import { AUDESPService } from "src/app/_services/audesp.service";
import { CarteiraService } from "src/app/_services/carteira.service";
export interface DialogData {
  idCliente: number;
  id: number;
  cnpj: string;
  periodo: Date;
}

@Component({
  selector: "app-dialog-ripp",
  templateUrl: "./dialog-ripp.component.html",
  styleUrls: ["./dialog-ripp.component.scss"],
})
export class DialogRIPPComponent implements OnInit {
  form: FormGroup;
  ripp: AudespRipp;
  isLoading: boolean = false;

  constructor(
    private dialog: MatDialogRef<DialogRIPPComponent>,
    private carteiraService: CarteiraService,
    private audespService: AUDESPService,
    private uiService: UIService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.form = fb.group({
      data_aplicacao_inicial: [""],
      patrimonio_liquido: [0],
      aplicacao_inicial: [0],
      qtd_cotas_inicial: [0],
      cota_inicial: [0],
      total_cotistas: [0],
      percentual_cmn: [0],
      percentual_investido: [0],
      percentual_autorizado: [0],
      total_resgates: [0],
      total_reaplicacao: [0],
      saldo: [0],
      numero_apr: [0],
      rendimento_periodo: [0],
      rendimento_total: [0],
      value_at_risk: [0],
      volatilidade: [0],
      fato_relevante: [""],
      provisionamento: [0],
      observacao: [""],
      incluir_xml: [3],
      type: [""],
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.audespService
      .consultarDadosAudesp(
        moment(this.data.periodo),
        this.data.id,
        this.data.idCliente
      )
      .subscribe((ripp) => {
        this.ripp = ripp;

        this.form.patchValue(this.ripp);
        this.isLoading = false;
      });
  }

  salvarDados() {
    const dados = {
      ...this.form.value,
      periodo: moment(this.data.periodo).format("YYYYMM"),
      cnpj_fundo: this.data.cnpj,
      idFundoCarteira: this.data.id,
    };

    this.audespService.salvarAudesp(dados).subscribe(
      (success) => {
        this.uiService.showSnackBar(
          "Dados registrados com sucesso",
          null,
          4000
        );
        this.dialog.close();
      },
      (erro) => {
        this.uiService.showSnackBar("Erro ao alterar os dados", null, 4000);
      }
    );
  }
}
