import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AnaliseRelatorio, Documento } from "src/app/_models/documentos.model";
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { ComentarioService } from "src/app/_services/comentario.service";
import {
  DocumentoQuery,
  DocumentoService,
} from "src/app/_services/documento.service";
import { PaginatedDataSource } from "src/app/_services/generic-datasource";
import { environment } from "src/environments/environment";
import { CriarComentarioNovoComponent } from "../Comentarios-novo/criar-comentario/criar-comentario-novo.component";
import { MatTableDataSource } from "@angular/material/table";
import { AddAnaliseRelatorioComponent } from "./add-analise-relatorio/add-analise-relatorio.component";
import { ViewAnaliseRelatorioComponent } from "./view-analise-relatorio/view-analise-relatorio.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-analise-relatorio",
  templateUrl: "./analise-relatorio.component.html",
  styleUrls: ["./analise-relatorio.component.scss"],
})
export class AnaliseRelatorioComponent implements OnInit {
  tipoUsuario = TipoUsuario;

  columns = ["desc", "data", "clientes", "acoes"];
  datasource = new MatTableDataSource<AnaliseRelatorio>();
  loading = false;

  constructor(
    private dialog: MatDialog,
    private comentarioService: ComentarioService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService._usuario.subscribe((user) => {
      if ([TipoUsuario.ADMIN, TipoUsuario.USUARIO].includes(user.tipoUser)) {
        this.columns = ["desc", "data", "clientes", "acoes"];
      }
    });

    this.getListaAnaliseRelatorio();
  }

  getListaAnaliseRelatorio() {
    this.loading = true;
    this.comentarioService.listarAnaliseRelatorio().subscribe((value: any) => {
      // console.log("value da consulta", value);
      this.datasource.data = value;
    });
    this.loading = false;
  }

  abrirDialogNovo() {
    const dialog = this.dialog.open(AddAnaliseRelatorioComponent, {
      width: "800px",
    });

    dialog.afterClosed().subscribe((result) => {
      this.getListaAnaliseRelatorio();
    });
  }

  OpenViewAnalisys(text: any) {
    const dialog = this.dialog.open(ViewAnaliseRelatorioComponent, {
      width: "800px",
      data: text,
    });
  }

  vincularClientes(idAnalise: number) {
    this.router.navigate(["analise-relatorio", idAnalise, "clientes"]);
  }

  apagarDocumento(idDocumento: number) {
    const del = confirm(
      "O item selecionado será deletado. Confirme a operação"
    );
    if (del) {
      this.comentarioService
        .deleteAnalise(idDocumento)
        .subscribe((value: any) => {
          this.getListaAnaliseRelatorio();
        });
    }
  }
}
