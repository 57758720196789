import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { APRService } from 'src/app/_services/apr.service';
import { AuthService } from 'src/app/_services/auth.service';
import { APRSigner, FuncoesAPR } from 'src/app/_models/arp.model';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ValidateCPFCNPJ } from 'src/app/_validators/cpf_cnpj.validator';
import { UIService } from 'src/app/_helpers/ui.service';
import { DialogDeleteComponent } from 'src/app/_helpers/dialog-delete/dialog-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonValidators, ArrayValidators } from 'ng-validator';

@Component({
  selector: 'app-assinadores',
  templateUrl: './assinadores.component.html',
  styleUrls: ['./assinadores.component.scss']
})
export class AssinadoresComponent implements OnInit {

  @Output() onAddAssinador: EventEmitter<APRSigner> = new EventEmitter();

  editando = false;
  registroEdicao: APRSigner;

  funcoesOptions = [
    FuncoesAPR.GESTOR,
    FuncoesAPR.PROPONENTE,
    FuncoesAPR.RESPONSAVEL
  ]

  assinadores: APRSigner[] = [];
  idCliente: number;
  fromRoute = false;

  form: FormGroup;
  formFuncoes: FormControl;

  constructor(
    private aprService: APRService,
    private authService: AuthService,
    public route: ActivatedRoute,
    private uiService: UIService,
    private fb: FormBuilder,
    private dialog: MatDialog) {
    this.form = fb.group({
      nome: ['', Validators.required],
      cpfCnpj: ['', [ValidateCPFCNPJ, Validators.required]],
      certificadora: '',
      validade: '',
      idCliente: ['', Validators.required],
      funcoes: [[], Validators.maxLength(2)],
      isGestor: false,
      isProponente: false,
      isResponsavel: false,
    })

  }

  ngOnInit() {
    this.authService.usuario$.subscribe(usuario => {
      if (!this.route.snapshot.paramMap.get('id')) {
        this.idCliente = usuario.idCliente;
      } else {
        this.fromRoute = true;
        this.idCliente = +this.route.snapshot.paramMap.get('id');
      }
      this.form.patchValue({ idCliente: this.idCliente });
      this.aprService.listaAssinadoresCliente(this.idCliente).subscribe(assinadores => {
        this.assinadores = assinadores;
      });
    });

    this.form.controls["funcoes"].valueChanges.subscribe(value => {
      this.form.patchValue({
        isGestor: value.includes(FuncoesAPR.GESTOR),
        isProponente: value.includes(FuncoesAPR.PROPONENTE),
        isResponsavel: value.includes(FuncoesAPR.RESPONSAVEL),
      })
    })
  }

  novoSigner(formValue: any) {
    const signer: APRSigner = formValue;
    signer.funcao = '';
    this.aprService.novoAssinador(signer).subscribe(signer => {
      if (this.onAddAssinador) {
        this.onAddAssinador.emit(signer);
      }
      this.assinadores.push(signer);
      this.form.patchValue({ idCliente: this.idCliente, funcoes: '', nome: '', cpfCnpj: '', certificadora: '', validade: '' })
    },
      error => {
        this.uiService.showSnackBar("Erro ao cadastrar assinador", "OK", 5000);
      });
  }

  updateSigner(formValue: any) {
    const signer: APRSigner = formValue;
    signer.funcao = '';
    this.aprService.atualizarAssinador(this.registroEdicao.id, signer).subscribe(signer => {
      let indexToReplace = this.assinadores.findIndex(assinador => assinador.id === this.registroEdicao.id);
      this.assinadores.splice(indexToReplace, 1, signer);
      // this.assinadores.push(signer);
      this.editando = false;
      this.registroEdicao = null;
      this.form.patchValue({ idCliente: this.idCliente, funcoes: '', nome: '', cpfCnpj: '', certificadora: '', validade: '' })
    },
      error => {
        this.uiService.showSnackBar("Erro ao cadastrar assinador", "OK", 5000);
      });
  }

  removerSigner(id: number) {
    this.dialog.open(DialogDeleteComponent, {
      data: {
        name: `o assinador selecionado`
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.aprService.removerAssinador(id).subscribe(() => {
          this.assinadores = this.assinadores.filter(s => s.id !== id);
        },
          error => {
            this.uiService.showSnackBar("Erro ao remover assinador: não é permitido exclusão de assinador vinculado em APR.", "OK", 5000);
          });
      }
    })
  }

  editSigner(signer: APRSigner) {
    this.editando = true;
    this.registroEdicao = signer;
    let funcoes = [];
    if (signer.isGestor) {
      funcoes.push(FuncoesAPR.GESTOR)
    }
    if (signer.isProponente) {
      funcoes.push(FuncoesAPR.PROPONENTE);
    }
    if (signer.isResponsavel) {
      funcoes.push(FuncoesAPR.RESPONSAVEL)
    }
    this.form.patchValue({ ...signer, funcoes });
  }

  cancelEdicao() {
    this.editando = false;
    this.registroEdicao = null;
    this.form.patchValue({
      nome: '',
      cpfCnpj: '',
      certificadora: '',
      validade: '',
      funcoes: [],
      isGestor: false,
      isProponente: false,
      isResponsavel: false,
    })
  }

}
