import { CarteiraService } from "src/app/_services/carteira.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  MedidasRisco,
  ProvisaoRisco,
  ProvisaoRiscoResumo,
  RiscoCredito,
  RiscoCreditoResumo,
  RiscoLiquidez,
  RiscoLiquidezResumo,
} from "./risco-novos.types";
import { MatTableDataSource } from "@angular/material/table";
import moment from "moment";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-riscos-novo",
  templateUrl: "./riscos-novo.component.html",
  styleUrls: ["./riscos-novo.component.scss"],
})
export class RiscosNovoComponent implements OnInit {
  atual = moment();
  date = new FormControl(moment().year(2025));
  minDate: string = '2024-01';
  maxDate: string = '2024-12';
  isLoading: boolean = false;

  idCliente: string;

  viewRelatorioMedicasRisco: boolean = false;
  medidasRisco: MedidasRisco[] = [];
  medidasRiscoResumo: MedidasRisco;
  dataSourceMedidasRisco: MatTableDataSource<MedidasRisco>;
  ColumnsMedidasRisco: string[] = [
    "cnpj",
    "nome",
    "benchmark",
    "sharpe",
    "var12",
    "varrs",
    "vol12",
  ];

  viewRelatorioProvisaoRisco: boolean = false;
  provisaoRisco: ProvisaoRisco[] = [];
  dataSourceProvisaoRisco: MatTableDataSource<ProvisaoRisco>;
  ColumnsProvisaoRisco: string[] = [
    "cnpj",
    "nome",
    "benchmark",
    "saldo",
    "var12",
    "varrs",
    "vol12",
  ];

  provisaoRiscoResumo: ProvisaoRiscoResumo[] = [];
  dataSourceProvisaoRiscoResumo: MatTableDataSource<ProvisaoRiscoResumo>;
  ColumnsProvisaoRiscoresumo: string[] = ["campo", "valor"];

  viewRelatorioRiscoLiquidez: boolean = false;
  riscoLiquidez: RiscoLiquidez[] = [];
  dataSourceRiscoLiquidez: MatTableDataSource<RiscoLiquidez>;
  ColumnsRiscoLiquidez: string[] = [
    "nome",
    "saldo",
    "participacao",
    "numCotistas",
    "percPl",
    "plFundo",
    "legislacao",
    "risco",
    "prazoResgate",
  ];

  viewRelatorioRiscoLiquidezResumo: boolean = false;
  RiscoLiquidezResumo: RiscoLiquidezResumo[] = [];
  dataSourceRiscoLiquidezResumo: MatTableDataSource<RiscoLiquidezResumo>;
  ColumnsRiscoLiquidezResumo: string[] = [
    "prazoResgate",
    "saldo",
    "NumFundo",
    "PercCarteira",
  ];

  viewRelatorioRiscoCredito: boolean = false;
  RiscoCredito: RiscoCredito[] = [];
  dataSourceRiscoCredito: MatTableDataSource<RiscoCredito>;
  ColumnsRiscoCredito: string[] = [
    "legislacao",
    "limite",
    "limitePolitica",
    "saldo",
    "percCarteira",
  ];

  RiscoCreditoResumo: RiscoCreditoResumo[] = [];
  dataSourceRiscoCreditoResumo: MatTableDataSource<RiscoCreditoResumo>;
  ColumnsRiscoCreditoresumo: string[] = ["tipo", "saldo", "percCarteira"];

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    hover: {
      mode: null,
    },
  };
  public barChartType: string = "bar";
  public barChartLegend: boolean = false;

  public riscoLiquidezLabels: string[] = [];
  public riscoLiquidezDados: any[] = [
    {
      data: [],
      label: "Saldo X Prazo Resgate",
      backgroundColor: [
        "#123E6B",
        "#EE8C31",
        "#44A960",
        "#C63434",
        "#6E3BAF",
        "#FFBF00",
        "#29A3AB",
        "#C14B8B",
      ],
      borderColor: ["#4F4F4F"],
      borderWidth: 1,
    },
  ];

  public riscoCreditoLabels: string[] = [];
  public riscoCreditoDados: any[] = [
    {
      data: [],
      label: "Saldo X Prazo Resgate",
      backgroundColor: [
        "#123E6B",
        "#EE8C31",
        "#44A960",
        "#C63434",
        "#6E3BAF",
        "#FFBF00",
        "#29A3AB",
        "#C14B8B",
      ],
      borderColor: ["#4F4F4F"],
      borderWidth: 1,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private CarteiraService: CarteiraService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.idCliente = params.get("idCliente");
    });
    this.getNovoMedidasRisco();
  }

  getNovoMedidasRisco() {
    this.isLoading = true; // Definimos como true antes de iniciar a requisição

    this.CarteiraService.getNovoMedidasRisco(
      Number(this.idCliente),
      moment(this.date.value).format("YYYY-MM")
    ).subscribe((data) => {
      this.medidasRisco = data.filter((x) => x.isTotal == false);
      this.formatProvRisco(this.medidasRisco);
      this.getProvisaoRiscoResumo(data);
      this.getRiscoLiquidez(data);
      this.getRiscoCreditoResumo(data);
      this.RiscoCreditoResumo = this.getRiscoCreditoResumo(data);
      this.dataSourceRiscoCreditoResumo =
        new MatTableDataSource<RiscoCreditoResumo>(this.RiscoCreditoResumo);
      this.medidasRiscoResumo = data.filter((x) => x.isTotal == true)[0];
      this.dataSourceMedidasRisco = new MatTableDataSource<MedidasRisco>(
        this.medidasRisco
      );
      this.RiscoCredito = this.getRiscoCredito(data);
      this.dataSourceRiscoCredito = new MatTableDataSource<RiscoCredito>(
        this.RiscoCredito
      );
      this.isLoading = false; // Definimos como false após a conclusão da requisição
    });
  }

  onChangeDataFiltro(newValue: string) {
    // console.log("Nova data selecionada:", newValue);
    this.getNovoMedidasRisco();
    // Adicione aqui a lógica para lidar com a mudança de data, se necessário
  }

  formatProvRisco(provRisco: any) {
    this.provisaoRisco = provRisco
      .filter((e) => e.ultimo == false)
      .map((e) => {
        return {
          cnpjFundo: e.cnpjFundo,
          nomeFundo: e.nomeFundo,
          benchmark: e.benchmark,
          sharpe: e.sharpe,
          saldo: e.saldo,
          var: e.var,
          varReais: e.varReais,
          vol3: e.vol3,
          vol6: e.vol6,
          vol12: e.vol12,
        };
      });
    this.dataSourceProvisaoRisco = new MatTableDataSource<ProvisaoRisco>(
      this.provisaoRisco
    );
  }

  getProvisaoRiscoResumo(medidas_risco: MedidasRisco[]) {
    // console.log("medidas_risco recebido na função", medidas_risco);
    this.provisaoRiscoResumo = [
      {
        label: "Provisão para perda e investimentos",
        value: medidas_risco.find((e) => e.ultimo == true).saldoDU,
        type: "currency",
      },
      {
        label: "% Provisão de Perda",
        value: medidas_risco.find((e) => e.ultimo == true).percentualPerda,
        type: "percent",
      },
      {
        label: "% Aceitável de Perda",
        value: medidas_risco.find((e) => e.ultimo == true).percentualAceitavel,
        type: "percent",
      },
      {
        label: "Limite de utilização",
        value: medidas_risco.find((e) => e.ultimo == true).limite,
        type: "percent",
      },
      {
        label: "Saldo Total",
        value: medidas_risco.find((e) => e.ultimo == true).saldo,
        type: "currency",
      },
    ];
    // console.log("provisaoRiscoResumo", this.provisaoRiscoResumo);
    this.dataSourceProvisaoRiscoResumo =
      new MatTableDataSource<ProvisaoRiscoResumo>(this.provisaoRiscoResumo);
  }

  getRiscoLiquidez(medidas_risco: MedidasRisco[]) {
    this.riscoLiquidez = medidas_risco
      .filter((e) => e.ultimo == false)
      .map((e) => {
        return {
          // cnpjFundo: e.cnpjFundo,
          nomeFundo: e.nomeFundo,
          saldo: e.saldo,
          participacao: e.participacao,
          numCotistas: e.numCotistas,
          percPl:
            e.saldo > 0 && e.patrimonioLiquido > 0
              ? +((e.saldo / e.patrimonioLiquido) * 100).toFixed(2)
              : 0,
          plFundo: e.patrimonioLiquido,
          legislacao: e.legislacao,
          risco: e.grauRisco,
          prazoResgate: e.prazoResgate,
        };
      });
    this.RiscoLiquidezResumo = this.getRiscoLiquidezResumo(
      this.riscoLiquidez,
      medidas_risco
    );
    this.dataSourceRiscoLiquidez = new MatTableDataSource<RiscoLiquidez>(
      this.riscoLiquidez
    );
    this.dataSourceRiscoLiquidezResumo =
      new MatTableDataSource<RiscoLiquidezResumo>(this.RiscoLiquidezResumo);
  }

  getRiscoLiquidezResumo(
    risco_liquidez: RiscoLiquidez[],
    medidas_risco: MedidasRisco[]
  ): RiscoLiquidezResumo[] {
    const agrupadoPorPrazoResgate = risco_liquidez.reduce((resultado, e) => {
      const { prazoResgate, saldo } = e;

      if (!resultado[prazoResgate]) {
        resultado[prazoResgate] = {
          prazoResgate,
          saldoTotal: 0,
          numFundos: 0,
          percentualCarteira: 0,
        };
      }

      resultado[prazoResgate].saldoTotal += saldo;
      resultado[prazoResgate].numFundos++;

      return resultado;
    }, {});

    // Calcular o percentual da carteira para cada prazoResgate
    for (const key in agrupadoPorPrazoResgate) {
      const grupo = agrupadoPorPrazoResgate[key];
      grupo.percentualCarteira = Math.round(
        (grupo.saldoTotal / medidas_risco.find((e) => e.ultimo == true).saldo) *
        100
      );
    }

    const resultadoOrdenadoLiquidez2: any = Object.values(
      agrupadoPorPrazoResgate
    ).sort((a: any, b: any) => {
      // Extrair os números de prazoResgate
      const numA = parseInt(a.prazoResgate.match(/\d+/)[0]);
      const numB = parseInt(b.prazoResgate.match(/\d+/)[0]);

      // Comparar os números de prazoResgate
      if (numA < numB) {
        return -1;
      } else if (numA > numB) {
        return 1;
      } else {
        // Se os números são iguais, comparar as strings completas para garantir a ordem correta
        return a.prazoResgate.localeCompare(b.prazoResgate);
      }
    });
    this.riscoLiquidezLabels = resultadoOrdenadoLiquidez2.map(
      (e) => e.prazoResgate
    );
    this.riscoLiquidezDados[0].data = resultadoOrdenadoLiquidez2.map(
      (e) => e.saldoTotal
    );

    return resultadoOrdenadoLiquidez2;
  }

  getRiscoCredito(medidas_risco: MedidasRisco[]) {
    const agrupadoPorLegislacao = medidas_risco
      .filter((e) => e.ultimo == false)
      .reduce((agrupado, fundo) => {
        const legislacao = fundo.legislacao;
        const saldo = fundo.saldo || 0; // Se o saldo for null, considerar 0
        const outroLimite = fundo.outroLimite || 0; // Se o limite for null, considerar 0
        const limitePolitica = fundo.limitePolitica || 0; // Se o limitePolitica for null, considerar 0

        let percentualSaldoTotal = 0;
        let outroLimitePerc = 0;
        let limitePoliticaPerc = 0;
        if (medidas_risco.find((e) => e.ultimo == true).saldo !== 0) {
          percentualSaldoTotal =
            (saldo / medidas_risco.find((e) => e.ultimo == true).saldo) * 100; // Calcular o percentual
        }

        if (!agrupado[legislacao]) {
          agrupado[legislacao] = {
            saldoTotal: 0,
            percentualSaldoTotalTotal: 0,
            outroLimite: 0,
            limitePoliticaTotal: 0,
          };
        }

        agrupado[legislacao].saldoTotal += saldo;
        agrupado[legislacao].percentualSaldoTotalTotal += percentualSaldoTotal;
        agrupado[legislacao].outroLimite = outroLimite;
        agrupado[legislacao].limitePoliticaTotal = limitePolitica;

        return agrupado;
      }, {});

    const resultadoAgrLegislacao = Object.entries(agrupadoPorLegislacao).map(
      ([legislacao, valores]: any) => ({
        legislacao,
        saldo: valores.saldoTotal,
        percentualSaldoTotal: valores.percentualSaldoTotalTotal.toFixed(2),
        outroLimite: valores.outroLimite,
        limitePolitica: valores.limitePoliticaTotal,
      })
    );

    let resultadoAgrLegislacaoFinal: any = [];
    resultadoAgrLegislacaoFinal.push(...Object.values(resultadoAgrLegislacao));

    return resultadoAgrLegislacaoFinal;
  }

  getRiscoCreditoResumo(medidas_risco: MedidasRisco[]) {
    let agrupamentoFinal = [];
    medidas_risco
      .filter((e) => e.ultimo == false)
      .forEach((item) => {
        if (
          item.legislacao.includes("Art. 7º, II") ||
          item.legislacao.includes('Art. 7º, III, "a"') ||
          item.legislacao.includes('Art. 7º, III, "b"') ||
          item.legislacao.includes("Art. 7º , IV")
        ) {
          agrupamentoFinal.push({
            ...item,
            agrupamento: "50% Títulos publicos e 50% Títulos privados",
            calculo: "50",
          });
          agrupamentoFinal.push({
            ...item,
            agrupamento: "100% Títulos publicos",
            calculo: "50",
          });
        } else if (
          item.legislacao.includes("Art. 7º, I") &&
          !item.legislacao.includes("Art. 7º, II") &&
          !item.legislacao.includes('Art. 7º, III, "a"') &&
          !item.legislacao.includes('Art. 7º, III, "b"') &&
          !item.legislacao.includes("Art. 7º , IV")
        ) {
          agrupamentoFinal.push({
            ...item,
            agrupamento: "100% Títulos publicos",
            calculo: "100",
          });
        } else {
          agrupamentoFinal.push({
            ...item,
            agrupamento: "100% Títulos privados",
            calculo: "100",
          });
        }
      });

    // Primeiro, agrupe os objetos pelo campo "agrupamento"
    const groupedData = agrupamentoFinal.reduce((result, item) => {
      if (!result[item.agrupamento]) {
        result[item.agrupamento] = {
          tipo: item.agrupamento,
          totalFundos: 0,
          percentual: 0,
          valorGrupo: 0,
        };
      }

      if (item.calculo == "50" || item.calculo == "100") {
        if (item.calculo == "50") {
          // result[item.agrupamento].saldo += item.saldo / 2;
          result[item.agrupamento].percentual += item.saldo / 2;
          result[item.agrupamento].totalFundos += 1;
          result[item.agrupamento].valorGrupo += item.saldo / 2;
        }
        if (item.calculo == "100") {
          result[item.agrupamento].percentual += item.saldo;
          result[item.agrupamento].totalFundos += 1;
          result[item.agrupamento].valorGrupo += item.saldo;
        }
      } else {
        result[item.agrupamento].valorGrupo += item.saldo;
      }

      // result[item.agrupamento].valorGrupo += item.saldo;
      return result;
    }, {});

    // Converter o objeto agrupado em um array
    let resultArray: any = Object.values(groupedData);
    resultArray = resultArray.map((e: any) => {
      e.percentual =
        (e.percentual / medidas_risco.find((e) => e.ultimo == true).saldo) *
        100;
      return e;
    });

    let agrupamentos = [
      "50% Títulos publicos e 50% Títulos privados",
      "100% Títulos publicos",
      "100% Títulos privados",
    ];

    agrupamentos.forEach((agrupamento) => {
      if (!resultArray.find((e: any) => e.tipo == agrupamento)) {
        resultArray.push({
          tipo: agrupamento,
          totalFundos: 0,
          percentual: 0,
          valorGrupo: 0,
        });
      }
    });

    let agrupamentoPublicoPrivadoFinal: RiscoCreditoResumo[] = [];

    agrupamentoPublicoPrivadoFinal.push(...resultArray);

    this.riscoCreditoLabels = resultArray.map((e) => e.tipo);
    this.riscoCreditoDados[0].data = resultArray.map((e) => e.totalFundos);

    return agrupamentoPublicoPrivadoFinal;
  }
}
