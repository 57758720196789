<div class="flex">
  <mat-card>
    <mat-card-header class="mat-card" style="background: #01579b; text-align: center;">
      <img [@fadeInOut] *ngIf="!logoCliente" src="../../../assets/imagens/logo.png"/>
      <img [@fadeInOut] *ngIf="logoCliente" style="object-fit: contain; max-width: 400px" [src]="'data:image/png;base64,'+ logoCliente" />
    </mat-card-header>
    <mat-card-content>
      <form (ngSubmit)="handleLogin(email.value, senha.value)">
        <mat-form-field>
          <input #email matInput autocomplete="username" (change)="carregarImagemCliente($event.target.value)" placeholder="Usuário" aria-label="Usuário" required >
          <mat-error *ngIf="!email.valid">Informe o Usuario</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput #senha name="senha" autocomplete="current-password" placeholder="Senha" [type]="hideSenha ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="hideSenha = !hideSenha">{{hideSenha ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="!senha.valid">Senha inválida</mat-error>
        </mat-form-field>
        <div>
          <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="isLoading"></mat-spinner>
          <a style="float: right; cursor: pointer" (click)="abrirDialogRecuperacaoSenha()">Esqueceu sua senha?</a>
          <span style="clear: both;"></span>
          <button mat-raised-button style="margin-top: 5px" color="primary"><mat-icon>input</mat-icon> Entrar</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
