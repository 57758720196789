<h1 class="title">Rentabilidade do fundo {{cnpj}}</h1>
<form>
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="dp"
      placeholder="Ano"
      [formControl]="date"
      min="2022-01-01"
    />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
      #dp
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event, dp)"
      panelClass="example-month-picker"
    >
    </mat-datepicker>
  </mat-form-field>

  <label class="hidePrint" style="margin-left: .5em">Zoom:</label>
  <mat-slider class="hidePrint" min="0.5" max="1" step="0.01" value="1" [formControl]="zoom" color="primary"></mat-slider>
  <button class="hidePrint" (click)="print()" mat-flat-button color="primary" style="float: right;">
    <i class="fas fa-print"></i>Imprimir
  </button>
</form>

<h2>Acumulado ano</h2>
<table [style.zoom]="zoom.value" mat-table [dataSource]="anoAtual">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  <ng-container matColumnDef="dataInicial">
    <th mat-header-cell *matHeaderCellDef>Data inicial</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Data inicial:</span>
        {{ retorno.dataInicial | date:'dd/MM/yyyy' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valorInicial">
    <th mat-header-cell *matHeaderCellDef>Valor cota inicial</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Valor cota inicial:</span>
        {{ (retorno.valorCotaInicial || 0) | currency:'BRL':'symbol':'1.2-6' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dataFinal">
    <th mat-header-cell *matHeaderCellDef>Data final</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Data final:</span>
        {{ retorno.dataFinal | date:'dd/MM/yyyy' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valorFinal">
    <th mat-header-cell *matHeaderCellDef>Valor cota final</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Valor cota final:</span>
        {{ (retorno.valorCotaFinal || 0) | currency:'BRL':'symbol':'1.2-6' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="percentualRetorno">
    <th mat-header-cell *matHeaderCellDef style="width: 9%">Rentabilidade acumulada %</th>
    <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Retorno %:</span>
      {{ retorno.rentabilidadeAcumulada | percent:'1.4-4' }}
    </td>
  </ng-container>
</table>

<h2>Rentabilidades acumuladas</h2>
<table [style.zoom]="zoom.value" *ngIf="rentabilidadePeriodo" mat-table [dataSource]="rentabilidadePeriodo ">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplayPeriodo"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplayPeriodo"></tr>
  <ng-container matColumnDef="periodo">
    <th mat-header-cell *matHeaderCellDef>Periodo</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Periodo:</span>
        {{ retorno.tipo }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dataInicial">
    <th mat-header-cell *matHeaderCellDef>Data inicial</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Data inicial:</span>
        {{ retorno.dataInicial | date:'dd/MM/yyyy' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valorInicial">
    <th mat-header-cell *matHeaderCellDef>Valor cota inicial</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Valor cota inicial:</span>
        {{ (retorno.valorCotaInicial || 0) | currency:'BRL':'symbol':'1.2-6' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dataFinal">
    <th mat-header-cell *matHeaderCellDef>Data final</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Data final:</span>
        {{ retorno.dataFinal | date:'dd/MM/yyyy' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valorFinal">
    <th mat-header-cell *matHeaderCellDef>Valor cota final</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Valor cota final:</span>
        {{ (retorno.valorCotaFinal || 0) | currency:'BRL':'symbol':'1.2-6' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="percentualRetorno">
    <th mat-header-cell *matHeaderCellDef style="width: 9%">Rentabilidade acumulada %</th>
    <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Retorno %:</span>
      {{ retorno.rentabilidadeAcumulada | percent:'1.4-4' }}
    </td>
  </ng-container>
</table>

<h2>Mês a Mês</h2>
<table [style.zoom]="zoom.value" mat-table [dataSource]="registrosAno">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  <ng-container matColumnDef="dataInicial">
    <th mat-header-cell *matHeaderCellDef>Data inicial</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Data inicial:</span>
        {{ retorno.dataInicial | date:'dd/MM/yyyy' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valorInicial">
    <th mat-header-cell *matHeaderCellDef>Valor cota inicial</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Valor cota inicial:</span>
        {{ (retorno.valorCotaInicial || 0) | currency:'BRL':'symbol':'1.2-6' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dataFinal">
    <th mat-header-cell *matHeaderCellDef>Data final</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Data final:</span>
        {{ retorno.dataFinal | date:'dd/MM/yyyy' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="valorFinal">
    <th mat-header-cell *matHeaderCellDef>Valor cota final</th>
    <td mat-cell *matCellDef="let retorno"> 
        <span class="mobile-label" style="width: 12%">Valor cota final:</span>
        {{ (retorno.valorCotaFinal || 0) | currency:'BRL':'symbol':'1.2-6' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="percentualRetorno">
    <th mat-header-cell *matHeaderCellDef style="width: 9%">Rentabilidade acumulada %</th>
    <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Retorno %:</span>
      {{ retorno.rentabilidadeAcumulada | percent:'1.4-4' }}
    </td>
  </ng-container>

</table>

<br/>