import { Component, OnInit, OnDestroy, AfterViewInit, AfterContentInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { FundoInvestimentoService } from 'src/app/_services/fundo-investimento.service';
import { switchMap, map, toArray } from 'rxjs/operators';
import { Subject, Observable, from, BehaviorSubject } from 'rxjs';
import { DialogAdicionarOtimizadorComponent } from './dialog-adicionar-otimizador/dialog-adicionar-otimizador.component';
import { OtimizadorService, InformacoesOtimizacao } from 'src/app/_services/otimizador.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import * as OtimizadorActions from './../../actions/otimizador.action';
import { ConjuntoService } from 'src/app/_services/conjunto.service';
import { UIService } from 'src/app/_helpers/ui.service';
import { InvestinentoOtimizador, FIList } from 'src/app/_models/fundo-investimento.models';
import { Conjunto } from 'src/app/_models/conjuntos.model';

@Component({
  selector: 'app-otimizador',
  templateUrl: './otimizador.component.html',
  styleUrls: ['./otimizador.component.scss']
})
export class OtimizadorComponent implements OnInit, OnDestroy, AfterViewInit {

  investimentos: Observable<FIList[]>;
  carteira: InvestinentoOtimizador[];
  filtro: Subject<Filtro>;
  transferindo: boolean;

  conjuntos$: Observable<Conjunto[]>;
  idConjuntoSelecionado: number;

  podeOtimizar: BehaviorSubject<boolean>;

  form = new FormGroup({
    database: new FormControl('', Validators.required),
    numMeses: new FormControl(12, Validators.required),
    passo: new FormControl(0.05, Validators.required),
    inicial: new FormControl(0.6 , Validators.required),
    final: new FormControl(1, Validators.required),
    limiteInferior: new FormControl(0),
    limiteSuperior: new FormControl(22),
  });

  constructor(
    public dialog: MatDialog,
    private fundoInvestimentoService: FundoInvestimentoService,
    private otimizadorService: OtimizadorService,
    private conjuntoService: ConjuntoService,
    private router: Router,
    private store: Store<AppState>,
    private uiService: UIService) {
      const dataAtual = new Date();
      dataAtual.setMonth(dataAtual.getMonth() - 1);
      this.form.patchValue({
        database: new DatePipe('pt-BR').transform(dataAtual, 'MM/yyyy')
      });
      this.podeOtimizar = new BehaviorSubject(false);
  }

  ngOnInit() {
    this.carteira = [];
    this.filtro = new Subject();

    this.investimentos = this.filtro.pipe(
      switchMap(value => {
        let tipoFiltro = null;
        tipoFiltro = value.filtro.match(/[^\d\.\s]/gi) ? 'nome' : 'cnpj';
        if (tipoFiltro === 'cnpj') {
          value.filtro = value.filtro.replace(/[^\d\.\s]/gi, '');
        }
        return this.fundoInvestimentoService.lista(tipoFiltro, value.filtro, value.idConjunto, true);
      }),
      map((fi) => {
        const itens_carteira = this.carteira.map(c => c.fundo.cnpj);
        return fi.filter(value => itens_carteira.indexOf(value.cnpj) < 0);
      }),
    );

    this.conjuntos$ = this.conjuntoService.getConjuntosDoCliente();
    this.store.select('otimizador').subscribe(
      state => {
        if (state) {
          this.form.patchValue(state);
          if (state.carteira) {
            this.carteira = state.carteira;
            this.podeOtimizar.next(this.carteira.reduce((a, b) => a + b.limiteInferior, 0) <= 100);
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.filtro.unsubscribe();
  }

  ngAfterViewInit() {
    this.handleFiltroChange('');
  }

  dropInvestimentos(event: CdkDragDrop<any[], any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      return;
    }
    const fundo = event.previousContainer.data[event.previousIndex].fundo;

    event.previousContainer.data.splice(event.previousIndex, 1);
    event.container.data.splice(event.currentIndex, 0, fundo);
    this.podeOtimizar.next(this.carteira.reduce((a, b) => a + b.limiteInferior, 0) <= 100);
  }

  dropCarteira(event: CdkDragDrop<any[], any[]>) {
    if (event.container === event.previousContainer) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      return;
    }
    const investimento = <InvestinentoOtimizador>{
      fundo: event.previousContainer.data[event.previousIndex]
    };
    const dialogRef = this.dialog.open(DialogAdicionarOtimizadorComponent, {
      width: '500px',
      data: {
        limiteInferior: this.form.get('limiteInferior').value,
        limiteSuperior: this.form.get('limiteSuperior').value,
      }
    });

    event.previousContainer.data.splice(event.previousIndex, 1);
    event.container.data.splice(event.currentIndex, 0, investimento);
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        event.container.data.splice(event.currentIndex, 1);
        event.previousContainer.data.splice(event.previousIndex, 0, investimento.fundo);
      } else {
        investimento.limiteInferior = result.limiteInferior;
        investimento.limiteSuperior = result.limiteSuperior;
      }
      this.podeOtimizar.next(this.carteira.reduce((a, b) => a + b.limiteInferior, 0) <= 100);
    });
  }

  adicionarTodos(itens: FIList[]) {
    if (this.form.value.limiteInferior < 0 || this.form.value.limiteSuperior > 100) {
      this.uiService.showSnackBar("Verifique os limites: inferior deve ser maior ou igual a 0, superior deve ser menor ou igual a 100", 'OK', 5000);
      return;
    }
    if (itens) {
      this.transferindo = true;
      const itens_carteira = this.carteira.map(c => c.fundo.cnpj);
      itens = itens.filter(i => !itens_carteira.includes(i.cnpj));
      from(itens).pipe(
        map(item => {
          const investimento = <InvestinentoOtimizador>{
            fundo: item,
            limiteInferior: this.form.get('limiteInferior').value,
            limiteSuperior: this.form.get('limiteSuperior').value
          };
          this.carteira.push(investimento);
          return true;
        }),
        toArray()
      ).subscribe(success => {
        itens = [];
        this.handleFiltroChange('');
        this.transferindo = false;
        this.podeOtimizar.next(this.carteira.reduce((a, b) => a + b.limiteInferior, 0) <= 100);
      });
    }
  }

  limparInvestimentos() {
    this.carteira = [];
    this.handleFiltroChange('');
    this.podeOtimizar.next(this.carteira.reduce((a, b) => a + b.limiteInferior, 0) <= 100);
  }

  otimizar(destino: string) {
    const obj = <InformacoesOtimizacao> {
      ...this.form.value,
      carteira: this.carteira
    };
    this.store.dispatch(new OtimizadorActions.Otimizar(obj));
    this.router.navigate(['otimizador', destino]);
  }

  handleFiltroChange(value: string) {
    this.filtro.next({ filtro: value, idConjunto: this.idConjuntoSelecionado});
  }

  changeConjunto = (idConjunto: number) => {
    this.idConjuntoSelecionado = idConjunto;
    this.handleFiltroChange('');
  }
}

export interface Filtro {
  filtro: string;
  idConjunto: number;
}
