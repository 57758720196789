import { Component, OnInit, Inject } from '@angular/core';
import { CarteiraService } from 'src/app/_services/carteira.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIService } from 'src/app/_helpers/ui.service';
import { APR } from 'src/app/_models/arp.model';
import { MovimentoCarteira, Carteira } from 'src/app/_models/carteira.model';

export interface DialogData {
  idCliente: number;
  movimento: MovimentoCarteira;
  investimento: Carteira;
}


@Component({
  selector: 'app-dialog-cadastro-apr',
  templateUrl: './dialog-cadastro-apr.component.html',
  styleUrls: ['./dialog-cadastro-apr.component.scss']
})
export class DialogCadastroAprComponent implements OnInit {

  constructor(
    private carteiraService: CarteiraService,
    public dialogRef: MatDialogRef<DialogCadastroAprComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private uiService: UIService) { }

  ngOnInit() {
  }

  closeDialog(result: any){
    if(result) {
      this.dialogRef.close(result)
    }
  }

}
