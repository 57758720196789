import { AbstractControl, ValidationErrors } from "@angular/forms"

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';

  if (!value) {
    return null
  }

  let letters = /[A-Z]+/gi
  if (letters.test(value) === false) {
    return { passwordStrength: `A senha deve possuir no mínimo 1 letra` };
  }


  let numberCharacters = /[0-9]+/g
  if (numberCharacters.test(value) === false) {
    return { passwordStrength: `A senha deve possuir no mínimo 1 número` };
  }

  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (specialCharacters.test(value) === false) {
    return { passwordStrength: `A senha deve possuir no mínimo 1 caractere especial (! @ # $ % & * ( ) + _ ....)` };
  }

  if (value.length < 6) {
    return { passwordStrength: `A senha deve possuir no mínimo 6 digitos` };
  }
  return null;
}