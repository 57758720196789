import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment.prod';
import { Indice } from '../_models/indices.models';

@Injectable({
  providedIn: 'root'
})
export class IndiceService {

  private indiceUrl = `${environment.PATH_API}/calculos/indices`;

  constructor(private http: HttpClient) { }

  buscarIndices(fundo: string, periodicidade: string, database: string, datafinal?: string) {
    let params = new HttpParams();
    params = params.append('periodicidade', periodicidade);
    params = params.append('database', database);
    if (datafinal) {
        params = params.append('datafinal', datafinal);
    }
    const url = `${this.indiceUrl}/${fundo}`;
    return this.http.get<Indice>(url, {params: params});
  }

  buscarParametro(nomeParametro: string) {
    const url = `${environment.PATH_API}/values/parametro/${nomeParametro}`
    return this.http.get(url);
  }

}
