import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { TipoUsuario } from 'src/app/_services/auth.service';
import { ResolucaoService } from 'src/app/_services/resolucao.service';
import { Enquadramento } from 'src/app/_models/resolucao.model';
import { Subject, Observable } from 'rxjs';
import { FIList } from 'src/app/_models/fundo-investimento.models';

export interface DialogData {
  fundo: FIList;
}

@Component({
  selector: 'app-adicionar-enquadramento-fundo',
  templateUrl: './adicionar-enquadramento-fundo.component.html',
  styleUrls: ['./adicionar-enquadramento-fundo.component.scss']
})
export class AdicionarEnquadramentoFundoComponent implements OnInit, OnDestroy {
  tipoUsuario = TipoUsuario

  enquadramento: FormControl;
  subject = new Subject();
  enquadramentos$ = this.resolucaoService.enquadramentosFundo(this.data.fundo.cnpj)

  constructor(
    public dialogRef: MatDialogRef<AdicionarEnquadramentoFundoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private resolucaoService: ResolucaoService
  ) {
    this.enquadramento = new FormControl(null, Validators.required)
  }

  ngOnInit() {
    this.subject.subscribe(c => {
      this.enquadramentos$ = this.resolucaoService.enquadramentosFundo(this.data.fundo.cnpj)
    });
    this.subject.next(null)
  }

  ngOnDestroy() {
    this.subject.complete()
  }

  submit (){
    const data: Enquadramento = this.enquadramento.value;
    this.resolucaoService.adicionarEnquadramento(data.id, this.data.fundo.cnpj).subscribe(c => {
      this.subject.next(data);
    })
    
  }

  remover (data: Enquadramento){
    this.resolucaoService.removerEnquadramento(data.id, this.data.fundo.cnpj).subscribe(c => {
      this.subject.next(data);
    })
  }

}
