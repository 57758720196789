<span *ngIf="!relatorioDinamico && !laminaResumo">
  <h1 class="title">Retorno: {{ complementoTitulo }}</h1>
  <form>
    <mat-form-field>
      <input matInput [matDatepicker]="dp" [max]="atual" placeholder="Mês/Ano" [formControl]="date" [min]="minDate"
        [max]="maxDate" />
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
      </mat-datepicker>
    </mat-form-field>
    <!-- <mat-form-field style="margin-left: 15px;margin-right: 15px">
    <mat-select formControlName="tipoFundo" placeholder="Tipo de Fundo"    class="hidePrint" (selectionChange)="chosenFundTypeHandler($event)">
      <mat-option value="Fundo de Investimento">Fundo de Investimento</mat-option>
      <mat-option value="Fundo Financeiro">Fundo Financeiro</mat-option>
      <mat-option value="Fundo Militar">Fundo Militar</mat-option>
      <mat-option value="Fundo Previdenciário">Fundo Previdenciário</mat-option>
      <mat-option value="Taxa de Administração">Taxa de Administração</mat-option>
      <mat-option value="Comprev">Comprev</mat-option>
      <mat-option value="Reserva Taxa">Reserva Taxa</mat-option>
      <mat-option value="IMSS">IMSS</mat-option>
      <mat-option value="PGA">PGA</mat-option>
      <mat-option value="PREVPLAN">PREVPLAN</mat-option>
    </mat-select>
  </mat-form-field> -->
    <label class="hidePrint" style="margin-left: 0.5em">Zoom:</label>
    <mat-slider class="hidePrint" min="0.5" max="1" step="0.01" value="1" [formControl]="zoom"
      color="primary"></mat-slider>
    <button class="hidePrint" (click)="print()" mat-flat-button color="primary" style="float: right">
      <i class="fas fa-print"></i>Imprimir
    </button>

    <button mat-flat-button color="accent" [disabled]="!registros.length" style="float: right; margin-right: 5px"
      [matMenuTriggerFor]="menuCadprev">
      <i class="fas fa-book-open"></i> Cadprev
    </button>
    <mat-menu #menuCadprev="matMenu">
      <button mat-menu-item (click)="abrirCadprev('resumo')">
        <i class="fas fa-file-pdf"></i>Resumo
      </button>
      <button mat-menu-item (click)="abrirCadprev('movimentos')">
        <i class="fas fa-file-excel"></i>Movimentos
      </button>
    </mat-menu>
  </form>
</span>

<div [hidden]="notLoading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<h2 *ngIf="laminaResumo" style="margin-bottom: 10px">Rentabilidade Média</h2>
<div *ngFor="let item of (registros | groupBy : 'grupo').sort(sortFn)">
  <h4>{{ item.key }}</h4>
  <table [style.zoom]="zoom.value" [class.laminaResumo]="laminaResumo" mat-table
    [dataSource]="item.value | sort : 'nomeFundo'">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    <ng-container matColumnDef="fundo">
      <th mat-header-cell *matHeaderCellDef style="width: 25%">Fundo</th>
      <td mat-cell *matCellDef="let retorno" style="padding-right: 1em">
        <span class="mobile-label">Fundo:</span>
        <span *ngIf="!laminaResumo">{{ retorno.cnpj }} - </span>{{ retorno.nomeFundo | resumoNomeFundo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="saldoAnterior">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Saldo anterior
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Saldo anterior:</span>
        {{ retorno.saldoAnterior | currency : "BRL" : "symbol" : "1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="aplicacoes">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Aplicações
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Aplicações:</span>
        {{ retorno.aplicacoes | currency : "BRL" : "symbol" : "1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="grupo">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Tipo
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Tipo:</span>
        {{ retorno.grupo }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="resgates">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Resgates
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Resgates:</span>
        {{ retorno.resgates | currency : "BRL" : "symbol" : "1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="saldoAtual">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Saldo Atual
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Saldo atual:</span>
        {{ retorno.saldoAtual | currency : "BRL" : "symbol" : "1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="retorno">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Retorno R$
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Retorno R$:</span>
        {{ retorno.retorno | currency : "BRL" : "symbol" : "1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="participacao">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Participação
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Participação: </span>
        {{ retorno.participacao | percent : "1.4-4" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="meta">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Meta
      </th>
      <td mat-cell *matCellDef="let retorno">
        {{ meta?.percentualMes / 100 || 0 | percent : "1.4-4" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="atingimento">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Atingimento
      </th>
      <td mat-cell *matCellDef="let retorno">
        {{
        (meta.percentualMes > 0
        ? retorno.efetivo / (meta.percentualMes / 100)
        : 0
        ) | percent : "1.4-4"
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="percentualRetorno">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Retorno %
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Retorno %:</span>
        {{
        (laminaResumo ? retorno.efetivo : retorno.retornoPercentual)
        | percent : "1.4-4"
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="efetivo">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Efetivo %
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Efetivo %:</span>
        {{ retorno.efetivo | percent : "1.4-4" }}
      </td>
    </ng-container>
  </table>
</div>
<span *ngIf="laminaResumo && registros?.length > 0">
  <br />
  <table [class.laminaResumo]="laminaResumo" mat-table [dataSource]="[1]">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    <ng-container matColumnDef="fundo">
      <th mat-header-cell *matHeaderCellDef style="width: 25%">Total</th>
      <td mat-cell *matCellDef="let retorno" style="padding-right: 1em">
        <span class="mobile-label">Total:</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="saldoAtual">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Saldo Atual
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Saldo atual:</span>
        {{ totalSaldoAtual | currency : "BRL" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="meta">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Meta
      </th>
      <td mat-cell *matCellDef="let retorno">
        {{ (meta?.percentualMes || 0) / 100 | percent : "1.4-4" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="atingimento">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Atingimento
      </th>
      <td mat-cell *matCellDef="let retorno">
        {{ (totalEfetivo / meta.percentualMes) * 100 | percent : "1.4-4" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="percentualRetorno">
      <th mat-header-cell *matHeaderCellDef [style.width]="widthColuna">
        Retorno %
      </th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Retorno %:</span>
        {{ totalEfetivo | percent : "1.4-4" }}
      </td>
    </ng-container>
  </table>
</span>

<br />
<h4 *ngIf="laminaResumo" style="margin-bottom: 10px">
  Total: R$ {{ totalSaldoAtual | currency : "BRL" }}
</h4>
<span *ngIf="laminaResumo" style="text-align: center">
  A rentabilidade média da carteira é de {{ totalEfetivo | percent : "1.4-4" }},
  enquanto que a meta atuarial foi de
  {{ (meta?.percentualMes || 0) / 100 | percent : "1.4-4" }}, sendo assim,
  atingindo
  {{ (totalEfetivo / meta.percentualMes) * 100 | percent : "1.4-4" }} da meta
  Atuarial
</span>
<br />
<div *ngIf="laminaResumo" class="page">
  <h2 style="display: block">Carteira Atual</h2>
  <div *ngIf="laminaResumo && pieChartData" style="width: 700px">
    <!-- <canvas
      baseChart
      width="700"
      height="850"
      [data]="pieChartData"
      [labels]="pieChartLabels"
      [options]="pieChartOptions"
      [plugins]="barChartPlugins"
      [chartType]="pie"
      [legend]="true"
    >
    </canvas> -->
    <ngx-charts-pie-chart *ngIf="laminaResumo" [view]="[700, 700]" [results]="graficoDistribuicaoFundos"
      [animations]="false" [labels]="true">
    </ngx-charts-pie-chart>
  </div>
</div>

<span *ngIf="!laminaResumo" style="display: block">
  <table [style.zoom]="zoom.value" mat-table [dataSource]="agrupados">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplayResumo"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplayResumo"></tr>
    <tr mat-footer-row *matFooterRowDef="columnsToDisplayResumo"></tr>

    <ng-container matColumnDef="fundo">
      <th mat-header-cell *matHeaderCellDef>Tipo</th>
      <td mat-cell *matCellDef="let retorno" style="width: 15%">
        {{ retorno.grupo }}
      </td>
      <td mat-footer-cell *matFooterCellDef>Totais</td>
    </ng-container>

    <ng-container matColumnDef="saldoAnterior">
      <th mat-header-cell *matHeaderCellDef>Saldo anterior</th>
      <td mat-cell *matCellDef="let retorno">
        {{ retorno.totalSaldoAnterior | currency : "BRL" : "symbol" : "1.2" }}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ totalSaldoAnterior | currency : "BRL" : "symbol" : "1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="aplicacoes">
      <th mat-header-cell *matHeaderCellDef>Total aplicações</th>
      <td mat-cell *matCellDef="let retorno">
        {{ retorno.totalAplicacoes | currency : "BRL" : "symbol" : "1.2" }}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ totalAplicacoes | currency : "BRL" : "symbol" : "1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="resgates">
      <th mat-header-cell *matHeaderCellDef>Total resgates</th>
      <td mat-cell *matCellDef="let retorno">
        {{ retorno.totalResgates | currency : "BRL" : "symbol" : "1.2" }}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ totalResgates | currency : "BRL" : "symbol" : "1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="saldoAtual">
      <th mat-header-cell *matHeaderCellDef>Saldo atual</th>
      <td mat-cell *matCellDef="let retorno">
        {{ retorno.totalSaldoAtual | currency : "BRL" : "symbol" : "1.2" }}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ totalSaldoAtual | currency : "BRL" : "symbol" : "1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="retorno">
      <th mat-header-cell *matHeaderCellDef>Total retorno</th>
      <td mat-cell *matCellDef="let retorno">
        {{ retorno.totalRetorno | currency : "BRL" : "symbol" : "1.2" }}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ totalRetorno | currency : "BRL" : "symbol" : "1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="percentualRetorno">
      <th mat-header-cell *matHeaderCellDef>Total retorno %</th>
      <td mat-cell *matCellDef="let retorno">
        {{ retorno.totalRetornoPercentual | percent : "1.4-4" }}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ totalRetornoPercentual | percent : "1.4-4" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="efetivo">
      <th mat-header-cell *matHeaderCellDef>Total retorno efetivo</th>
      <td mat-cell *matCellDef="let retorno">
        {{ retorno.totalEfetivo | percent : "1.4-4" }}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        {{ totalEfetivo | percent : "1.4-4" }}
      </td>
    </ng-container>
  </table>
</span>