import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { switchMap, catchError } from 'rxjs/operators';
import { ClienteService } from 'src/app/_services/cliente.service';
import { Cliente } from 'src/app/_models/cliente.model';

@Component({
  selector: 'app-autocomplete-clientes',
  templateUrl: './autocomplete-clientes.component.html',
  styleUrls: ['./autocomplete-clientes.component.scss']
})
export class AutocompleteClientesComponent implements OnInit {
  filtroCliente: BehaviorSubject<string>;
  clientes$: Observable<Cliente[]>;
  clienteSelecionado: Subject<Cliente>;

  @Input() control: FormControl;
  @Input("label") label: string;
  @Input("inline") inline: boolean = false;
  
  constructor(private clienteService: ClienteService) {
    this.filtroCliente = new BehaviorSubject('');
    this.clienteSelecionado = new Subject()
  }

  ngOnInit() {
    this.clientes$ = this.filtroCliente.pipe(
      switchMap(value => this.clienteService.listarCliente(value).pipe(
        catchError(() => of([]))
      )),
    );
  }

  ngAfterViewInit() {
    this.clienteSelecionado.subscribe(cliente => this.control.setValue(cliente));
  }

  handleFocus = () => this.filtroCliente.next('');

  handleFiltro = (valor: string) => {
    this.filtroCliente.next(valor);
  }
  displayFundo = (cliente: Cliente) => cliente ? `${cliente.nome} - ${cliente.cnpj}` : '';
  selectFundo = (cliente: Cliente) => this.clienteSelecionado.next(cliente);

  ngOnDestroy() {
    this.clienteSelecionado.complete()
  }


}
