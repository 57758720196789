import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { DialogConfirmComponent } from "src/app/_helpers/dialog-confirm/dialog-confirm.component";
import { UIService } from "src/app/_helpers/ui.service";
import {
  Credenciamento,
  FundosAderencia,
  Papel,
} from "src/app/_models/credenciamento.model";
import { Rating } from "src/app/_models/rating.model";
import { Usuario } from "src/app/_models/user.models";
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { CredenciamentoService } from "src/app/_services/credenciamento.service";
import { FundoInvestimentoService } from "src/app/_services/fundo-investimento.service";
@Component({
  selector: "app-cadastro-credenciamento",
  templateUrl: "./cadastro-credenciamento.component.html",
  styleUrls: ["./cadastro-credenciamento.component.scss"],
})
export class CadastroCredenciamentoComponent implements OnInit {
  TipoUsuario = TipoUsuario;

  form1: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  form4: FormGroup;

  papeis = new Array<Papel>();

  ratings = new Array<Rating>();

  datasource: FundosAderencia[];

  credenciamento: Credenciamento;

  resumoFundos = {
    total_fundos: 0,
    total_aderencia: 0,
    percentual: 0,
    aderencia: 0,
  };

  usuario: Usuario;

  isLoading = false;

  fundoInvestimento;

  papel;

  dados_credenciamento;

  columnsToDisplay = [
    "cnpjFundo",
    "nomeFundo",
    "benchmark",
    "grauRisco",
    "prazoAderencia",
    "percentualBenchmark",
    "percentualRentabilidade",
    "aderencia",
  ];

  constructor(
    private credenciamentoService: CredenciamentoService,
    private fundoInvestimentoService: FundoInvestimentoService,
    private fb: FormBuilder,
    private authService: AuthService,
    private uiService: UIService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.form1 = fb.group({ fundo: [""] });
    this.form2 = fb.group({
      nomeContato1: ["", Validators.required],
      emailContato1: ["", Validators.required],
      telefoneContato1: ["", Validators.required],
      nomeContato2: [""],
      emailContato2: [""],
      telefoneContato2: [""],
      constituinteIF: ["", Validators.required],
      dataEmissao: ["", Validators.required],
      atividade: ["", Validators.required],
      atoDeclaratorio: ["", Validators.required],
      numero: ["", Validators.required],
      dataExpedicao: ["", Validators.required],
      expedidoPor: ["", Validators.required],
      vencimentoFiscalMunicipal: ["", Validators.required],
      vencimentoFiscalEstadual: ["", Validators.required],
      vencimentoFiscalFederal: ["", Validators.required],
      vencimentoPrevidenciario: ["", Validators.required],
      classificacaoIF: ["", Validators.required],
      rating: ["", Validators.required],
      pontuacao: [0],
      patrimonioNacional: [0, Validators.required],
      patrimonioGlobal: [0, Validators.required],
      patrimonioRPPS: [0, Validators.required],
      idrating: [0],
    });
    this.form3 = fb.group({ penalidade: [0] });
    this.form4 = fb.group({
      rating: [0],
      tempo: [0],
      volume: [0],
      aderencia: [0],
      penalidade: [0],
      pontuacao: [0],
    });
  }

  ngOnInit() {
    this.credenciamentoService
      .getPapeis()
      .subscribe((response) => (this.papeis = response));

    this.credenciamentoService
      .getRatings()
      .subscribe((response) => (this.ratings = response));

    this.authService.usuario$.subscribe((usuario) => (this.usuario = usuario));
  }

  avancar_primeiro_passo() {
    this.isLoading = true;

    const obj = this.form1.value;

    this.papel = this.papeis.find((f) => f.seq === obj.fundo);

    this.credenciamentoService
      .getRetornoAcumulado(
        this.usuario.idCliente,
        this.papel.cnpjAdmin,
        this.papel.cnpjGestor,
        null
      )
      .subscribe((dados) => {
        let rets = new Array<FundosAderencia>();
        dados.map((d) => {
          d.grauRisco =
            d.grauRisco === "M"
              ? "Médio"
              : d.grauRisco === "A"
              ? "Alto"
              : "Baixo";
          d.prazoAderencia =
            d.prazo != null
              ? d.prazo === 0
                ? "Ano"
                : d.prazo.toString()
              : "24";
          rets.push(d);
        });
        this.datasource = rets;
      });

    this.isLoading = false;
  }

  avancar_segundo_passo() {
    this.isLoading = true;

    const obj = this.form2.value;

    this.dados_credenciamento = obj;

    if (this.dados_credenciamento.idrating > 0) {
      const rpontuacao = this.ratings.find(
        (r) => r.id === this.dados_credenciamento.idrating
      );

      this.dados_credenciamento.pontuacao = rpontuacao.pontuacao;
    } else {
      this.dados_credenciamento.pontuacao = 0;
    }

    const total = this.datasource.length;
    const aderentes = this.datasource.filter(
      (d) => d.aderencia === "SIM"
    ).length;
    const perc_adarencia = (aderentes * 100) / total;

    let calc_aderencia = 6;

    if (perc_adarencia <= 10) {
      calc_aderencia = 6;
    } else {
      if (perc_adarencia >= 11 && perc_adarencia <= 20) {
        calc_aderencia = 12;
      } else {
        if (perc_adarencia >= 21 && perc_adarencia <= 30) {
          calc_aderencia = 18;
        } else {
          if (perc_adarencia >= 31 && perc_adarencia <= 40) {
            calc_aderencia = 24;
          } else {
            if (perc_adarencia >= 41 && perc_adarencia <= 50) {
              calc_aderencia = 30;
            } else {
              if (perc_adarencia >= 51 && perc_adarencia <= 60) {
                calc_aderencia = 36;
              } else {
                if (perc_adarencia >= 61 && perc_adarencia <= 70) {
                  calc_aderencia = 42;
                } else {
                  if (perc_adarencia >= 71 && perc_adarencia <= 80) {
                    calc_aderencia = 48;
                  } else {
                    if (perc_adarencia >= 81 && perc_adarencia <= 90) {
                      calc_aderencia = 54;
                    } else {
                      if (perc_adarencia >= 91 && perc_adarencia <= 100) {
                        calc_aderencia = 60;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    this.resumoFundos = {
      total_fundos: total,
      total_aderencia: aderentes,
      percentual: perc_adarencia,
      aderencia: calc_aderencia,
    };

    this.isLoading = false;
  }

  avancar_ultimo_passo() {
    this.isLoading = true;

    const obj = this.form3.value;

    const rting = this.dados_credenciamento.pontuacao;
    let volume = 0;

    if (this.dados_credenciamento.patrimonioNacional <= 50) {
      volume = 0;
    } else {
      if (
        this.dados_credenciamento.patrimonioNacional > 50 &&
        this.dados_credenciamento.patrimonioNacional <= 250
      ) {
        volume = 2;
      } else {
        if (
          this.dados_credenciamento.patrimonioNacional > 250 &&
          this.dados_credenciamento.patrimonioNacional <= 500
        ) {
          volume = 4;
        } else {
          if (
            this.dados_credenciamento.patrimonioNacional > 500 &&
            this.dados_credenciamento.patrimonioNacional <= 1000
          ) {
            volume = 6;
          } else {
            if (
              this.dados_credenciamento.patrimonioNacional > 1000 &&
              this.dados_credenciamento.patrimonioNacional <= 2000
            ) {
              volume = 8;
            } else {
              volume = 10;
            }
          }
        }
      }
    }
    let tempo = 0;
    const anos =
      moment().year() - moment(this.dados_credenciamento.dataExpedicao).year();
    if (anos < 4) {
      tempo = 2;
    } else {
      if (anos >= 4 && anos < 6) {
        tempo = 4;
      } else {
        if (anos >= 6 && anos < 8) {
          tempo = 6;
        } else {
          if (anos >= 8 && anos < 10) {
            tempo = 8;
          } else {
            tempo = 10;
          }
        }
      }
    }

    const total =
      rting + volume + tempo + this.resumoFundos.aderencia - obj.penalidade;

    this.form4 = this.fb.group({
      rating: [rting],
      volume: [volume],
      tempo: [tempo],
      aderencia: [this.resumoFundos.aderencia],
      penalidade: [obj.penalidade],
      pontuacao: [total],
    });

    this.isLoading = false;
  }

  salvar() {
    this.isLoading = true;

    console.log(this.dados_credenciamento);

    const obj = this.form4.value;

    console.log(obj);

    const iqg = obj.rating + obj.volume + obj.tempo + obj.aderencia;

    let indice_iqg = "RP3";
    if (iqg >= 90) {
      indice_iqg = "RP1";
    } else {
      if (iqg >= 75 && iqg < 90) {
        indice_iqg = "RP2";
      } else {
        indice_iqg = "RP3";
      }
    }

    const credenciamento = {
      credenciamento: {
        nomeAdmin: this.papel.nomeAdmin,
        cnpjAdmin: this.papel.cnpjAdmin,
        nomeGestor: this.papel.nomeGestor,
        cnpjGEstor: this.papel.cnpjGestor,
        contato1: this.dados_credenciamento.nomeContato1,
        email1: this.dados_credenciamento.emailContato1,
        telefone1: this.dados_credenciamento.telefoneContato1,
        contato2: this.dados_credenciamento.nomeContato2,
        email2: this.dados_credenciamento.emailContato2,
        telefone2: this.dados_credenciamento.telefoneContato2,
        constituinte: this.dados_credenciamento.constituinteIF,
        dataEmissao: this.dados_credenciamento.dataEmissao,
        atividade: this.dados_credenciamento.atividade,
        atoDeclaratorio: this.dados_credenciamento.atoDeclaratorio,
        numero: this.dados_credenciamento.numero,
        dataExpedicao: this.dados_credenciamento.dataExpedicao,
        orgaoExpedidor: this.dados_credenciamento.expedidoPor,
        vencimentoMunicipal:
          this.dados_credenciamento.vencimentoFiscalMunicipal,
        vencimentoEstadual: this.dados_credenciamento.vencimentoFiscalEstadual,
        vencimentoFederal: this.dados_credenciamento.vencimentoFiscalFederal,
        vencimentoPrevidenciario:
          this.dados_credenciamento.vencimentoPrevidenciario,
        classificacao: this.dados_credenciamento.classificacaoIF,
        hasRating: this.dados_credenciamento.rating,
        patrimonioNacional: this.dados_credenciamento.patrimonioNacional,
        patrimonioGlobal: this.dados_credenciamento.patrimonioGlobal,
        patrimonioRPPS: this.dados_credenciamento.patrimonioRPPS,
        rating_qualidade: obj.rating,
        volume_recursos: obj.volume,
        tempo_atuacao: obj.tempo,
        avaliacao_aderencia: obj.aderencia,
        criterio_penalidade: obj.penalidade,
        pontuacao: obj.pontuacao,
        indice_qualidade_gestao: indice_iqg,
        clienteId: this.usuario.idCliente,
        id_rating: this.dados_credenciamento.idrating || null,
        tipo_credenciamento: "N",
      },
      fundos: this.datasource,
    };

    this.credenciamentoService.salvarCredenciamento(credenciamento).subscribe(
      (response) => {
        const obj = response;
        this.openNew(["/credenciamento", "impressao", obj.id]);
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          width: "500px",
          data: { text: "Credenciamento salvo com sucesso!" },
        });
        dialogRef
          .afterClosed()
          .subscribe((result) => this.router.navigate(["credenciamento"]));
      },
      (error) => {
        this.uiService.showSnackBar(
          "Erro ao salvar os dados do Credenciamento",
          null,
          4000
        );
      }
    );

    this.isLoading = false;
  }

  openNew(path) {
    const url = this.router.serializeUrl(this.router.createUrlTree(path));

    window.open(url, "_blank");
  }
}
