import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog, MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { Alert } from "selenium-webdriver";
import { DialogCadastroAprComponent } from "src/app/_components/APR/CadastroAPR/dialog-cadastro-apr/dialog-cadastro-apr.component";
import { UIService } from "src/app/_helpers/ui.service";
import { APR } from "src/app/_models/arp.model";
import { Carteira, MovimentoCarteira } from "src/app/_models/carteira.model";
import { Cliente } from "src/app/_models/cliente.model";
import { InformeFundo, InformeCorrigirCota } from "src/app/_models/fundo-investimento.models";
import { AuthService, TipoUsuario } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { environment } from "src/environments/environment";
import { DialogAjusteSaldoComponent } from "../dialog-ajuste-saldo/dialog-ajuste-saldo.component";

export interface DialogData {
  idCliente: number;
  cliente: Cliente;
  idInvestimento: number;
  investimento: Carteira;
}


@Component({
  selector: "app-visualizar-investimento",
  templateUrl: "./visualizar-investimento.component.html",
  styleUrls: ["./visualizar-investimento.component.scss"],
})

export class VisualizarInvestimentoComponent implements OnInit {
  tipoUsuario = TipoUsuario;

  showMessageNoQuote = false;
  movimentos: MovimentoCarteira[];
  form: FormGroup;
  formCorrigir: FormGroup;
  dadosSaldo = [];
  colorScheme = {
    domain: ["#01579B", "#fbc02d", "#c62828"],
  };

  editando = false;

  constructor(
    private carteiraService: CarteiraService,
    public dialog: MatDialog,
    private authService: AuthService,
    public dialogRef: MatDialogRef<VisualizarInvestimentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private uiService: UIService
  ) {
    this.form = fb.group({
      idMovimento: [null],
      dataInvestimento: [null, Validators.required],
      hora: ["10:00", Validators.required],
      valorInvestido: [{ value: null, disabled: false }, Validators.required],
      valorCota: [{ value: null, disabled: true }],
      numCotas: [{ value: null, disabled: true }],
      operacao: [null, Validators.required],
      gerarApr: [false],
    });

    this.formCorrigir = fb.group({
      dataCorrigir: [Date()],
    });
  }




  ngOnInit() {
    this.reloadMovimentos();
  }

  restrainWeekend(d: Date): boolean {
    if (d) {
      const day = d.getDay();
      // Prevent Saturday and Sunday from being selected.
      return day !== 0 && day !== 6;
    }
    return false
  }

  reloadMovimentos = () => {
    this.carteiraService
      .getMovimentosInvestimento(this.data.idCliente, this.data.idInvestimento)
      .subscribe((movimentos) => {
        this.movimentos = movimentos;
        const series = [...movimentos].reverse().map((m) => ({
          name: `${new DatePipe("pt-br").transform(
            m.dataMovimento,
            "dd/MM/yy HH:mm"
          )}`,
          value: m.saldo.saldoFinal,
        }));
        this.dadosSaldo = [{ name: "Saldo", series: series }];
      });
  };

  movimentar() {
    const obj: MovimentoCarteira = {
      dataMovimento: this.form.value.dataInvestimento,
      hora: this.form.value.hora,
      idFundoCarteira: this.data.idInvestimento,
      numCotas: this.form.value.numCotas,
      valor: this.form.value.valorInvestido,
      operacao: this.form.value.operacao,
    };

    if (this.form.value.valorCota && !obj.numCotas) {
      throw new Error("Cotas não informadas");
    }

    let observable;
    const operacao = this.form.value.operacao;
    if (operacao === "A") {
      observable = this.carteiraService.aplicacaoFundo(
        this.data.idInvestimento,
        obj
      );
    } else if (operacao === "R") {
      observable = this.carteiraService.resgateFundo(
        this.data.idInvestimento,
        obj
      );
    } else if (operacao === "M") {
      observable = this.carteiraService.amotizacaoFundo(
        this.data.idInvestimento,
        obj
      );

    } else {
      this.uiService.showSnackBar("Operação inválida", "Ok", 3000);
      return false;
    }

    this.uiService
      .abrirDialogLoading(
        observable,
        "Executando a movimentação...",
        "Movimentação realizada com sucesso!",
        "Erro, verifique os dados e o saldo e tente novamente!"
      )
      .subscribe((response) => {
        if (response) {
          if (this.form.get("gerarApr").value) {
            this.novaApr(response);
          } else {
            this.reloadMovimentos();
          }
          this.form.reset();
          this.form.patchValue({ hora: "10:00", gerarApr: false });
        }
      });
  }

  atualizarMovimento() {
    const obj: MovimentoCarteira = {
      dataMovimento: this.form.value.dataInvestimento,
      hora: this.form.value.hora,
      idFundoCarteira: this.data.idInvestimento,
      numCotas: this.form.value.numCotas,
      valor: this.form.value.valorInvestido,
      operacao: this.form.value.operacao,
    };

    let observable;
    observable = this.carteiraService.atualizacaoMovimento(
      this.data.idInvestimento,
      this.form.value.idMovimento,
      obj
    );

    this.uiService
      .abrirDialogLoading(
        observable,
        "Atualizando a movimentação...",
        "Movimentação atualizada com sucesso!",
        "Erro, verifique os dados e o saldo e tente novamente!"
      )
      .subscribe((response) => {
        if (response) {
          if (this.form.get("gerarApr").value) {
            this.novaApr(response);
          } else {
            this.reloadMovimentos();
          }
          this.form.reset();
          this.form.patchValue({ hora: "10:00", gerarApr: false });
          this.editando = false;
        }
      });
  }

  deletarMovimento() {
    let observable;
    observable = this.carteiraService.removerMovimento(
      this.data.idInvestimento,
      this.form.value.idMovimento
    );

    this.uiService
      .abrirDialogLoading(
        observable,
        "Removendo a movimentação...",
        "Movimentação removida com sucesso!",
        "Erro, verifique os dados e o saldo e tente novamente!"
      )
      .subscribe((response) => {
        if (response) {
          this.editando = false;
          this.reloadMovimentos();
          this.form.reset();
          this.form.setValue({ hora: "10:00", gerarApr: false });
          this.form.get("valorInvestido").disable();
          this.form.get("valorCota").setValue(null);
          this.form.get("valorCota").disable();
          this.form.get("numCotas").setValue(null);
          this.form.get("numCotas").disable();
        }
      });
  }

  atualizaValorCota = () => {
    const cnpj = this.data.investimento.cnpjFundo.replace(/\D/g, "");
    this.carteiraService
      .getValorCotaDia(cnpj, this.form.value.dataInvestimento)
      .subscribe(
        (informe: InformeFundo) => {
          this.showMessageNoQuote = false;
          this.form.get("valorInvestido").enable();
          this.form.get("valorCota").enable();
          this.form.get("numCotas").enable();
          this.form.patchValue({ valorCota: informe.valorQuota });
          this.changeValorInvestido();
        },
        (error) => {
          this.showMessageNoQuote = true;
          //this.form.get("valorInvestido").disable();
          this.form.get("valorCota").setValue(null);
          this.form.get("valorCota").disable();
          this.form.get("numCotas").setValue(null);
          this.form.get("numCotas").disable();
          this.uiService.showSnackBar(
            "Valor de cota inexistente ou não encontrado",
            "",
            3000
          );
        }
      );
  };

  corrigirCota = () => {
    const date = new DatePipe("pt-BR").transform(this.formCorrigir.value.dataCorrigir, "yyyy-MM-dd");

    const obj: InformeCorrigirCota = {
      cnpj: this.data.investimento.cnpjFundo,
      idFundoCarteira: this.data.idInvestimento,
      idCliente: this.data.idCliente,
      data: date
    };

    this.carteiraService.postCorrigirCota(obj)
      .subscribe(
        (corrigido) => {
          this.showMessageNoQuote = true;
          this.uiService.showSnackBar("Saldo Atualizado com Sucesso!", "", 3000);
          this.reloadMovimentos();
        }, (error) => {
          this.showMessageNoQuote = true;
          console.log("error", error);
          this.uiService.showSnackBar("Erro ao Atualizar Saldo", "", 3000);
        }
      );
  };

  changeValorInvestido = () => {
    const valorInvestido = this.form.value.valorInvestido;
    const valorCota = this.form.value.valorCota;
    this.form.patchValue({
      numCotas: this.carteiraService.calculaNumCotasPorValor(
        valorInvestido,
        valorCota
      ),
    });
  };

  loadMovimento = (movimento: MovimentoCarteira) => {
    this.editando = true;
    const obj = {
      idMovimento: movimento.idMovimento,
      dataInvestimento: movimento.dataMovimento,
      hora: new DatePipe("pt-BR").transform(movimento.dataMovimento, "HH:mm"),
      valorInvestido: movimento.valor,
      operacao: movimento.operacao,
    };
    this.form.patchValue(obj);
    this.atualizaValorCota();
    this.changeValorInvestido();
  };

  novaApr = (movimento: MovimentoCarteira) => {
    const data = {
      movimento,
      idCliente: this.data.idCliente,
      investimento: this.data.investimento,
    };

    const dialog = this.dialog.open(DialogCadastroAprComponent, {
      data,
      width: "70vw",
      height: "70vh",
    });

    dialog.afterClosed().subscribe((result: APR) => {
      if (result) {
        this.reloadMovimentos();
      }
    });
  };

  abrirApr = (idApr: number) => {
    const url = `${environment.PATH_API}/apr/ExportAPRPDF/${idApr}`;
    this.authService.openWindowWithAuth(url);
  };

  cancelarEdicao = () => {
    this.editando = false;
    this.form.reset();
    //this.form.get("valorInvestido").disable();
    this.form.get("valorCota").setValue(null);
    this.form.get("valorCota").disable();
    this.form.get("numCotas").setValue(null);
    this.form.get("numCotas").disable();
  };


  lancamentoAjusteSaldo = () => {
    const data = {
      investimento: this.data.investimento,
    };

    const dialog = this.dialog.open(DialogAjusteSaldoComponent, {
      data,
      width: '500px',
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadMovimentos();
      }
    });
  };
}
