import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { DialogDeleteComponent } from "src/app/_helpers/dialog-delete/dialog-delete.component";
import { UIService } from "src/app/_helpers/ui.service";
import { InformeFundo } from "src/app/_models/fundo-investimento.models";
import { FundoInvestimentoService } from "src/app/_services/fundo-investimento.service";
import { EditarPatrimonioComponent } from "./editar-patrimonio/editar-patrimonio.component";

@Component({
  selector: "app-informes",
  templateUrl: "./informes.component.html",
  styleUrls: ["./informes.component.scss"],
})
export class InformesComponent implements OnInit, OnDestroy {
  cnpj: string;
  origem: string;
  informes: InformeFundo[];
  columnsToDisplay = [
    "periodo",
    "data",
    "valor",
    "patrimonioLiquido",
    "numCotistas",
    "valorQuota",
  ];

  form: FormGroup;
  formFiltro: FormGroup;

  loading: BehaviorSubject<boolean>;

  constructor(
    private route: ActivatedRoute,
    private fundoInvestimentoService: FundoInvestimentoService,
    private fb: FormBuilder,
    private uiService: UIService,
    private dialog: MatDialog,
    private dialogEdit: MatDialog
  ) {
    this.cnpj = route.snapshot.paramMap.get("cnpj");
    this.fundoInvestimentoService.getOrigem(this.cnpj).subscribe((obj) => {
      this.origem = obj["origem"];
      if (this.origem === "S") {
        this.columnsToDisplay.push("remover");
      }
    });

    this.form = fb.group({
      cnpj: this.cnpj,
      dataCompetencia: [new Date(), Validators.required],
      valorTotal: null,
      valorQuota: null,
      valorPatrimonioLiquido: null,
      captacaoDia: null,
      resgateDia: null,
      numCotasTotal: null,
    });

    this.formFiltro = fb.group({
      inicio: null,
      fim: null,
    });

    this.loading = new BehaviorSubject(false);
  }

  ngOnInit() {
    this.atualizarLista();
  }

  abrirModal(data: any) {
    const dialogRef = this.dialog.open(EditarPatrimonioComponent, {
      data: {
        data: data,
        cnpj: this.cnpj,
        atualizarLista: this.atualizarLista,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Lógica após fechar o modal
      console.log("Resultado do modal:", result);
    });
  }

  atualizarLista(filtro?: boolean) {
    let inicio, fim: string;
    try {
      inicio = new DatePipe("pt-BR").transform(
        this.formFiltro.value.inicio,
        "yyyy-MM-dd"
      );
      fim = new DatePipe("pt-BR").transform(
        this.formFiltro.value.fim,
        "yyyy-MM-dd"
      );
    } catch (error) {}
    if (filtro && !(inicio && fim)) {
      this.uiService.showSnackBar("Informe o período completo", "OK", 4000);
      return;
    }
    this.loading.next(true);
    this.fundoInvestimentoService
      .listarInformes(this.cnpj, inicio, fim)
      .subscribe(
        (informes) => {
          this.informes = informes;
          this.loading.next(false);
        },
        (error) => {
          this.loading.next(true);
        }
      );
  }

  salvar() {
    const obj: InformeFundo = {
      ...this.form.value,
    };
    obj.periodo = new DatePipe("pt-BR").transform(
      obj.dataCompetencia,
      "yyyyMM"
    );
    const dialog = this.uiService.abrirDialogLoading(
      this.fundoInvestimentoService.salvarInforme(obj)
    );
    dialog.subscribe((response) => {
      if (response) {
        this.atualizarLista();
        this.form.patchValue({
          cnpj: this.cnpj,
          dataCompetencia: new Date(),
          valorTotal: null,
          valorQuota: null,
          valorPatrimonioLiquido: null,
          captacaoDia: null,
          resgateDia: null,
          numCotasTotal: null,
        });
      }
    });
  }

  removerInforme(informe: InformeFundo, i: number) {
    const dia = new DatePipe("pt-BR").transform(
      informe.dataCompetencia,
      "dd/MM/yyyy"
    );
    this.dialog
      .open(DialogDeleteComponent, {
        data: {
          name: `o informe do dia ${dia} `,
        },
      })
      .afterClosed()
      .subscribe((deletar) => {
        if (deletar) {
          this.fundoInvestimentoService
            .removerInforme(informe.id)
            .subscribe((response) => {
              this.atualizarLista();
            });
        }
      });
  }

  ngOnDestroy() {
    this.loading.unsubscribe();
  }
}
