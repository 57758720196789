<div class="hidePrint">
  <button mat-flat-button (click)="print()" color="primary" style="flex-grow: 0; align-self: center; justify-self: center;">
    Imprimir
  </button>
</div>

<div id="report" class="{{ credenciamento.tipo_credenciamento === 'S'?'report-simulacao':'' }}">
  <div class="page" style="justify-content: center; align-self: center;">
    <div style="text-align: center;" *ngIf="cliente">
    <img
    *ngIf="!cliente.foto"
    style="object-fit: contain;width:150px;"
    src="../../../assets/imagens/logo.png"
  />
  <img
    *ngIf="cliente.foto"
    style="object-fit: contain;width:150px;"
    [src]="'data:image/png;base64,' + cliente.foto"
  />
</div>
<h1 class="title" style="text-align:center">CREDENCIAMENTO</h1>

<br/>
<p>
  <span class="bold-label">RESPONSÁVEL:</span><span class="span-value">{{cliente.nome}}</span>&nbsp;
  <span class="bold-label">CNPJ DO RESPONSÁVEL:</span><span  class="span-value">{{cliente.cnpj}}</span>
</p>
<p>
  <span class="bold-label">INSTITUIÇÃO FINANCEIRA:</span><span class="span-value">{{credenciamento.nomeAdmin}}</span>&nbsp;
  <span class="bold-label">CNPJ :</span><span  class="span-value">{{credenciamento.cnpjAdmin}}</span>
</p>
<p>
  <span class="bold-label">GESTOR:</span><span class="span-value">{{credenciamento.nomeGestor}}</span>
  <span class="bold-label">CNPJ:</span><span class="span-value">{{credenciamento.cnpjGestor}}</span>
</p>
<p>
  <span class="span-value">{{credenciamento.data | date:'dd/MM/yyyy'}}</span>
</p>
<br/>
<h4 style="text-align: left;font-weight: normal;">DADOS PARA CONTATO</h4>
<div style="display: block;margin:0 auto; width:80vw;">
  <div class="sep-line">
    <div style="display: inline-block;width:40%;">
      <span class="span-value">{{credenciamento.contato1}}</span>
    </div>
    <div style="display: inline-block;width:40%;">
      <span class="span-value">{{credenciamento.email1}}</span>
    </div>
    <div style="display: inline-block;width:20%;">
      <span class="span-value">{{credenciamento.telefone1}}</span>
    </div>
  </div>
  <div class="sep-line">
    <div style="display: inline-block;width:40%;">
      <span class="bold-label">Nome para contato 1</span>
    </div>
    <div style="display: inline-block;width:40%;">
      <span class="bold-label">Email 1</span>
    </div>
    <div style="display: inline-block;width:20%;">
      <span class="bold-label">Telefone 1</span>
    </div>
  </div>
  <div class="sep-line">
    <div style="display: inline-block;width:40%;">
      <span class="span-value">{{credenciamento.contato2}}</span>
    </div>
    <div style="display: inline-block;width:40%;">
      <span class="span-value">{{credenciamento.email2}}</span>
    </div>
    <div style="display: inline-block;width:20%;">
      <span class="span-value">{{credenciamento.telefone2}}</span>
    </div>
  </div>
  <div class="sep-line">
    <div style="display: inline-block;width:40%;">
      <span class="bold-label">Nome para contato 2</span>
    </div>
    <div style="display: inline-block;width:40%;">
      <span class="bold-label">Email 2</span>
    </div>
    <div style="display: inline-block;width:20%;">
      <span class="bold-label">Telefone 2</span>
    </div>
</div>
</div>

<h4 style="text-align: left;font-weight: normal;">DECLARAÇÃO</h4>

<article style="text-align: justify;margin: 7px;">
  Instituição financeira constituída conforme <span class="bold-label">{{credenciamento.constituinte}}</span> , e alterações posteriores, emitido em
  <span class="bold-label">{{credenciamento.dataEmissao | date:'dd/MM/yyyy'}}</span> . Entidade autorizada a exercer a atividade de <span class="bold-label">{{credenciamento.atividade}}</span>, conforme <span class="bold-label">{{credenciamento.atoDeclaratorio}}</span> número <span class="bold-label">{{credenciamento.numero}}</span> expedido em <span class="bold-label">{{credenciamento.dataExpedicao | date:'dd/MM/yyyy'}}</span> pelo(a) <span class="bold-label">{{credenciamento.orgaoExpedidor}}</span>.
</article>
<br/>
<h4 style="text-align: left;font-weight: normal;">DOCUMENTAÇÃO COMPROBATÓRIA - ATESTADOS DE REGULARIDADE</h4>
<br/>
<p>
  <span class="bold-label">Fiscal Municipal (Venc):</span><span  class="span-value">{{credenciamento.vencimentoMunicipal | date:'dd/MM/yyyy'}}</span>
</p>
<p>
  <span class="bold-label">Fiscal Estadual (Venc):</span><span  class="span-value">{{credenciamento.vencimentoEstadual | date:'dd/MM/yyyy'}}</span>
</p>
<p>
  <span class="bold-label">Fiscal Federal (Venc):</span><span  class="span-value">{{credenciamento.vencimentoFederal | date:'dd/MM/yyyy'}}</span>
</p>
<p>
  <span class="bold-label">Previdenciária (Venc):</span><span  class="span-value">{{credenciamento.vencimentoPrevidenciario | date:'dd/MM/yyyy'}}</span>
</p>
<br/>
<h4 style="text-align: left;font-weight: normal;">CLASSIFICAÇÃO DA INSTITUIÇÃO FINANCEIRA: {{credenciamento.classificacao}}</h4>
<br/>
<h4 style="text-align: left;font-weight: normal;" *ngIf="rating">RATING DE GESTÃO: {{rating.agencia}} | {{rating.rating}} | {{rating.pontuacao}}%</h4>
<br/>
<h4 style="text-align: left;font-weight: normal;">PATRIMÔNIO SOB GESTÃO</h4>
<p>
  <span class="bold-label">Nacional (R$):</span><span  class="span-value">{{credenciamento.patrimonioNacional | currency:'BRL'}}</span>
</p>
<p>
  <span class="bold-label">Global (R$):</span><span  class="span-value">{{credenciamento.patrimonioGlobal | currency:'BRL'}}</span>
</p>
<p class="break-page">
  <span class="bold-label">RPPS (R$):</span><span  class="span-value">{{credenciamento.patrimonioRPPS | currency:'BRL'}}</span>
</p>
<br/>
<h4 style="text-align: left;font-weight: normal;" class="break-page-bf">BENCHMARK</h4>
<br/>
<table mat-table [dataSource]="datasource" >

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay;" ></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay" ></tr>

  <ng-container matColumnDef="cnpjFundo" >
    <th mat-header-cell *matHeaderCellDef >CNPJ</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">CNPJ:</span>
      {{element.cnpjFundo}}
    </td>
  </ng-container>

  <ng-container matColumnDef="nomeFundo">
    <th mat-header-cell *matHeaderCellDef>Nome</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Nome:</span>
      {{element.nomeFundo}}
    </td>
  </ng-container>


  <ng-container matColumnDef="benchmark">
    <th mat-header-cell *matHeaderCellDef>Benchmark</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Benchmark:</span>
      {{element.benchmark}}
    </td>
  </ng-container>

  <ng-container matColumnDef="grauRisco">
    <th mat-header-cell *matHeaderCellDef>Risco</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Risco:</span>
      {{element.grauRisco}}
    </td>
  </ng-container>


  <ng-container matColumnDef="prazo">
    <th mat-header-cell *matHeaderCellDef>Prazo</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Prazo:</span>
      {{element.prazo ? element.prazo === 0?  'Ano' : element.prazo : 24}}
    </td>
  </ng-container>



  <ng-container matColumnDef="percentualBenchmark">
    <th mat-header-cell *matHeaderCellDef>Benchmark (%)</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Benchmark (%):</span>
      {{element.percentualBenchmark  }} %
    </td>
  </ng-container>


  <ng-container matColumnDef="percentualRentabilidade">
    <th mat-header-cell *matHeaderCellDef>Rentabilidade (%)</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Rentabilidade (%):</span>
      {{element.percentualRentabilidade  }} %
    </td>
  </ng-container>


  <ng-container matColumnDef="aderencia">
    <th mat-header-cell *matHeaderCellDef>AD</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">AD:</span>
      {{element.aderencia}}
    </td>
  </ng-container>


</table>
<br/>
<h4 style="text-align: left;font-weight: normal;" class="break-page-pontuation">PONTUAÇÃO TÉCNICA</h4>
<br/>
<span style="text-align: left;">
  Observado o disposto na Portaria MTP n° 1.467/2022, Seção III, Credenciamento de instituições e, 
cumpridos os quesitos de análise estabelecidos pelo procedimento de seleção de entidades autorizadas e credenciadas do RPPS, o (a)
  <span class="bold-label" *ngIf="credenciamento.classificacao == 'gestor'">{{credenciamento.nomeGestor}}</span>
  <span class="bold-label" *ngIf="credenciamento.classificacao == 'administrador'">{{credenciamento.nomeAdmin}}</span>
  <span class="bold-label" *ngIf="credenciamento.classificacao =='administrador_gestor'">{{credenciamento.nomeAdmin}}</span>
  apresenta a seguinte
  pontuação:
</span>
<br/>

<table >
  <tr>
    <td style="width:80%">Rating de Gestão de Qualidade (0 a 20%)</td>
    <td>{{credenciamento.rating_qualidade}}%</td>
  </tr>
  <tr>
    <td style="width:80%">Volumes de Recursos Administrados (2 a 10%)</td>
    <td>{{credenciamento.volume_recursos}}%</td>
  </tr>
  <tr>
    <td style="width:80%">Tempo de atuação de Mercado (2 a 10%)</td>
    <td>{{credenciamento.tempo_atuacao}}%</td>
  </tr>
  <tr>
    <td style="width:80%">Avaliação de Aderência dos Fundos (0 a 60%)</td>
    <td>{{credenciamento.avaliacao_aderencia}}%</td>
  </tr>

  <tr>
    <td style="width:80%">Menos critério de penalidade (0 a 10%)</td>
    <td>{{credenciamento.criterio_penalidade}}%</td>
  </tr>

  <tr>
    <td style="width:80%">Pontuação - Quesitos técnicos</td>
    <td>{{credenciamento.pontuacao}}%</td>
  </tr>

  <tr>
    <td style="width:80%">Índice de Qualidade e Gestão</td>
    <td>{{credenciamento.indice_qualidade_gestao}}</td>
  </tr>
</table>


<h4 style="text-align: center;">{{credenciamento.indice_qualidade_gestao}}</h4>
<br/>
<br/>
<p class="break-page">
  <span class="bold-label">Limite de Alocação:</span>
  <span  class="span-value" *ngIf="credenciamento.indice_qualidade_gestao == 'RP3'">Sem limite para aplicação</span>
  <span  class="span-value" *ngIf="credenciamento.indice_qualidade_gestao == 'RP2'">Inferior ao da Resolução CMN 4963/21 e Política de Investimentos, pois há ressalva
    técnica que merece avaliação mais criteriosa.</span>
  <!-- <span  class="span-value" *ngIf="credenciamento.indice_qualidade_gestao == 'RP2'">Inferior ao da Resolução CMN 3922/10 e Política de Investimentos, pois há ressalva
    técnica que merece avaliação mais criteriosa.</span> -->
  <!-- <span  class="span-value" *ngIf="credenciamento.indice_qualidade_gestao == 'RP1'">Da Resolução 3.922/10 e da Política de Investimento</span> -->
  <span  class="span-value" *ngIf="credenciamento.indice_qualidade_gestao == 'RP1'">Da Resolução 4963/21 e da Política de Investimento</span>
</p>

<p >
  <span class="bold-label">Descrição:</span>
  <span  class="span-value" *ngIf="credenciamento.indice_qualidade_gestao == 'RP3'">Atuação cujos aspectos relacionados com histórico de risco e retorno, estrutura e
    credibilidade da instituição administradora e gestora, processos de investimento e de controles
    internos, assim como equipe profissional, podem ser considerados, no máximo, razoáveis. No geral, a
    administração e gestão dos fundos classificados neste nível não garantem, em sua plenitude, o
    cumprimento do dever fiduciário.</span>
  <span  class="span-value" *ngIf="credenciamento.indice_qualidade_gestao == 'RP2'">As instituições administradoras e gestoras classificadas neste nível apresentam histórico de
    risco e retorno. São consideradas instituições com credibilidade, tradição em gestão e boa estrutura
    organizacional. Contam com controle de processos de investimento e de análise de risco, equipes com
    formação profissional qualificada e experiente, com baixo índice de rotatividade. Apresentam, ainda,
    ambiente de controle interno capaz de garantir disponibilidade, integridade, tempestividade e
    rastreabilidade das informaçfies. No geral, as instituições classificadas neste nível são capazes de
    assegurar o cumprimento do dever fiduciário em sua plenitude. Contudo, há evento que merece
    avaliação mais seletiva para o período analisado.</span>
  <span  class="span-value" ng-if="credenciamento.indice_qualidade_gestao == 'RP1'">As instituições administradoras e gestoras classificadas neste nível apresentam histórico
    consistente de risco e retorno. São consideradas instituições com excelente credibilidade, tradição em
    gestão e sólida estrutura organizacional. Contam com eficazes e seguros processos de investimento e
    de análise de risco, equipes com formação profissional altamente qualificada, elevada experiência e
    baixa rotatividade. Apresentam, ainda, ambiente de controle interno seguro, capaz de garantir total
    disponibilidade, integridade, tempestividade e rastreabilidade das informaçfies. No geral, as instituições
    classificadas neste nível asseguram o cumprimento do dever fiduciário em sua plenitude.</span>
</p>

<br/>
<table >
  <tr style="background-color: white;">
    <td >Responsável pela análise</td>
    <td>Cargo</td>
    <td>CPF</td>
    <td >Assinatura</td>
  </tr>
  <tr style="background-color: white;">
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
  </tr>
  <tr style="background-color: white;">
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
  </tr>
  <tr style="background-color: white;">
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
  </tr>

  <tr style="background-color: white;">
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
  </tr>

  <tr style="background-color: white;">
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
    <td><br/><br/><br/><br/></td>
  </tr>



</table>

</div>
</div>
