<div id="dialog-loading">
  <mat-dialog-content>
    <div class="ring" [class.lds-ring]="success == undefined" [class.success]="success" [class.error]="success == false">
      <div></div>
      <div></div>
      <div></div>
      <div><i class="fas" [class.fa-check]="success" [class.fa-times]="success == false"></i></div>
    </div>
    <h3 *ngIf="success == undefined">{{data.text}}</h3>
    <h3 *ngIf="success == false">{{data.errorMessage}}</h3>
    <h3 *ngIf="success">{{data.successMessage}}</h3>
  </mat-dialog-content>
</div>