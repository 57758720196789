<form [formGroup]="form" (ngSubmit)="alterarDados();">
  <h4>Informações adicionais</h4>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="20">
      <input matInput formControlName="codigoAlternativo" placeholder="Código alternativo" aria-label="Código alternativo ">
    </mat-form-field>
    <mat-form-field fxFlex="20">
      <input #valor matInput formControlName="banco" placeholder="Banco" aria-label="Banco">
    </mat-form-field>

    <mat-form-field fxFlex="30">
      <input matInput formControlName="agencia" placeholder="Agência" aria-label="Agência">
    </mat-form-field>
    <mat-form-field fxFlex="30">
      <input matInput formControlName="conta" placeholder="Número da conta" aria-label="Número da conta">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
    <mat-form-field fxFlex="80">
      <mat-select formControlName="tipo" placeholder="Tipo">
        <mat-option value="Fundo de Investimento">Fundo de Investimento</mat-option>
        <mat-option value="Fundo Financeiro">Fundo Financeiro</mat-option>
        <mat-option value="Fundo Militar">Fundo Militar</mat-option>
        <mat-option value="Fundo Previdenciário">Fundo Previdenciário</mat-option>
        <mat-option value="Taxa de Administração">Taxa de Administração</mat-option>
        <mat-option value="Comprev">Comprev</mat-option>
        <mat-option value="Reserva Taxa">Reserva Taxa</mat-option>
        <mat-option value="IMSS">IMSS</mat-option>
        <mat-option value="PGA">PGA</mat-option>
        <mat-option value="PREVPLAN">PREVPLAN</mat-option>
        <mat-option value="PREVMAIS">PREVMAIS</mat-option>
        <mat-option value="Outras Receitas">Outras Receitas</mat-option>
      </mat-select>
    </mat-form-field>


      <mat-checkbox formControlName="agrupar">Agrupar Fundo</mat-checkbox>

  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px" style="margin-bottom: 1em;">
  <button type="submit" fxFlex="100" style="padding: .2em" fxFlexAlign="center" fxFlexAlign.lt-md="stretch"
      matTooltip="Alterar dados" mat-flat-button [disabled]="form.invalid" color="primary">
      <i class="fas fa-save"></i>Alterar dados
    </button>
  </div>
</form>
