<h1 class="title">Credenciamento</h1>

<div class="acoes">

  <button routerLink="novo" mat-raised-button color="accent" *ngIf="idCliente == null">
    <mat-icon>add</mat-icon> Novo TAC
  </button>

  <button routerLink="simulacao" mat-raised-button color="accent" *ngIf="idCliente == null">
    <mat-icon>add</mat-icon> Simular TAC
  </button>
</div>

<table mat-table [dataSource]="datasource">

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  <ng-container matColumnDef="cnpjAdmin">
    <th mat-header-cell *matHeaderCellDef>CNPJ</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">CNPJ:</span>
      {{element.cnpjAdmin}}
    </td>
  </ng-container>

  <ng-container matColumnDef="nomeAdmin">
    <th mat-header-cell *matHeaderCellDef> Nome </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Nome:</span>
      {{element.nomeAdmin}}
    </td>
  </ng-container>

  <ng-container matColumnDef="nomeGestor">
    <th mat-header-cell *matHeaderCellDef> Gestor </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Gestor:</span>
      {{element.nomeGestor}}
    </td>
  </ng-container>

  <ng-container matColumnDef="pontuacao">
    <th mat-header-cell *matHeaderCellDef>Pontuação</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Pontuação:</span>
      {{element.pontuacao }}%
    </td>
  </ng-container>


  <ng-container matColumnDef="indice_qualidade_gestao">
    <th mat-header-cell *matHeaderCellDef>Índ. Qualid. Gestão</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Índice de Qualidade da Gestão:</span>
      {{element.indice_qualidade_gestao}}
    </td>
  </ng-container>


  <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef>Data</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Data:</span>
      {{element.data | date:'dd/MM/yyyy'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="prazo">
    <th mat-header-cell *matHeaderCellDef>Prazo</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Prazo:</span>
      <span class="verde" *ngIf="calcularVencimento(element)">{{calcularPrazo(element) | date:'dd/MM/yyyy'}}</span>
      <span class="vermelho" *ngIf="!calcularVencimento(element)">{{calcularPrazo(element) | date:'dd/MM/yyyy'}}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="tipo_credenciamento">
    <th mat-header-cell *matHeaderCellDef>Tipo</th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Tipo:</span>
      {{element.tipo_credenciamento === 'S'?'Simulação':'Normal'}}
    </td>
  </ng-container>


  <ng-container matColumnDef="acoes">
    <th mat-header-cell *matHeaderCellDef> Ações </th>
    <td mat-cell *matCellDef="let element">
      <span class="mobile-label">Ações:</span>
      <span style="display: flex; flex-flow: row; float: right;">
        <button mat-icon-button color="primary" (click)="openNew(['/credenciamento', 'impressao',element.id])"
          matTooltip="Imprimir"><i class="fas fa-file"></i></button>
        <button mat-icon-button color="warn" (click)="excluirCredenciamento(element)" matTooltip="Excluir"><i
            class="fas fa-trash"></i></button>
      </span>
    </td>
  </ng-container>
</table>

<div class="spinner-container" *ngIf="datasource.loading$ | async">
  <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
</div>
<mat-paginator *ngIf="datasource.page$ | async as page" [length]="page.totalElements" [pageSize]="page.size"
  [pageIndex]="page.number" [hidePageSize]="true" (page)="datasource.fetch($event.pageIndex)">
</mat-paginator>